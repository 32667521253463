import { memo, useEffect } from 'react';
import {
  InputData,
  usePaginateEvent,
  useStateEvent,
  useChangeEvent,
  PaginationData,
  useMethod,
  useDebouncedState,
} from '@roc-digital/ui-lib';
import { listTags } from '@roc-digital/mxm-base/logic';
import { Event } from '@roc-digital/mxm-base/types';
import { Tag, Tags } from '@roc-digital/mxm-base/data';
import {
  BodyDark,
  BodyLight,
  Button,
  ButtonSizes,
  Heading1,
  InputSearch,
  Pagination,
  Pressable,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableSection,
  Loading,
} from '@/components/elements';
import { useNavigate } from 'react-router-dom';

const AdminTags = memo(() => {
  const navigate = useNavigate();
  const [allTags] = useStateEvent<Tags>('mxm.http.tags', 'listTags.success', undefined);
  const listTagsQuery = useMethod(listTags);
  const [search, setSearch] = useDebouncedState<string>('', 500);

  const paginateEvent = usePaginateEvent(
    ({ data }: Event<PaginationData>) => {
      listTagsQuery.run('', { isAdmin: true, page: data?.page, count: 10, query: search  });
    },
    { namespace: 'mxm.http.tags' },
    {},
    [search]
  );

  useEffect(() => {
    listTagsQuery.run('', { isAdmin: true, page: 1, count: 10, query: search })
  }, [search]);

  const changeSearchEvent = useChangeEvent(({ data }: Event<InputData>) => {
    setSearch(data?.value as string);
  });

  return (
    <div className="flex flex-col w-4/5">
      <div className=" flex justify-between h-16 mr-6  mt-3">
        <Heading1 className="text-2xl text-black">Tags</Heading1>
        <Button size={ButtonSizes.medium} clickEvent={() => navigate(`/tags/new`)}>
          <BodyLight className={'text-white'}>Add Tags</BodyLight>
        </Button>
      </div>
      <div className="flex-grow bg-white p-10 mr-6 rounded">
        <div className="flex flex-row mb-4 justify-between">
          <InputSearch
            showLabel={false}
            placeholder={'Search tags'}
            changeValueEvent={changeSearchEvent}
            showLoading={false}
            hideResults={true}
          />
        </div>
        <Table className={'mt-7 table-fixed'}>
          <TableSection type="head">
            <TableRow>
              <TableHeader className={'w-6/12'}>
                <BodyDark>Tag Name</BodyDark>
              </TableHeader>
              <TableHeader className={'w-1/12'}>
                <BodyDark>Usage</BodyDark>
              </TableHeader>
              <TableHeader className={'w-4/12'}>
                <BodyDark>Type</BodyDark>
              </TableHeader>
              <TableHeader className={'w-3/12'}>
                <BodyDark>Status</BodyDark>
              </TableHeader>
            </TableRow>
          </TableSection>
          <TableSection>
            {allTags?.map((tag: Tag, key: number | undefined) => (
                <TableRow key={key}>
                  <TableCell className={'flex flex-row justify-between gap-4'}>
                    <Pressable clickEvent={() => navigate(`/tags/${tag.id}`)} id={tag.id} className={'w-full'}>
                      <BodyDark>
                        <div title={'Click to edit'}>{tag.name}</div>
                        </BodyDark>
                    </Pressable>
                  </TableCell>
                  <TableCell>
                    <BodyDark>{0}</BodyDark>
                  </TableCell>
                  <TableCell>
                    <BodyDark>{tag.type}</BodyDark>
                  </TableCell>
                  <TableCell>
                    <BodyDark>{tag.is_active ? 'Active' : 'Inactive'}</BodyDark>
                  </TableCell>
                </TableRow>
              ))}
            {listTagsQuery.loading ? (
              <TableRow>
                <TableCell className={'text-center'} colSpan={4}>
                  <Loading size={'medium'} />
                </TableCell>
              </TableRow>
            ) : null}
          </TableSection>
        </Table>
        {allTags && allTags.pagination && (
          <Pagination className={'mt-5'} paginateEvent={paginateEvent} paginator={allTags?.pagination} />
        )}
      </div>
    </div>
  );
});

AdminTags.displayName = 'AdminTags';

export default AdminTags;
