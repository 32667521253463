import { ReactNode } from 'react';
import { publish } from '@roc-digital/mxm-base/events';
import { ClickEvent, CustomEvent, PublisherData } from '@roc-digital/ui-lib';
export enum ButtonVariants {
  primary = 'primary',
  primaryPlain = 'primaryPlain',
  secondary = 'secondary',
  secondaryPlain = 'secondaryPlain',
  destructive = 'destructive',
  disabled = 'disabled',
  white = 'white',
  plain = 'plain',
  web = 'web',
  square = 'square',
}

export enum ButtonSizes {
  large = 'lg',
  medium = 'md',
  small = 'sm',
  xsmall = 'xs',
  largeFull = 'lgFull',
  mediumFull = 'mdFull',
  smallFull = 'smFull',
  xsmallFull = 'xsFull',
}

export type ButtonProps = {
  id?: string;
  variant?: ButtonVariants | `${ButtonVariants}`;
  size?: ButtonSizes | `${ButtonSizes}`;
  customClassName?: string;
  name?: string;
  disabled?: boolean;
  clickEvent?: ClickEvent | CustomEvent | Function;
  children?: ReactNode;
  title?: JSX.Element | string;
  loading?: boolean;
  isIconLeft?: boolean;
  icon?: JSX.Element | string;
};

const baseClasses = 'relative items-center justify-center';

const variantClasses = {
  primary: 'bg-surface-primary rounded-full',
  primaryPlain: 'bg-surface-primary rounded-full',
  secondary: 'bg-white border border-slate-200 rounded-full',
  secondaryPlain: 'bg-white border border-slate-200 rounded-full',
  disabled: 'bg-gray-400 rounded-full',
  web: 'bg-surface-primary rounded-full text-white',
  square: 'bg-white border border-slate-200 rounded-none font-bold',
  destructive: 'bg-red-500 rounded-full',
  white: 'bg-white rounded-full',
  plain: 'bg-transparent rounded-full',
};

const sizeClasses = {
  lg: 'h-16 w-80',
  md: 'h-8 min-w-fit px-4',
  sm: 'h-6 min-w-fit px-4',
  xs: 'h-4 w-12',
  lgFull: 'h-14 w-full',
  mdFull: 'h-14 w-full',
  smFull: 'h-12 w-full',
  xsFull: 'h-12 w-full',
};

const textClasses = {
  primary: 'text-white font-workSans font-medium',
  primaryPlain: 'text-black font-workSans font-medium',
  secondary: 'text-black font-workSans font-medium',
  secondaryPlain: 'text-black font-workSans font-medium',
  disabled: 'text-gray-600 font-workSans font-medium',
  square: 'text-black font-workSans font-medium',
  web: 'text-white font-workSans font-medium',
  destructive: 'text-white font-workSans font-medium',
  white: 'text-white font-workSans font-medium',
  plain: 'text-black font-workSans font-medium',
};

export const Button = ({
  children,
  title,
  isIconLeft = false,
  id,
  customClassName = '',
  variant = ButtonVariants.primary,
  size = ButtonSizes.large,
  disabled,
  clickEvent,
  loading = false,
  icon,
}: ButtonProps) => {
  const publishClick = (event: any) => {
    if (!clickEvent || disabled || loading) {
      return;
    }
    if(typeof clickEvent === 'function') {
      clickEvent(event);
    } else {
      publish<PublisherData>(clickEvent.namespace, 'click', {
        id,
        eventData: clickEvent.data || {},
      });
    }
  };

  if (disabled) {
    variant = ButtonVariants.disabled;
  }

  const getContent = (): ReactNode => {
    if (title !== undefined) {
      return (
        <div className={`${textClasses[variant]}`}>
          {isIconLeft ? icon : null} {title} {!isIconLeft ? icon : null}
        </div>
      );
    }
    return typeof children === 'string' ? <div className={`${textClasses[variant]}`}>{children}</div> : children;
  };

  return (
    <button
      className={`${variantClasses[variant]} ${sizeClasses[size]} ${customClassName} ${baseClasses}`}
      onClick={publishClick}
      disabled={disabled}
    >
      <div className={`${textClasses[variant]}`}>
        {loading ? <img src={'/spinner4.gif'} className={'h-8 w-8 m-auto'} /> : (getContent() as any)}
      </div>
    </button>
  );
};
