import { UiBlockTemplate } from './types';
export type { UiBlockTemplate } from './types';
import { WebPriceTableTemplate } from './web-price-table';
import { TrendingSubscribeBannerLarge } from './trending-subscribe-banner-large';
import { TrendingSubscribeBannerSmall } from './trending-subscribe-banner-small';
import { SubscribeLandingHeader } from './subscribe-landing-header';
import { GenericTemplate } from './generic'

export const TEMPLATES: UiBlockTemplate[] = [
  GenericTemplate,
  WebPriceTableTemplate,
  SubscribeLandingHeader,
  TrendingSubscribeBannerLarge,
  TrendingSubscribeBannerSmall
]


export function hasTemplateWithClass(classType: string) {
  return TEMPLATES.find(t => t.class === classType) ? true : false;
}