import { Button, Modal } from "@/components/elements";
import { BlocksList } from "./BlocksList";
import { ContentBlock, readManyBlocks } from "@/logic/blocks";
import { debounce } from "@roc-digital/mxm-base/logic";
import React from "react";
import { hasTemplateWithClass } from "../templates";

export interface UiBlockInputProps {
  value?: string;
  classType?: string;
  onChange?: (value: string) => void;
}

export function UiBlockInput(props: UiBlockInputProps) {
  const label = useLabel(props.value);

  React.useEffect(() => {
    if(props.classType) {
      if(!hasTemplateWithClass(props.classType)) {
        console.warn('No templates for class type:', props.classType);
      }
    }
  }, [props.classType])

  const change = () => {
    openChangeModal({
      onChange: (block: ContentBlock) => {
        if(block) {
          labelMap[block.id] = block.label;
          if(props.onChange) {
            props.onChange(block.id);
          }
        }
        Modal.close('mxm.modal')
      },
      onClose: () => {
        Modal.close('mxm.modal')
      },
      classType: props.classType,
    })
  }

  return <div className="flex row gap-[4px] fill grow">
    <span className="flex fill grow items-center">{label}</span>
    <Button size='sm' clickEvent={change}>Change</Button>
  </div>
}

function openChangeModal(props: ChangeUiBlockModalProps) {
  Modal.open('mxm.modal', <ChangeUiBlockModal {...props}/>, 'medium');
}


interface ChangeUiBlockModalProps {
  onChange: (value: ContentBlock) => void;
  onClose: () => void;
  classType?: string;
}

function ChangeUiBlockModal(props: ChangeUiBlockModalProps) {
  return <>
    <BlocksList
      onSelect={props.onChange}
      classType={props.classType}
    />
  </>
}

const callBacksQueue = new Set<Function>();
const idsQueue = new Set<string>();
const labelMap: {[id: string]: string} = {};

function useLabel(code?: string) {
  const [_, render] = React.useState(0);
  const triggerRender = React.useCallback(() => render(r => r + 1), []);

  return fetchLabel(code, triggerRender);
}

const queueFetch = debounce(async () => {
  const cbs = Array.from(callBacksQueue);
  callBacksQueue.clear();
  const ids = Array.from(idsQueue);
  idsQueue.clear();

  const blocks = await readManyBlocks(ids);
  blocks.forEach(block => labelMap[block.id] = block.label);
  cbs.forEach(c => c());
}, 250);

function fetchLabel(id: string | undefined, cb: Function) {
  if(!id) return '';
  if(labelMap[id] && labelMap[id] !== 'loading...') return labelMap[id];

  callBacksQueue.add(cb);
  idsQueue.add(id);
  labelMap[id] = 'loading...';
  queueFetch();

  return labelMap[id];
}