import { publish } from '@roc-digital/mxm-base/events';

import { ClickEvent, CustomEvent, PublisherData, getClassNames } from '@roc-digital/ui-lib';

export type BadgeType = 'default' | 'subdued' | 'info' | 'success' | 'warning' | 'critical' | 'web';

type Props = {
  id?: string;
  type?: BadgeType;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  clickEvent?: ClickEvent | CustomEvent;
  children?: React.ReactNode;
};

const styleClasses = {
  base: `text-font`,
  type: {
    default: `bg-action-primary rounded-full`,
    subdued: `bg-slate-100 rounded-full`,
    info: `bg-surface-info text-font-info-high rounded-full`,
    web: `bg-neutral-300 rounded-full`,
  },
  size: {
    small: `text-sm px-[4px]`,
    medium: `px-[6px]`,
    large: `text-lg px-[6px]`,
  },
};

export const Badge = ({ children, id, className, type, size = 'medium', clickEvent }: Props) => {
  const publishClick = (evt: React.MouseEvent<HTMLSpanElement>) => {
    if (!clickEvent) {
      return;
    }

    if (clickEvent.allowDefault === false) {
      evt.preventDefault();
    }

    if (clickEvent.propagate === false) {
      evt.stopPropagation();
    }

    publish<PublisherData>(clickEvent.namespace, 'click', {
      id,
      eventData: clickEvent.data || {},
    });
  };

  const badgeClassName = getClassNames(styleClasses, { type, size });

  className = `${badgeClassName} ${className}`;

  return (
    <span id={id} className={className} onClick={publishClick}>
      {children}
    </span>
  );
};

Badge.defaultProps = {
  type: 'default',
};
