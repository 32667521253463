import { useEffect } from 'react';

import { Notification, getClassNames } from '@roc-digital/ui-lib';

import styles from './NotificationsItem.module.css';

type Props = {
  notification: Notification;
  removeDelay: number;
  onRemove: (notification: Notification) => void;
};

const liClasses = `border-edge bg-surface text-font inline-block w-full border-1 border-solid mb-1 opacity-95 shadow-xl rounded-md`;
const messageClasses = `${styles.message} text-grey-10 block p-2 text-md`;
const headerClasses = {
  base: `${styles.type} uppercase block px-2 py-1 text-lg rounded`,
  type: {
    info: `${styles['type-info']} bg-font-info-muted text-font-info-high`,
    success: `${styles['type-success']} bg-font-success-muted text-font-success-high`,
    warning: `${styles['type-warning']} bg-font-warning-muted text-font-warning-high`,
    error: `${styles['type-error']} bg-font-critical-muted text-font-critical-high`,
  },
};

export const NotificationsItem = ({ notification, removeDelay, onRemove }: Props) => {
  // Set a timeout to trigger the onRemove after a timeout
  useEffect(() => {
    const timeout = setTimeout(() => onRemove(notification), removeDelay);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerClassName = getClassNames(headerClasses, { type: notification.type });

  return (
    <li className={liClasses}>
      <div className={headerClassName}>{notification.type}</div>
      <div className={messageClasses}>{notification.message}</div>
    </li>
  );
};
