import { PopupAdminType, PopupProps, UpdateProfilePopupConfig } from '@roc-digital/types/popups';
import { UpdateProfilePopup } from '@roc-digital/ui-web';
import { PreviewContainer } from './PreviewContainer';
import { Input, Select, Toggle } from "@/components/elements";
import { FormGroup } from "@/components/elements/FormGroups";
import { SidebarConfigBox } from "@/components/elements/SidebarConfigBox";
import { useObjectSetter, inputProps } from "@/hooks/useObjectSetter";
import { PopupConfigProps } from "@roc-digital/types";
import { Option } from '@/components/elements/Select';

export const updateProfilePopupType: PopupAdminType<UpdateProfilePopupConfig> = {
  type: 'update-profile',
  Preview: Preview,
  ConfigForm: Config,
  defaultConfig: {
    title: 'INCOMPLETE PROFILE',
    body: `Your profile is missing an email and phone number. Update your profile to stay updated on the latest news from MxM News+.`,
    trigger: {
      missingEmail: true,
      missingPhone: true,
      delay: 10,
    },
    frequency: 1,
    position: 'bottom',
  }
}

const positionOptions: Option[] = [
  { label: 'Center', value: 'center' },
  { label: 'Bottom', value: 'bottom' },
];

function Config(props: PopupConfigProps<UpdateProfilePopupConfig>) {
  const popup = useObjectSetter(props.popup, props.onChange);
  const config = useObjectSetter(props.popup.config, (config) => popup.set('config', config));
  const triggers = useObjectSetter(config.trigger, (trigger) => config.set('trigger', trigger));

  return <>
    <SidebarConfigBox title="Content">
      <FormGroup title="Title">
        <Input {...inputProps(config, 'title')} />
      </FormGroup>
      <FormGroup title="Body">
        <Input {...inputProps(config, 'body')} />
      </FormGroup>
    </SidebarConfigBox>

    <SidebarConfigBox title="Layout">
      <FormGroup title="Position">
        <Select
          value={config.position} changeEvent={(e) => config.set('position', e.target.value as any)}
          options={positionOptions}></Select>
      </FormGroup>
    </SidebarConfigBox>

    <SidebarConfigBox title="Conditions">
      <FormGroup title="" gap="4px">
        <Toggle
          label='Missing Email'
          value={triggers.missingEmail}
          clickEvent={(v) => triggers.set('missingEmail', v)}
        />
        <Toggle
          label='Missing Phone'
          value={triggers.missingPhone}
          clickEvent={(v) => triggers.set('missingPhone', v)}
        />
      </FormGroup>
      <FormGroup title="URL match">
        <Input {...inputProps(triggers, 'urlMatch')} />
      </FormGroup>
      <FormGroup title="Delay">
        <Input {...inputProps(triggers, 'delay')} type='number' min="1" />
      </FormGroup>
      <FormGroup title="Frequency">
        <Input {...inputProps(config, 'frequency')} type='number' min="1"/>
      </FormGroup>
    </SidebarConfigBox>
  </>
}

function Preview(props: PopupProps) {
  return <PreviewContainer>
    <UpdateProfilePopup popup={props.popup} isPreview />
  </PreviewContainer>
}

