import { BlockElementDef, RuiApi, createNode } from './types';
import { RuiNode } from '@roc-digital/rich-ui';

export const paragraphElement: BlockElementDef = {
  id: 'paragraph',
  getLabel: () => 'Text',
  getIcon: () => textIcon,
  getElementStarters: () => [
    {id: 'paragraph', elementId: 'paragraph', name: 'Paragraph', createNew: createNew}
  ],
  renderEditControls: (node, api) => {
    return <ContainerControls node={node} api={api}/>
  },

  createDomNode:(node, api) => {
    const div = document.createElement('div');
    div.setAttribute('selectable', 'yes');
    div.setAttribute('draggable', 'true');
    div.classList.add('rui-text')
    div.style.minHeight='12px';
    div.dataset['mcePlaceholder']="Type here..."
    return div;
  },

  renderNode,
  serialize
}

function createNew(): RuiNode {
  return createNode('paragraph', {html: 'Enter text here...'});
}

interface ContainerControlsProps {
  node: RuiNode;
  api: RuiApi
}

function ContainerControls(props: ContainerControlsProps) {
  return <></>
}

function renderNode(node: RuiNode, api: RuiApi) {
  const element = api.getDomNode(node);
  if (!element) return;
  element.innerHTML = node.data?.html || '';
}

function serialize(node: RuiNode, serializeChild: (domNode: HTMLElement | Element) => RuiNode, api: RuiApi) {
  const domNode = api.getDomNode(node);

  if (!domNode) return null;

  const nextNode: RuiNode = {
    element: 'paragraph',
    children: [],
    data: {
      html: domNode.innerHTML,
    }
  }

  return nextNode;
}


const textIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M208,56V88a8,8,0,0,1-16,0V64H136V192h24a8,8,0,0,1,0,16H96a8,8,0,0,1,0-16h24V64H64V88a8,8,0,0,1-16,0V56a8,8,0,0,1,8-8H200A8,8,0,0,1,208,56Z"></path></svg>`;