import React, { useEffect, useState } from 'react';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer } from 'victory';

interface IData {
  x: string;
  y: number;
  label: string;
  title?: string;
}

export type entry = {
  tag: string,
  time: number,
  count: number,
  type: string
};

export type params = {
  histogram?: entry[]
};

export type timeData = {
  tag: string,
  values: []
};


const Chart = ({histogram}: params) => {
  const [rows, setRows] = useState<any>(null);
  
  useEffect(() => {
    if (histogram) {
      processHistogram(histogram);
    }

  }, [histogram]);

  const processHistogram = (histogram: any[]) => {
    let rows: timeData[] = [];
    const times = getTimes(histogram);
    const tags = getTags(histogram);
    tags.forEach((tagName) => {
      const values: any = [];
      times.forEach((time) => {
        const newEmpty = {x: getShortMonthDay(time), y: getTagTimeValue(histogram, tagName, time), label: tagName};
        values.push(newEmpty);
      });
      const newRow: timeData = {
        tag: tagName,
        values: values
      };
      rows.push(newRow);
    });
    setRows(rows);
    console.log('rows', rows);
  };

  const getTagTimeValue = (histogram: entry[], tagName: string, time: number) => {
    const search = histogram.find(entry => entry['tag'] === tagName && entry['time'] === time);
    if (search) {
      return search.count;
    } else {
      return 0;
    }
  }

  const getTimes = (histogram: entry[]) => {
    let times: any[] = [];
    histogram.forEach((entry: any) => {
      let time = entry.time;
      if (!times.includes(time)) {
        times.push(time);
      }
    });
    return times.sort(); 
  }

  const getTags = (histogram: entry[]) => {
    let tags: any[] = [];
    histogram.forEach((entry: any) => {
      let tag = entry.tag;
      if (!tags.includes(tag)) {
        tags.push(tag);
      }
    });
    return tags; 
  }

  function getShortMonthDay(unixTimestamp: number, locale = 'en-US') {
    const date = new Date(unixTimestamp * 1000); // Convert to milliseconds
    const options: any = { month: 'short', day: 'numeric' };
    const shortMonthDay = date.toLocaleDateString(locale, options);
    return shortMonthDay;
  }
  
  return (
    <div className={'w-full chart-wrapper'}>
      <VictoryChart containerComponent={<VictoryVoronoiContainer />} height={200} domainPadding={{ x: [1, -1], y: 20 }}>
        {rows ? rows.map((row: timeData) => {
          return <VictoryLine
            data={row.values}
            style={{ data: { stroke: '#A6A6A6' } }}
            interpolation="natural"
            labelComponent={<VictoryTooltip text={(options) => `${options.datum.label}:${options.datum.y}`} />}
          />
        }) : <VictoryLine
        data={[]}
        style={{ data: { stroke: '#A6A6A6' } }}
        interpolation="natural"
        labelComponent={<VictoryTooltip text={(options) => `${options.datum.label}:${options.datum.y}`} />}
        />}
        <VictoryAxis />
        <VictoryAxis
          style={{
            axis: { stroke: 'none' },
          }}
          dependentAxis
        />
      </VictoryChart>
    </div>
  );
};

export default Chart;