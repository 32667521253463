// @ts-ignore

import { FocusEventHandler } from 'react';
import { publish } from '@roc-digital/mxm-base/events';

import { ChangeEvent, CustomEvent, InputData, InputType, getClassNames } from '@roc-digital/ui-lib';

export type InputProps = {
  id?: string;
  type?: InputType | 'color';
  width?: 'auto' | 'full' | 'half' | 'third' | 'quarter';
  size?: 'x-small' | 'small' | 'medium' | 'large';
  name?: string;
  title?: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  readOnly?: boolean;
  changeEvent?: ChangeEvent | CustomEvent | ((event: React.ChangeEvent<HTMLInputElement>) => void);
  value?: string | number | null;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  ref?: any;
  autoFocus?: boolean;
  min?: string;
};

const styleClasses = {
  base: `border-stone-200 text-font inline-block border border-solid rounded-lg`,
  width: {
    auto: `w-auto`,
    full: `w-full`,
    half: `w-1/2`,
    third: `w-1/3`,
    quarter: `w-1/4`,
  },
  size: {
    ['x-small']: `px-1 py-[3px] text-sm`,
    small: `px-3 py-[6px] text-sm`,
    medium: `px-4 py-2`,
    large: `px-6 py-3`,
  },
};

export const Input = ({
  id,
  width,
  size = 'medium',
  className,
  type,
  placeholder,
  value,
  changeEvent,
  title,
  onFocus,
  onBlur,
  ref,
  autoFocus,
  min
}: InputProps) => {
  const publishChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!changeEvent) {
      return;
    }

    if(typeof changeEvent === 'function') {
      changeEvent(evt);
      return;
    }

    if (changeEvent.allowDefault === false) {
      evt.preventDefault();
    }

    if (changeEvent.propagate === false) {
      evt.stopPropagation();
    }

    publish<InputData>(changeEvent.namespace, 'change', {
      id,
      eventData: changeEvent.data || {},
      value: evt.target.value,
      htmlEvent: evt,
    });
  };

  if (value === null || value === undefined) {
    value = '';
  }

  const inputClassName = getClassNames(styleClasses, { width, size });

  className = `${inputClassName} ${className}`;

  return (
    <input
      title={title}
      autoFocus={autoFocus}
      ref={ref}
      id={id}
      type={type}
      placeholder={placeholder}
      className={className}
      value={value}
      onChange={publishChange}
      onFocus={onFocus}
      onBlur={onBlur}
      min={min}
    />
  );
};

Input.defaultProps = {

  type: 'text',
  size: 'medium',
};
