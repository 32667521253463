import { JsonFetch } from "@roc-digital/mxm-base/logic";
import { Interest } from "@roc-digital/types";

const BASE_URL = process.env.REACT_APP_AUX_API_URL;

export async function listInterests(){
  return JsonFetch<Interest[]>({
    url: `${BASE_URL}/admin/interests`
  })
}

export async function readInterest(id: string){
  return JsonFetch<Interest>({
    url: `${BASE_URL}/admin/interests/${id}`
  })
}

export function upsertInterest(interest: Interest) {
  if(!interest.id) {
    return createInterest(interest); 
  } else {
    return updateInterest(interest.id, interest);
  }
}

export async function updateInterest(id: string, interest: Interest){
  return JsonFetch<Interest>({
    method: 'PATCH',
    url: `${BASE_URL}/admin/interests/${id}`,
    data: interest
  })
}

export async function createInterest(interest: Interest){
  return JsonFetch<Interest>({
    method: 'POST',
    url: `${BASE_URL}/admin/interests/`,
    data: interest
  })
}

export async function deleteInterest(id: string){
  return JsonFetch<void>({
    method: 'DELETE',
    url: `${BASE_URL}/admin/interests/${id}`
  })
}