import React from "react";
import { useContextMenu, Menu, Item, Separator, ItemParams } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

interface DropdownProps {
  id?: string;
  items: Array<'-' | DropdownItem>;
  onActivateItem: (itemId: string) => void
}

export interface DropdownItem {
  id: string;
  label: string;
  render?: (item: DropdownItem) => JSX.Element;
  disabled?: boolean;
}

export type DropdownItems = Array<'-' | DropdownItem>;

export function useDropdown(props: DropdownProps) {
  const menuId = React.useMemo(() => props.id || Math.random().toString(), []);
  const cm = useContextMenu({
    id: menuId,
  });
  const cmRef = React.useRef(cm);
  cmRef.current = cm;

  const promiseRef = React.useRef({
    resolver: ((data: any) => {}) as (((data: any) => void) | null)
  })

  const api = React.useMemo(() => {
    const open = (event: any) => {
      const promise = new Promise<string>((res) => {
        promiseRef.current.resolver = res
      })
      cmRef.current.show({event: event});
      return promise;
    }

    const handleItemClick = (args: ItemParams<any, any>) => {
      if(args.id) {

        if (promiseRef.current.resolver) {
          promiseRef.current.resolver(args.id);
          promiseRef.current.resolver = null;
        }

        props.onActivateItem(args.id);
      }
    }

    return {
      open,
      handleItemClick
    }
  }, []);

  const jsx = <Menu id={menuId} onVisibilityChange={isVisible => {
    if (!isVisible && promiseRef.current.resolver) {
      promiseRef.current.resolver(null);
      promiseRef.current.resolver = null;
    }
  }}>
    {props.items.map((item, index) => {
      if(item === '-') {
        return <Separator key={index}/>
      } else {
        return <Item key={item.id} id={item.id} onClick={api.handleItemClick} style={{gap: 4}} disabled={item.disabled}>
          {item.label}
          {item.render ? item.render(item) : null}
        </Item>
      }
    })}
  </Menu>;

  const handle = React.useMemo(() => {
    return {
      jsx: null as any,
      open: api.open,
      close: () => cmRef.current.hideAll(),
    }
  }, [api]);

  handle.jsx = jsx;

  return handle;
}