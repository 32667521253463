import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

export interface DropEvent {
  from: any;
  to: any;
}

export interface SlotProps {
  children: any;
  onDrop:(event: DropEvent) => void;
  getItem: () => object;
  className?: string;
  type?: string;
  disableDrag?: boolean;
}

export function Slot(props: SlotProps) {
  const ref = React.useRef<HTMLDivElement>(null)
  const state = React.useRef(props);
  state.current = props;

  const [{ handlerId, isOver }, drop] = useDrop<
  object,
  void,
  { handlerId: any, isOver: boolean }
>({
  accept: props.type || 'article',
  collect(monitor) {
    return {
      isOver: monitor.isOver(),
      handlerId: monitor.getHandlerId(),
    }
  },
  drop: (item) => {
    props.onDrop({
      from: item,
      to: props.getItem(), 
    });
  },
  hover(item: object, monitor) { },
})

  const [{isDragging}, drag] = useDrag(() => ({
    item: props.getItem,
    type: props.type || 'article',
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop()
    }
  }));

  if(props.disableDrag) {
    drop(ref)
  } else {
    drag(drop(ref))
  }

  return <div
    ref={ref}
    data-handler-id={handlerId}
    className={props.className}
    style={{
      // display: 'flex',
      cursor: !props.disableDrag ? 'move' : 'initial',
      // minHeight: '50px',
      opacity: isDragging ? 0.1 : 1,
      transform: isOver ? 'scale(.9)' : 'initial',
      // // flexShrink: 0
    }}
  >{props.children}</div>;
}
