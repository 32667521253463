import { Button, ButtonSizes, Heading2, InterestList } from "@/components/elements";
import { listInterests } from "@/logic/interests";
import { useMethod } from "@roc-digital/ui-lib";
import { useNavigate } from "react-router";

export function Interests() {
  const query = useMethod(listInterests, {autoRun: []});
  const navigate = useNavigate();

  return <>
    <div className="flex flex-row">
      <div className="flex flex-col flex-grow">
        <Heading2>Interest Tagging</Heading2>
        <span>Allow tagging users by links they visit</span>
      </div>
      <Button
        clickEvent={() => navigate('/settings/interests/add')}
        size={ButtonSizes.small}>Add</Button>
    </div>
    <InterestList
      interests={query.result}
      onOpen={(interest) => navigate('/settings/interests/'+interest.id)}
    />
  </>
}