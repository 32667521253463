import { Button } from '@roc-digital/ui-web';
import { BlockElementDef, RuiApi, createNode, setNodeDataKey } from './types';
import { RuiNode, ButtonAttributes } from '@roc-digital/rich-ui';
import { Input } from '../components/Input';
import { Grouping } from '../components/Grouping';
import { DropdownItems } from '../components/Dropdown';
import { useValueRef } from '@/hooks/useValueRef';
import React from 'react';
import { useProperties, bindProp } from '@/hooks/useProperties';
import { Select } from '../components/Select';
import { getButtonPropsFromNode } from '@roc-digital/ui-web';

export const buttonElement: BlockElementDef = {
  id: 'button',
  getLabel: () => 'Button',
  getIcon: () => buttonIcon,
  getElementStarters: () => [
    {id: 'button', elementId: 'button', name: 'Button', createNew: createNew}
  ],
  renderEditControls: (node, api) => {
    return <ContainerControls node={node} api={api}/>
  },

  createDomNode:(node, api) => {
    const div = document.createElement('div');
    div.setAttribute('selectable', 'yes');
    div.setAttribute('draggable', 'true');
    return div;
  },

  renderNode,
  serialize
}

function createNew(): RuiNode {
  return createNode('button', {label: 'Button'});
}

interface ContainerControlsProps {
  node: RuiNode;
  api: RuiApi
}

function ContainerControls(props: ContainerControlsProps) {
  const nodeRef = useValueRef(props.node);
  const lastChangeRef = React.useRef<any>();
  const [options, setOptions] = React.useState<ButtonAttributes>(() => props.node.data || {} as any);

  React.useEffect(() => {
    if(props.node.data ===lastChangeRef.current) return;
    setOptions(props.node.data || {} as any);
  }, [props.node.data])

  React.useEffect(() => {
    nodeRef.current.data = options
    lastChangeRef.current = options;
    props.api.renderNode(props.node);
    props.api.markChange();
  }, [options]);

  const state = useProperties(options, setOptions);

  return <div className="flex flex-row gap-[4px] w-full">
    <Grouping label='Controls' fill>
      <Input
        type='string'
        label='Label'
        tooltip='Text displayed in the button'
        {...bindProp(state, 'label')}
      />
      <Select
        label='Action'
        items={buttonOptions}
        tooltip='Action taken when button is activated.'
        {...bindProp(state, 'action')}
      />

      <Select
        label='Size'
        items={buttonSizes}
        tooltip='Button size'
        {...bindProp(state, 'size')}
      />

      <Select
        label='Variant'
        items={buttonVariants}
        tooltip='Button Variant'
        {...bindProp(state, 'variant')}
      />

    </Grouping>
  </div>
}

function renderNode(node: RuiNode, api: RuiApi) {
  const element = api.getDomNode(node);
  if (!element) return;
  const baseProps = getButtonPropsFromNode(node);

  api.renderJsx(element, <Button {...baseProps}/>);
}

function serialize(node: RuiNode, serializeChild: (domNode: HTMLElement | Element) => RuiNode, api: RuiApi) {
  const domNode = api.getDomNode(node);

  if (!domNode) return null;

  const nextNode: RuiNode = {
    element: 'button',
    children: [],
    data: {
      label: node.data?.label || 'Button',
      size: node.data?.size || 'large',
      variant: node.data?.variant || 'primary',
      url: node.data?.url || '',
      action: node.data?.action || '',
    }
  }

  return nextNode;
}

const buttonIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M196,88a27.86,27.86,0,0,0-13.35,3.39A28,28,0,0,0,144,74.7V44a28,28,0,0,0-56,0v80l-3.82-6.13A28,28,0,0,0,35.73,146l4.67,8.23C74.81,214.89,89.05,240,136,240a88.1,88.1,0,0,0,88-88V116A28,28,0,0,0,196,88Zm12,64a72.08,72.08,0,0,1-72,72c-37.63,0-47.84-18-81.68-77.68l-4.69-8.27,0-.05A12,12,0,0,1,54,121.61a11.88,11.88,0,0,1,6-1.6,12,12,0,0,1,10.41,6,1.76,1.76,0,0,0,.14.23l18.67,30A8,8,0,0,0,104,152V44a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V100a12,12,0,0,1,24,0v20a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0Z"></path></svg>`;

const buttonOptions: DropdownItems = [
  // { id: 'url', label: 'Url' },
  // { id: 'visit-login', label: 'Visit Login' },
  { id: 'visit-billing', label: 'Visit Billing' },
  { id: 'subscribe-monthly', label: 'Subscribe Monthly'},
  { id: 'subscribe-annual', label: 'Subscribe Annual'},
];

const buttonSizes: DropdownItems = [
  { id: 'lg', label: 'Large' },
  { id: 'md', label: 'Medium' },
  { id: 'sm', label: 'Small' },
];

const buttonVariants: DropdownItems = [
  { id: 'primary', label: 'Primary' },
  { id: 'secondary', label: 'Secondary' },
  { id: 'warning', label: 'Warning' },
  { id: 'outline', label: 'Outline' },
];