import { uploadImageToBucket } from '@/logic/upload';
import { useState } from 'react';
import { toast } from 'react-toastify';

type Props = {
  returnUrl: Function;
};

function ImageUploadForm({returnUrl}: Props) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const sendImageUrl = (imageUrl: any) => {
    returnUrl(imageUrl);
  }

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();

    if (!selectedFile) {
      alert('Please select an image file.');
      return;
    }

    try {
      const imageUrl = await uploadImageToBucket(selectedFile);
      sendImageUrl(imageUrl);
      toast.success('Image uploaded successfully');
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image');
    }
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit" className="border rounded-full px-2 py-1 mt-1" disabled={!selectedFile}>Upload Image</button>
      </form>
    </div>
  );
}

export default ImageUploadForm;