import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { debounce } from '@roc-digital/mxm-base/logic';

export interface TextEditorProps {
  onChange: (value: string) => void;
  value: string;
}

export const TextEditor = (props: TextEditorProps) => {
  const [content, setContent] = React.useState('')
  const editorRef = React.useRef<any>(null);
  const lastContent = React.useRef('');

  const onChange = React.useCallback(debounce(() => {
    if(props.onChange) {
      const content = editorRef.current.getContent();
      lastContent.current = content;
      props.onChange(lastContent.current);
    }
  }, 500), []);

  React.useEffect(() => {
    if(props.value !== lastContent.current) {
      lastContent.current = props.value;
      setContent(props.value);
    }
  }, [props.value]);


  return (
    <>
      <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        tinymceScriptSrc={"/tinymce/js/tinymce/tinymce.min.js"}
        initialValue={content}
        onChange={onChange}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
          ],
          toolbar: 'undo redo | blocks | image | code | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | link | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
        }}
      />
    </>
  );
};

export default TextEditor;
