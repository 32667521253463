import { RuiDocument } from '@roc-digital/rich-ui';
import { UiBlockTemplate } from './types';

const DOCUMENT: RuiDocument = {
  "children": [
    {
      "element": "container",
      "children": [
        {
          "element": "container",
          "children": [
            {
              "element": "paragraph",
              "children": [],
              "data": {
                "html": "<p style=\"text-align: center;\"><strong><span style=\"color: rgb(236, 240, 241); font-size: 18pt;\">Welcome to the MXM News UI Creator</span></strong></p>"
              }
            }
          ],
          "data": {
            "alignment": "middle-center",
            "backgroundColor": "#24aae1",
            "borderRadius": 20,
            "direction": "column",
            "height": "fill",
            "marginLeft": 0,
            "marginTop": 0,
            "paddingLeft": 20,
            "paddingTop": 20,
            "width": "fill"
          }
        }
      ],
      "data": {
        "alignment": "top-left",
        "backgroundColor": "#ffffff",
        "direction": "column",
        "gap": 8,
        "height": "hug",
        "width": "fill"
      }
    }
  ]
};

export const GenericTemplate: UiBlockTemplate = {
  class: 'generic',
  label: 'Generic UI Block',
  description: 'A generic block.',
  document: DOCUMENT,
  validate: () => null,
  webPreviewUrl: '',
}


