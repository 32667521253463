import { Button } from '@/components/elements';
import { BlockElementDef, RuiApi, createNode } from './types';
import { RuiNode, EmbedAttributes } from '@roc-digital/rich-ui';
import { Input } from '../components/Input';
import { Grouping } from '../components/Grouping';
import { useValueRef } from '@/hooks/useValueRef';
import React from 'react';
import { useProperties, bindProp } from '@/hooks/useProperties';
import { Embed } from '@roc-digital/ui-web';

export const embedElement: BlockElementDef = {
  id: 'embed',
  getLabel: () => 'Embed',
  getIcon: () => embedIcon,
  getElementStarters: () => [
    {id: 'embed', elementId: 'embed', name: 'Embed', createNew: createNew}
  ],
  renderEditControls: (node, api) => {
    return <ContainerControls node={node} api={api}/>
  },

  createDomNode:(node, api) => {
    const div = document.createElement('div');
    div.setAttribute('selectable', 'yes');
    div.setAttribute('draggable', 'true');
    div.classList.add('w-full', 'relative')
    return div;
  },

  renderNode,
  serialize
}

function createNew(): RuiNode {
  return createNode('embed', {url: ''});
}

interface ContainerControlsProps {
  node: RuiNode;
  api: RuiApi
}

function ContainerControls(props: ContainerControlsProps) {
  const nodeRef = useValueRef(props.node);
  const [options, setOptions] = React.useState<EmbedAttributes>(() => props.node.data || {} as any);

  React.useEffect(() => {
    nodeRef.current.data = options
    props.api.renderNode(props.node);
    props.api.markChange();
  }, [options]);

  const state = useProperties(options, setOptions);

  return <div className="flex flex-row gap-[4px] w-full">
    <Grouping label='Controls' fill>
      <Input
        type='string'
        label='URL'
        tooltip='The Twitter, Youtube or Rumble url.'
        {...bindProp(state, 'url')}
      />
    </Grouping>
  </div>
}

function renderNode(node: RuiNode, api: RuiApi) {
  const element = api.getDomNode(node);
  if (!element) return;

  api.renderJsx(element, <>
    <Embed url={node?.data?.url || ''}/>
    <div className='absolute top-[0px] right-[0px] left-[0px] bottom-[0px]'></div>
  </>);
}

function serialize(node: RuiNode, serializeChild: (domNode: HTMLElement | Element) => RuiNode, api: RuiApi) {
  const domNode = api.getDomNode(node);

  if (!domNode) return null;

  const nextNode: RuiNode = {
    element: 'embed',
    children: [],
    data: {
      url: node.data?.url || ''
    }
  }

  return nextNode;
}

const embedIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M164.44,121.34l-48-32A8,8,0,0,0,104,96v64a8,8,0,0,0,12.44,6.66l48-32a8,8,0,0,0,0-13.32ZM120,145.05V111l25.58,17ZM234.33,69.52a24,24,0,0,0-14.49-16.4C185.56,39.88,131,40,128,40s-57.56-.12-91.84,13.12a24,24,0,0,0-14.49,16.4C19.08,79.5,16,97.74,16,128s3.08,48.5,5.67,58.48a24,24,0,0,0,14.49,16.41C69,215.56,120.4,216,127.34,216h1.32c6.94,0,58.37-.44,91.18-13.11a24,24,0,0,0,14.49-16.41c2.59-10,5.67-28.22,5.67-58.48S236.92,79.5,234.33,69.52Zm-15.49,113a8,8,0,0,1-4.77,5.49c-31.65,12.22-85.48,12-86,12H128c-.54,0-54.33.2-86-12a8,8,0,0,1-4.77-5.49C34.8,173.39,32,156.57,32,128s2.8-45.39,5.16-54.47A8,8,0,0,1,41.93,68c30.52-11.79,81.66-12,85.85-12h.27c.54,0,54.38-.18,86,12a8,8,0,0,1,4.77,5.49C221.2,82.61,224,99.43,224,128S221.2,173.39,218.84,182.47Z"></path></svg>`;
