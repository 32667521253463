import { Text, TextProps } from './Text';

export const Heading1 = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-chivo text-3xl !font-extrabold ${className} `}>
    {children}
  </Text>
);

export const Heading2 = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-chivo text-xl font-extrabold ${className}`}>
    {children}
  </Text>
);

export const BodyDark = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-workSans text-sm text-admin-dark font-medium ${className}`}>
    {children}
  </Text>
);

export const BodyLight = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-workSans text-admin-light font-medium ${className}`}>
    {children}
  </Text>
);

export const BodyMuted = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-workSans text-admin-muted font-medium ${className}`}>
    {children}
  </Text>
);
