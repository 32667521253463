import { ClickEvent, CustomEvent, dashboardOutlineIcon, tagIcon, usersIcon, contentIcon, settingsIcon, frameCornersIcon } from '@roc-digital/ui-lib';
import { Pressable } from './Pressable';
import { BodyDark } from './Typefaces';
import { Icon } from './Icon';import React from 'react';

const promotionalsIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#919193" viewBox="0 0 256 256"><path d="M252,120a52.06,52.06,0,0,0-52-52H160.32c-3.44-.21-52.6-4-99.46-43.3A20,20,0,0,0,28,40V200a19.8,19.8,0,0,0,11.54,18.12,19.86,19.86,0,0,0,21.32-2.81A192.92,192.92,0,0,1,144,174.47v26.2a20,20,0,0,0,8.9,16.64,11.35,11.35,0,0,0,1.39.8l14.44,7.06A20,20,0,0,0,198.37,213l11.09-41.82A52.07,52.07,0,0,0,252,120ZM52,191.63V48.4c36.17,28.07,72.17,38.1,92,41.66V150C124.17,153.52,88.17,163.55,52,191.63ZM176.39,202.2,168,198.1V172h16.4ZM200,148H168V92h32a28,28,0,1,1,0,56Z"></path></svg>';
const uiBlocksIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#919193" viewBox="0 0 256 256"><path d="M100,40a12,12,0,0,1,12-12h32a12,12,0,0,1,0,24H112A12,12,0,0,1,100,40Zm44,164H112a12,12,0,0,0,0,24h32a12,12,0,0,0,0-24ZM208,28H184a12,12,0,0,0,0,24h20V72a12,12,0,0,0,24,0V48A20,20,0,0,0,208,28Zm8,72a12,12,0,0,0-12,12v32a12,12,0,0,0,24,0V112A12,12,0,0,0,216,100Zm0,72a12,12,0,0,0-12,12v20H184a12,12,0,0,0,0,24h24a20,20,0,0,0,20-20V184A12,12,0,0,0,216,172ZM40,156a12,12,0,0,0,12-12V112a12,12,0,0,0-24,0v32A12,12,0,0,0,40,156Zm32,48H52V184a12,12,0,0,0-24,0v24a20,20,0,0,0,20,20H72a12,12,0,0,0,0-24ZM40,84A12,12,0,0,0,52,72V52H72a12,12,0,0,0,0-24H48A20,20,0,0,0,28,48V72A12,12,0,0,0,40,84ZM80,68h96a12,12,0,0,1,12,12v96a12,12,0,0,1-12,12H80a12,12,0,0,1-12-12V80A12,12,0,0,1,80,68Zm12,96h72V92H92Z"></path></svg>';

type Props = {
  id?: string;
  className?: string;
  alt?: string;
  title?: string;
  style?: React.CSSProperties;
  clickEvent?: ClickEvent | CustomEvent;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  active?: string;
};

export const AdminMenu = ({ active, clickEvent }: Props) => {
  const activeClasses = 'bg-white rounded-3xl';
  const textActiveClasses = 'text-admin-primary ml-2';

  const menuItems = [
    { name: 'dashboard', icon: dashboardOutlineIcon },
    { name: 'content', icon: contentIcon },
    { name: 'tags', icon: tagIcon },
    { name: 'users', icon: usersIcon },
    { name: 'views', icon: dashboardOutlineIcon },
    { name: 'settings', icon: settingsIcon },
    { name: 'popups', icon: frameCornersIcon },
    { name: 'blocks', icon: uiBlocksIcon },
    { name: 'promotionals', icon: promotionalsIcon}
  ];

  return (
    <div className="fixed w-[250px] top-16">
      {/* Menu items */}
      <ul className="text-admin-light flex flex-col p-4 text-sm align-middle">
        {menuItems.map((item) => (
          <React.Fragment key={item.name}>
            <li className="py-1 hidden-850">
              <Pressable
                clickEvent={clickEvent}
                className={`${active === item.name ? activeClasses : ''} py-2`}
                id={item.name}
              >
                <Icon
                  className={'mx-3 align-middle'}
                  src={item.icon}
                  fill={active === item.name && item.name !== 'users' ? '#24AAE1' : ''}
                  stroke={active === item.name ? '#24AAE1' : ''}
                />
                <BodyDark className={active === item.name ? textActiveClasses : 'ml-2'}>
                  {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                </BodyDark>
              </Pressable>
            </li>
            <li className="py-1 visible-850 px-2">
              <Pressable
                clickEvent={clickEvent}
                className={`${active === item.name ? activeClasses : ''} py-2`}
                id={item.name}
              >
                <Icon
                  className={'mx-2 align-middle'}
                  src={item.icon}
                  fill={active === item.name && item.name !== 'users' ? '#24AAE1' : ''}
                  stroke={active === item.name ? '#24AAE1' : ''}
                />
                <br/>
                <BodyDark className={active === item.name ? textActiveClasses : 'ml-2'}>
                  {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                </BodyDark>
              </Pressable>
            </li>
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};
