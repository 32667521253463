import React from "react";

export interface PropertiesSetter<T extends object> {
  current?: T,
  set< K extends keyof T>(key: K, value: T[K]): void;
}

export function useProperties<T extends object>(properties: T | undefined, onChange: (properties: T) => void): PropertiesSetter<T> {
  const ref = React.useRef(properties);
  const [current, setCurrent] = React.useState(properties);

  if(properties) {
    ref.current = properties
  }

  const setter = React.useMemo(() => {
    return function set<K extends keyof T>(key: K, value: T[K]) {
      const next = {
        ...(ref.current || {}),
        [key]: value,
      } as T;
      ref.current = next;
      setCurrent(next);
      onChange(next);
    }
  }, []);

  return {
    current: ref.current,
    set: setter,
  }
}

export function bindProp<T extends object, K extends keyof T>(setter: PropertiesSetter<T>, key: K) {
  const binder = React.useMemo(() => {
    return {
      value: setter.current?.[key],
      onChange: (value: T[K]) => setter.set(key, value)
    }
  }, [setter, key]);
  binder.value = setter.current?.[key];

  return binder;
}
