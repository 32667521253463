import { RuiDocument } from '@roc-digital/rich-ui';
import { UiBlockTemplate } from './types';

const DOCUMENT: RuiDocument = {
  "children": [
    {
      "children": [
        {
          "children": [
            {
              "children": [
                {
                  "children": [
                    {
                      "children": [],
                      "data": {
                        "html": "<div style=\"text-align: center;\"><strong><span style=\"font-size: 12pt; color: rgb(236, 240, 241);\">Monthly</span></strong></div>\n<div style=\"text-align: center;\"><strong><span style=\"font-size: 18pt; color: rgb(236, 240, 241);\">$2.49/week</span></strong></div>\n<div style=\"text-align: center;\"><strong><span style=\"font-size: 12pt; color: rgb(236, 240, 241);\">$9.96/month</span></strong></div>\n<div style=\"text-align: center;\">&nbsp;</div>\n<div><strong><span style=\"font-size: 14pt; color: rgb(236, 240, 241);\">+ Ad-free mainstream news without mainstream bias</span></strong></div>\n<div><strong><span style=\"font-size: 14pt; color: rgb(236, 240, 241);\">+ Local news from YOUR community</span></strong></div>\n<div><strong><span style=\"font-size: 14pt; color: rgb(236, 240, 241);\">+ Top podcasts, interviews, and viral must-see content, all in one place.</span></strong></div>"
                      },
                      "element": "paragraph"
                    }
                  ],
                  "data": {
                    "alignment": "top-left",
                    "backgroundColor": "#24aae1",
                    "direction": "column",
                    "height": "fill",
                    "width": "fill",
                  },
                  "element": "container"
                },
                {
                  "children": [],
                  "data": {
                    "action": "subscribe-monthly",
                    "label": "GET Monthly",
                    "size": "md",
                    "url": "",
                    "variant": "outline"
                  },
                  "element": "button"
                }
              ],
              "data": {
                "alignment": "top-center",
                "backgroundColor": "#24aae1",
                "borderRadius": 6,
                "direction": "column",
                "gap": 8,
                "height": "fill",
                "marginLeft": 0,
                "marginTop": 0,
                "paddingLeft": 20,
                "paddingTop": 20,
                "width": "fill"
              },
              "element": "container"
            },
            {
              "children": [
                {
                  "children": [
                    {
                      "children": [],
                      "data": {
                        "html": "<div>\n<div style=\"text-align: center;\"><strong><span style=\"color: rgb(236, 240, 241); font-size: 12pt;\">Annual</span></strong></div>\n<div style=\"text-align: center;\"><strong><span style=\"color: rgb(236, 240, 241); font-size: 18pt;\">$1.86/week</span></strong></div>\n<div style=\"text-align: center;\"><strong><span style=\"color: rgb(236, 240, 241); font-size: 12pt;\"><em>$119.52 </em>$89.64/year</span></strong></div>\n<div style=\"text-align: center;\">&nbsp;</div>\n<div><strong><span style=\"color: rgb(236, 240, 241); font-size: 14pt;\">+ Everything in monthly</span></strong></div>\n<div><strong><span style=\"color: rgb(236, 240, 241); font-size: 14pt;\">+ Plus 30% savings!</span></strong></div>\n<div><strong><span style=\"color: rgb(236, 240, 241); font-size: 14pt;\">+ Annual access</span></strong></div>\n</div>"
                      },
                      "element": "paragraph"
                    }
                  ],
                  "data": {
                    "alignment": "top-center",
                    "backgroundColor": "#24aae1",
                    "direction": "column",
                    "height": "fill",
                    "width": "fill"
                  },
                  "element": "container"
                },
                {
                  "children": [],
                  "data": {
                    "action": "subscribe-monthly",
                    "label": "GET Monthly",
                    "size": "md",
                    "url": "",
                    "variant": "outline"
                  },
                  "element": "button"
                }
              ],
              "data": {
                "alignment": "top-center",
                "backgroundColor": "#24aae1",
                "borderRadius": 6,
                "direction": "column",
                "gap": 8,
                "height": "fill",
                "paddingLeft": 20,
                "paddingTop": 20,
                "width": "fill"
              },
              "element": "container"
            }
          ],
          "data": {
            "alignment": "top-left",
            "backgroundColor": "none",
            "direction": "row",
            "gap": 16,
            "height": "fill",
            "width": "fill"
          },
          "element": "container"
        }
      ],
      "data": {
        "alignment": "top-left",
        "backgroundColor": "none",
        "direction": "column",
        "gap": 16,
        "height": "hug",
        "width": "fill"
      },
      "element": "container"
    }
  ],
  "textTokens": []
}

export const WebPriceTableTemplate: UiBlockTemplate = {
  class: 'web-price-table',
  label: 'Web Price Table',
  description: 'Displays the subscription prices, promo boxes and subscribe CTA buttons.',
  document: DOCUMENT,
  validate: () => null,
  webPreviewUrl: '/account/billing',
}


