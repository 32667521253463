import React from 'react';
import { Slot, DropEvent } from './Slot';
import { ZoneLayout, ArticleView } from '@roc-digital/ui-web';
import { ArticleContent, ArticleViewProps} from '@roc-digital/ui-lib';

export interface DnDZonePreviewProps {
  zoneId: string;
  layoutId: number;
  articles: ArticleContent[];
  onDrop: (event: DropEvent) => void;
}

const ZoneContext = React.createContext<any>({});

export function DnDZonePreview(props: DnDZonePreviewProps) {
  return <ZoneContext.Provider key={props.zoneId} value={{zoneId: props.zoneId, onDrop: props.onDrop}}>
    <ZoneLayout
      articles={props.articles}
      layoutId={props.layoutId as any}
      onAction={() => {}}
      ArticleViewComponent={ZonePreview}
    />
  </ZoneContext.Provider>;
}

function ZonePreview(props: ArticleViewProps) {
  const state = React.useContext(ZoneContext);
  let article = props.article;

  if(article && !article.headline) {
    article = {
      ...article,
      headline: 'Drop article here'
    }
  }

  return <Slot
    getItem={() => ({area: state.zoneId, slot: props.index || 0, articleId: props.article?.id})}
    onDrop={state.onDrop}
    className={props.className}
  >
    <ArticleView {...props} article={article}/>
  </Slot>
}