import React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
};

const tableClasses = 'text-font w-full border-b-1 border-solid border-edge-high border-opacity-10';

export const Table = ({ children, className }: Props) => {
  const tableClassName = className ? `${tableClasses} ${className}` : tableClasses;

  className = `${tableClassName} ${className}`;

  return <table className={className}>{children}</table>;
};
