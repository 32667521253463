import React, { memo } from 'react';
import { ArticleContent, mapToArticleContent, useMethod } from '@roc-digital/ui-lib';
import { adminReadArticle } from '@roc-digital/mxm-base/logic';
import {
  Button,
  ButtonSizes,
  ButtonVariants,
  Heading2,
  Input,
  Modal,
  SpinnerBackdrop,
  TextArea,
  Toggle,
} from '@/components/elements';
import { toast } from 'react-toastify';
import { ArticleView } from '@roc-digital/ui-web';
import { UserFilterBuilder } from './UsersFilterBuilder';
import { TagSchema } from '@roc-digital/mxm-base/src/data';
import { createPushNotification } from '@/logic/notifications';
import { ContentType, CreatePushNotificationParams } from '@roc-digital/types';

export interface NotifyModalProps {
  articleId?: string;
}

export function openNotifyModal(props: NotifyModalProps) {
  Modal.open('mxm.modal', <NotifyModal {...props} />, 'medium');
}

export const NotifyModal = memo((props: NotifyModalProps) => {
  const [all, setAll] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [body, setBody] = React.useState('');
  const [imageUrl, setImageUrl] = React.useState('');
  const [token, setToken] = React.useState('');
  const [tags, setTags] = React.useState<TagSchema[]>([]);
  const [page, setPage] = React.useState<'notification' | 'audience'>('notification');

  const isTest = window.location.search.includes('test')

  const articleQuery = useMethod(adminReadArticle, {
    autoRun: [props.articleId || ''],
    onBefore: async (args) => {
      if(args?.[0]) return args;
      return;
    },
    onSuccess: (article) => {
      if (!article) return;
      setTitle('MxM News');
      setBody(article.title);
      setImageUrl(article.image_url || '');
    }
  });

  const notifyQuery = useMethod(createPushNotification, {
    onBefore: async (args) => {
      if (!confirm('Ready to send notification? This will notify all users in your selected audience.')) {
        return;
      }

      return args;
    },
    onSuccess: () => {
      toast.success('Notification sent!');
      closeModal();
    },
    onError: (e) => {
      toast.error(('Error notifying article: ' + e?.message) as string);
    }
  });

  const send = () => {
    const params: CreatePushNotificationParams = {
      title,
      message: body,
      data: undefined,
      target: 'token',
      image_url: '',
      scopes: undefined,
      token: undefined
    };

    if(!props.articleId) {
      params.data = {
        _type:'general',
        body: body,
        title: title,
        id: 'general_notification',
        image_url: imageUrl,
        openUrl: '',
      }
    } else {
      const article = articleQuery.result;
      if(!article) throw new Error('Article not found.');

      params.data = {
        author_name: article.author_name,
        blur_hash: article.blur_hash,
        content: article.content,
        content_type: article.content_type as ContentType,
        date: article.date,
        deep_link: article.deep_link,
        id: article.id,
        image_url: article.image_url,
        title: article.title,
        url: article.url,
        video_url: article.video_url,
        paywall: article.paywall,
        _type: 'article',
      }
    }

    if(token) {
      params.target = 'token';
      params.token = token;
    } else if(all) {
      params.target = 'all';
    } else if(tags?.length) {
      params.target = 'tags';
      params.scopes = tags.map(t => t.scope || '');
    }

    notifyQuery.run(params)
  };

  const closeModal = () => {
    Modal.close('mxm.modal');
  };

  const content = React.useMemo(() => {
    if (!articleQuery.result) return {
      id: '',
      tag: '',
      headline: '',
      image: '',
      logo: '',
      timeLapse: '',
      upvotes: 0,
      downvotes: 0,
      saved: false,
    } as ArticleContent;

    return mapToArticleContent(articleQuery.result, {}, true)
  }, [articleQuery.result])

  const loading = articleQuery.loading || notifyQuery.loading;
  const valid = title.trim() && body.trim() && !loading ? true : false;
  const canSend = valid && (all || tags.length);


  return (
    <div className="p-8">
      {page === 'notification' ? <>
        <Heading2 className={'mb-8 text-admin-dark !font-extrabold !text-[26px]'}>Send Push Notification</Heading2>

        {props.articleId ? <>
          <div className='flex justify-center mt-4'>
            <ArticleView
              article={content as any}
              orientation='mini'
              size='sm'
              onAction={(action) => { }}
              className='max-w-[400px]'
            />
          </div>
        </> : <></>}

        <div className='mb-8'>
          <div className="w-full mt-2">
            <label>Title</label>
            <Input value={title} className="w-full" changeEvent={(e) => setTitle(e.target.value)} />
          </div>
          <div className="w-full mt-2">
            <label>Body</label>
            <TextArea value={body} className="w-full h-[202px]" changeEvent={(e) => setBody(e.target.value)} />
          </div>
          <div className="w-full mt-2">
            <label>Image URL</label>
            <Input value={imageUrl} className="w-full" changeEvent={(e) => setImageUrl(e.target.value)} />
          </div>
          {isTest ? <>
            <div className="w-full mt-2">
              <label>Token*</label>
              <Input value={token} className="w-full" changeEvent={(e) => setToken(e.target.value)} />
            </div>
          </> : <></>}
        </div>
        <div>
          <div className="mt-4 flex inline-block gap-2">
            <Button clickEvent={closeModal} size={ButtonSizes.medium} variant={ButtonVariants.secondary}>Cancel</Button>
            <span className='flex-grow'></span>
            <Button clickEvent={() => setPage('audience')} disabled={!valid} size={ButtonSizes.medium}>Select Audience</Button>
          </div>
        </div>
      </> : <></>}
      {page === 'audience' ? <>
        <Heading2 className={'mb-8 text-admin-dark !font-extrabold !text-[26px]'}>Send Push Notification - Audience</Heading2>

        <div className='min-h-[400px] pt-8 gap-2 flex flex-col'>
          <Toggle label='Send to all users' initialState={all} onToggle={setAll} />
          {all ? <>
            
          </> : <>
            <div className='mt-2'>
              Target users with the following tags:
              <UserFilterBuilder
                onChange={filters => {
                  setTags(filters.tags)
                }}
              />
            </div>
          </>}
        </div>

        <div>
          <div className="mt-4 flex inline-block gap-2">
            <Button clickEvent={closeModal} size={ButtonSizes.medium} variant={ButtonVariants.secondary}>Cancel</Button>
            <Button clickEvent={() => setPage('notification')} size={ButtonSizes.medium} variant={ButtonVariants.secondary}>Back</Button>
            <span className='flex-grow'></span>
            <Button clickEvent={send} disabled={!canSend} size={ButtonSizes.medium}>Send Notification</Button>
          </div>
        </div>
      </> : <></>}

      <SpinnerBackdrop active={loading} />
    </div>
  );
});
