import { useMethod } from '@roc-digital/ui-lib';
import { ContentBlock, readBlock, upsertBlock, DEFAULT_BLOCK, DEFAULT_DOC, publishBlock, EMPTY_DOCUMENT } from '@/logic/blocks';
import { useNavigate, useParams } from 'react-router';
import { UiBlockEditor } from '@/components/elements/UiBlocks';
import { Header } from './components/Header';
import React from 'react';
import { openSelectTemplateModal } from '@/components/elements/UiBlocks/SelectTemplateModal';

export default function EditBock() {
  const nav = useNavigate();
  const id = useParams()?.id || '';
  const originalRef = React.useRef<ContentBlock | null>()
  const readQuery = useMethod(readBlock, {
    autoRun: id !== 'new' ? [id]: undefined,
    onSuccess: (v, args) => {
    },
    defaultValue: id === 'new' ? DEFAULT_BLOCK : undefined
  });

  const saveQuery = useMethod(upsertBlock, {
    onSuccess: (result, args) => {
      if(!args[0].id && result.id) {
        nav(`/blocks/${result.id}`);
      }
    }
  });
  const publishQuery = useMethod(publishBlock);
  const isModified = readQuery.result && readQuery.result !== originalRef.current ? true : false;
  const loading = readQuery.loading || saveQuery.loading || publishQuery.loading;
  const document = readQuery.result?.draft || readQuery.result?.document || EMPTY_DOCUMENT;

  if(!document?.children?.length && id !== 'new' && DEFAULT_DOC?.children.length) {
    document.children = [...DEFAULT_DOC.children];
  }

  React.useEffect(() => {
    if(id === 'new' && document.children.length === 0) {
      openSelectTemplateModal({
        onSelect: (template) => {
          updateBlock({
            class: template.class,
            label: 'New ' + template.label,
            document: template.document,
            draft: template.document
          });
        },
        onCancel: () => {
          // if the user does not select a block... we return.
          nav('/blocks')
        }
      })
    }
  }, [id])

  const updateBlock = (changes: Partial<ContentBlock>) => {
    const block = readQuery.getLastResult() || {
      id: '0',
      label: 'Untitled',
    } as ContentBlock;
    const change = {...block, ...changes};
    readQuery.setResult(change);
  }

  const save = () => {
    const change = readQuery.getLastResult();
    if (!change) return;
    saveQuery.run(change).then((saved: any) => {
      originalRef.current = saved;
      readQuery.setResult(saved);      
    });
  }

  const publish = () => {
    publishQuery.run(id);
  }

  return <div className='fixed top-[0px] left-[0px] bottom-[0px] right-[0px] flex flex-col'>
    <Header
      title={readQuery.result?.label || ''}
      onTitleChange={(label) => updateBlock({label})}
      loading={loading}
      modified={isModified}
      onSave={save}
      onPublish={publish}
      onBack={() => nav('/blocks')}
    />
    <div className='flex flex-row flex-fill w-[100%] h-[100%]'>
      <UiBlockEditor
        value={document}
        onChange={(document) => updateBlock({draft: document})}/>
    </div>
  </div>
}
