import { BodyDark, Heading1 } from '@/components/elements';

export interface DashboardNumberStatProps {
  value: any;
  label: any;
  type?: 'number' | 'percent' | 'dollar'
  labelColor?: string;
  valueColor?: string;
  info?: string;
}

export function DashboardNumberStat(props: DashboardNumberStatProps) {

 const infoBubble = <span className='inline-block' title={props.info}>
  <svg 
    style={{fill: '#ccc'}}
    width="10px"
    height="10px"
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m50 0c-27.57 0-50 22.43-50 50s22.43 50 50 50 50-22.43 50-50-22.43-50-50-50zm4.8906 77.211h-9.7812v-38.41h9.7812zm-4.9688-43.93c-3.2344 0-5.3633-2.2891-5.3633-5.2031 0-2.9961 2.2109-5.2852 5.4414-5.2852 3.3125 0 5.3633 2.2891 5.4414 5.2852 0 2.9141-2.1289 5.2031-5.5195 5.2031z"/>
  </svg>
 </span>;
 
  return  <div className={'flex flex-col mr-6 mb-6 w-1/2'}>
  <Heading1 className={`text-admin-dark text-4xl mb-3`} style={{color: props.valueColor}}>
    {props.type === 'dollar' ? '$' : ''}
    {typeof props.value === 'number' ? Math.round(props.value).toLocaleString() : props.value}

  </Heading1>
  <BodyDark className={`text-xs`} style={{color: props.labelColor}}>
    {props.label} {props.info ? infoBubble : null}
  </BodyDark>
</div>
}