import { RuiNode } from "@roc-digital/rich-ui";
import React from "react";

export type Falsable<T> = null | undefined | T; 

export interface ElementStarter {
  id: string;
  elementId: string;
  name: string;
  createNew: (starter: ElementStarter) => Promise<RuiNode> | RuiNode;
}

export interface BlockElementDef {
  id: string;
  getLabel: (node: RuiNode, api: RuiApi) => string;
  getIcon: (node: RuiNode, api: RuiApi) => string;
  renderEditControls: (node: RuiNode, api: RuiApi) => any;
  getElementStarters: () => ElementStarter[];
  createDomNode(node: RuiNode, api: RuiApi): HTMLElement;
  renderNode(node: RuiNode, api: RuiApi): void;
  serialize(node: RuiNode, serializeChild: (domNode: HTMLElement | Element) => RuiNode | null, api: RuiApi): RuiNode | null;
}

export interface RuiApi {
  getParent(node: RuiNode): Falsable<RuiNode>
  getDomNode(node: RuiNode): Falsable<HTMLElement>;
  markChange(): void;
  renderJsx(root: HTMLElement, jsx: React.ReactElement);
  renderNode(node: RuiNode);
}

export function createNode(element: string, data?: object, children?: RuiNode[]): RuiNode {
  return {
    id: Math.random().toString(),
    element: element,
    data,
    children
  }
}

export function readNodeDataKey<T>(node: RuiNode, key: string): T | undefined {
  return node?.data?.[key]; 
}

export function setNodeDataKey<T>(node: RuiNode, key: string, value: T) {
  if(!node) return;
  if (!node.data) node.data = {};
  node.data[key] = value;
}