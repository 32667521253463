import { Tooltip } from './Tooltip';
import { DropdownItems, useDropdown } from './Dropdown';
import './Input.css';
import React from 'react';

export interface SelectProps<T extends (string | number)> {
  label?: string;
  value?:  T;
  onChange?: (value:  T) => void;
  disabled?: boolean;
  items?: DropdownItems;
  tooltip?: string
}

export function Select<T extends (string | number)>(props: SelectProps<T>) {
  const id = React.useMemo(() => Math.random().toString(), []);
  const dropdown = useDropdown({
    items: props.items || [],
    onActivateItem: (id) => {
      if(props.onChange) {
        props.onChange(id as any);
      }
    }
  });

  let label = 'unset';

  if(props.items?.length && props.value) {
    const selected: any = props.items.find((i: any) => i.id === props.value);
    label = selected?.label || 'unset';
  }

  return <>
    <div
      data-tooltip-id={id}
      data-tooltip-content={props.tooltip}
      className='blocks input-group'
    >
      <span className='label'>
        {props.label}
      </span>
      <span className='label flex-grow'>
        {label}
      </span>
      <span className='label dropdown' onClick={dropdown.open}>{'-'}</span>
    </div>
    {props.tooltip ? <Tooltip id={id} delayShow={500}/> : null}
    {dropdown.jsx}
  </>
}