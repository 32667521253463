import { ActivityLog, ActivityLogFilters, listActivityLogs } from "@/logic/activity_logs";
import { useMethod } from "@roc-digital/ui-lib";
import React from "react";
import { Table } from "./Table";
import { TableSection } from "./TableSection";
import { TableCell } from "./TableCell";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";
import { readUsersMany } from "@/logic/users";

export interface ActivityLogViewProps {
  userId?: string;
  articleId?: string;
}

export function ActivityLogView(props: ActivityLogViewProps) {
  const query = useMethod(fetchLogs, {
    defaultValue: []
  });

  React.useEffect(() => {
    query.debounce([{
      article_id: props.articleId,
      user_id: props.userId
    }])
  }, [props.articleId, props.userId])

  let noActivityMessage = '';

  if(props.userId) {
    noActivityMessage = 'No recorded activity for this user.';
  } else if(props.articleId) {
    noActivityMessage = 'No recorded activity for this article.';
  } else {
    noActivityMessage = 'No activity.';
  }

  return <>
    <Table>
      <TableSection type="head">
        <TableRow>
          <TableHeader className={'min-w-[110px]'}>Date</TableHeader>
          <TableHeader className={'w-3/12'}>User</TableHeader>
          <TableHeader className={'w-8/12'}>Description</TableHeader>
        </TableRow>
      </TableSection>
      <TableSection>

        {query.result?.map(r => {
          return <TableRow>
            <TableCell>{new Date(r.date * 1000).toLocaleString()}</TableCell>
            <TableCell>{r.user}</TableCell>
            <TableCell>{r.description}</TableCell>
          </TableRow>
        })}
      </TableSection>
    </Table>

    {query.count && query.result?.length === 0 && query.loading !== true ? <>
      <div style={{textAlign: 'center', color: 'gray', fontSize: '12px', marginTop: '12px'}}>
        {noActivityMessage}
      </div>
    </> : <></>}
  </>
}


interface LogItem extends ActivityLog {
  user: string;
}

async function fetchLogs(filters: ActivityLogFilters): Promise<LogItem[]> {
  const logs = await listActivityLogs(filters);
  const ids = logs
  .map(l => l.user_id)
  .filter((id, i, l) => l.indexOf(id) === i && id) as string[];
  
  const users = await readUsersMany(ids);

  return logs.map(l => {
    const user = users.find(u => u.id === l.user_id);
    return {
      ...l,
      user: user?.name || user?.email || 'Unknown'
    }
  })

}