export interface SpinnerBackdropProps {
  active?: boolean;
  children?: JSX.Element;
}

export function SpinnerBackdrop(props: SpinnerBackdropProps) {

  if(!props.active) {
    return <></>;
  }

  return <div className='absolute top-0 bottom-0 w-full h-full flex justify-center' style={{backgroundColor: 'rgba(255,255,255,.5)'}}>
    {props.children}
  </div>
}