import { RuiDocument } from "@roc-digital/rich-ui";
import { JsonFetch } from "@roc-digital/mxm-base/logic";
import { AUX_API } from '@/env';
import { ContentBlock } from '@roc-digital/rich-ui';
export type { ContentBlock } from '@roc-digital/rich-ui';

export interface UiBlocksFilters {
  query?: string;
  class?: string;
  draft?: boolean;
  published?: boolean;
  deleted?: boolean;
  count?: number;
}
export async function listBlocks(filters?: UiBlocksFilters){
  return JsonFetch<ContentBlock[]>({url: `${AUX_API}/admin/ui-blocks`, query: filters});
}

export async function readBlock(id: string) {
  if(id === 'new') return DEFAULT_BLOCK;
  return JsonFetch<ContentBlock>({url: `${AUX_API}/admin/ui-blocks/${id}`});
}

export async function readManyBlocks(ids: string[]) {
  return JsonFetch<ContentBlock[]>({method: 'POST', url: `${AUX_API}/admin/ui-blocks/read-many`, data: ids});
}

export function createBlock(block: ContentBlock) {
  return JsonFetch<ContentBlock>({method: 'POST',url: `${AUX_API}/admin/ui-blocks`, data: block});
}

export function duplicateBlock(id: string) {
  return JsonFetch<ContentBlock>({method: 'POST',url: `${AUX_API}/admin/ui-blocks/${id}/duplicate`, data: {}});
}

export async function updateBlock(id: string, block: ContentBlock) {
  block = {
    ...block,
    id,
  };
  return JsonFetch<ContentBlock>({method: 'PATCH', url: `${AUX_API}/admin/ui-blocks/${block.id}`, data: block});
}

export function upsertBlock(block: ContentBlock) {
  if (block.id) {
    return updateBlock(block.id, block);
  } else {
    return createBlock(block);
  }
}

export async function publishBlock(id: string) {
  return JsonFetch<ContentBlock>({method: 'PATCH', url: `${AUX_API}/admin/ui-blocks/${id}/publish`, data: {}});
}

export async function deleteBlock(id: string) {
  return JsonFetch<void>({method: 'DELETE', url: `${AUX_API}/admin/ui-blocks/${id}`});
}

export const DEFAULT_DOC: RuiDocument = {
  "children": [
    {
      "element": "container",
      "children": [
        {
          "element": "container",
          "children": [
            {
              "element": "paragraph",
              "children": [],
              "data": {
                "html": "<p style=\"text-align: center;\"><strong><span style=\"color: rgb(236, 240, 241); font-size: 18pt;\">Welcome to the MXM News UI Creator</span></strong></p>"
              }
            }
          ],
          "data": {
            "alignment": "middle-center",
            "backgroundColor": "#24aae1",
            "borderRadius": 20,
            "direction": "column",
            "height": "fill",
            "marginLeft": 0,
            "marginTop": 0,
            "paddingLeft": 20,
            "paddingTop": 20,
            "width": "fill"
          }
        }
      ],
      "data": {
        "alignment": "top-left",
        "backgroundColor": "#ffffff",
        "direction": "column",
        "gap": 8,
        "height": "hug",
        "width": "fill"
      }
    }
  ]
};

export const EMPTY_DOCUMENT: RuiDocument = {
  children: []
}

export const DEFAULT_BLOCK: ContentBlock = {
  id: '',
  class: 'generic',
  label: 'Untitled Ui Block',
  document: {children: []},
  draft: {children: []},
}