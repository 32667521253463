import React from 'react';
import './RadioGroup.css';
import { Tooltip } from 'react-tooltip';

export interface RadioGroupProps<T extends string> {
  value?: T;
  onChange?: (id: T) => void;
  options: RadioGroupOption<T>[];
}

export interface RadioGroupOption<T extends string> {
  id: T;
  tooltip: string;
  content: string; 
}

export function RadioGroup<T extends string>(props: RadioGroupProps<T>) {
  const id = React.useMemo(() => Math.random().toString(), []);

  return <div className="blocks radio-group">
    {props.options.map((option => {
      return <div
        key={option.id}
        data-tooltip-id={id}
        data-tooltip-content={option.tooltip}
        onClick={() => props.onChange?.(option.id)}
        className={`option ${option.id === props.value ? 'active' : ''}`}>
          {option.content}
        </div>
    }))}
    <Tooltip id={id} delayShow={500} />
  </div>
}