import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { calendarIcon, useClickEvent } from '@roc-digital/ui-lib';
import { Icon } from './Icon';
import { Pressable } from './Pressable';

type InputWithCalendarProps = {
  placeholder?: string;
};

export const InputWithCalendar: React.FC<InputWithCalendarProps> = ({ placeholder }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const datePickerRef = useRef<any>(null);

  const openDatePicker = useClickEvent(
    () => {
      datePickerRef.current.setOpen(true);
    },
    {},
    {},
    [datePickerRef]
  );

  return (
    <div className="flex items-center rounded-md overflow-hidden w-1/4">
      <DatePicker
        ref={datePickerRef}
        selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        className="p-2 flex-grow text-sm border-2"
        placeholderText={placeholder}
        popperPlacement="bottom-end"
      />
      <Pressable className="absolute left-[170px] top-5" clickEvent={openDatePicker}>
        <Icon src={calendarIcon} size={'small'} />
      </Pressable>
    </div>
  );
};
