import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const Card = ({ children, className }: Props) => {
  return (
    <div
      className={`bg-white w-full md:w-auto md:max-w-[374px] h-auto md:h-[396px] overflow-auto p-5 rounded-md shadow-md ${className}`}
    >
      {children}
    </div>
  );
};
