import React from 'react';
import {
  BodyLight,
  Button,
  ButtonSizes,
  ButtonVariants,
  Heading1,
  Heading2,
  Loading,
  BodyDark,
  Input,
  Select,
  SpinnerBackdrop,
} from '@/components/elements';
import { readTag, putTag, adminDeleteTags } from '@roc-digital/mxm-base/logic';
import { InputData, useMethod, } from '@roc-digital/ui-lib';
import { useNavigate, useParams } from 'react-router-dom';
import { TagSchema } from '@roc-digital/mxm-base/src/data';
import { publish } from '@roc-digital/mxm-base/events';

const categories = [
  { label: 'Category', value: 'category' },
  { label: 'Topic', value: 'topic' },
  { label: 'Location', value: 'location' },
  { label: 'Curator', value: 'curator' },
  { label: 'Author', value: 'author' },
  { label: 'Publication', value: 'publication' },
  { label: 'Flag', value: 'flag' },
];
import { toast } from 'react-toastify';
import ImageUploadForm from '@/components/elements/ImageUploadForm';

export function EditTag() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [tag, setTag] = React.useState<TagSchema>({} as any);
  const [isModified, setIsModified] = React.useState(false);

  const readTagQuery = useMethod(readTag, {
    defaultValue: {} as any,
    onSuccess: (tag) => setTag(tag.toParams()),
  });

  const putTagQuery = useMethod(putTag, {
    onSuccess: (tag, args) => {
      if(args[0]?.id) {
        toast.success('Tag updated successfully');
        readTagQuery.run(args[0]?.id)
      } else {
        toast.success('Tag created successfully');
        navigate(`/tags/${tag?.id}`);
      }
      
    },
    onError: (err, args) => {
      console.error(err);
      if(args[0]?.id) {
        toast.error('Tag failed to update:' + err);
      } else {
        toast.error('Tag failed to create:' + err);
      }
    }
  });

  const deleteTagQuery = useMethod(adminDeleteTags, {
    onBefore: async (args) => {
      if(!confirm('Are you sure you want to delete this tag?')) {
        return;
      }

      return args;
    },
    onSuccess: () => {
      toast.success("Tag deleted!");
      navigate('/tags')
    },
    onError: (err) => {
      console.error(err);
      toast.error("Failed to delete tag");
    }
  })

  React.useEffect(() => {
    if (!id) return;

    if(id === 'new') {
      setTag({
        is_active: false,
        type: 'category'
      })
      return;
    }

    readTagQuery.run(id);
  }, [id]);

  const updateTag = (changes: Partial<TagSchema>) => {
    setTag(current => ({...current,...changes}));
    setIsModified(true);
  }

  const cancelChanges = () => {
    setTag(readTagQuery.result?.toParams() || {});
    setIsModified(false);
  }

  const loading = readTagQuery.loading || putTagQuery.loading || deleteTagQuery.loading;

  const saveUrl = (data: any) => {
    let updatedTag = {
      ...tag
    };
    updatedTag.image_url = data.publicPath;
    setTag(updatedTag);
    setIsModified(true);
  }

  return <>
    <div className="flex flex-col w-4/5">
      <div className="flex h-16 mr-6 mt-3 align-middle gap-4">
        <Heading1 className="text-2xl text-black flex-grow">
          {id == 'new' ? 'Create' : 'Edit'} Tag
        </Heading1>

        <Button
            size={ButtonSizes.medium}
            variant={ButtonVariants.secondary}
            disabled={loading || !isModified}
            clickEvent={cancelChanges}
          >
            <BodyLight className={'font-light'}>Cancel</BodyLight>
          </Button>
          <Button
            size={ButtonSizes.medium}
            clickEvent={() => putTagQuery.run(tag)}
            variant={isModified ? ButtonVariants.primary : ButtonVariants.disabled}
            disabled={loading || !isModified}
          >
            <BodyLight className={`${isModified && 'text-white'} font-light`}>Save</BodyLight>
          </Button>

      </div>
      <div className="flex flex-col bg-white flex-grow p-10 mr-6 rounded gap-4 relative">
        <BodyDark className={'text-sm'}>Name*</BodyDark>
        <Input
          className={'mb-4'}
          value={tag?.name || ''}
          changeEvent={(event) => updateTag({name: event.target.value})}
        />

        <BodyDark className={'text-sm'}>Tag*</BodyDark>
        <Input
          className={'mb-4'}
          value={tag?.tag || ''}
          changeEvent={(event) => updateTag({tag: event.target.value})}
        />

        <BodyDark className={'text-sm'}>Type*</BodyDark>
        <Select
          size="small"
          value={tag?.type || ''}
          options={categories}
          changeEvent={(event) => updateTag({type: event.target.value })}
        ></Select>

        <BodyDark className={'text-sm'}>Status*</BodyDark>
        <Select
          size="small"
          value={tag?.is_active === true ? 'active' : 'inactive'}
          options={[
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
          ]}
          changeEvent={(event) => updateTag({is_active: event.target.value == 'active' ? true : false })}
        ></Select>

        <BodyDark className={'text-sm'}>Link Url</BodyDark>
        <Input
          className={'mb-4'}
          value={tag?.link_url || ''}
          changeEvent={(event) => updateTag({link_url: event.target.value})}
        />

        <BodyDark className={'text-sm'}>Image Url</BodyDark>
        <Input
          className={'mb-4'}
          value={tag?.image_url || ''}
          changeEvent={(event) => updateTag({image_url: event.target.value})}
        />
        <SpinnerBackdrop active={loading}><Loading type='critical' size='large'/></SpinnerBackdrop>
        <ImageUploadForm returnUrl={saveUrl}/>
      </div>

      {id && id !== 'new' && !loading ? <>
        <div className={'flex flex-col justify-between p-4 gap-4'}>
        <Heading2 className={'!text-2xl text-admin-dark'}>Danger Zone</Heading2>
        <div className="flex-row">
          <Button
            size={ButtonSizes.medium}
            clickEvent={() => deleteTagQuery.run(id)}
            variant={ButtonVariants.destructive}
            disabled={loading as boolean}
          >
            Delete Tag
          </Button>
        </div>
      </div>      
      </> : <></>}

    </div>
  </>
}