import { getClassNames } from '@roc-digital/ui-lib';
import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  htmlFor?: string;
  className?: string;
};

export const textStyleClasses = {
  base: ``,
  size: {
    title: 'font=font-semibold text-lg',
    //body: `font-normal leading-6`,
    //strong: `font-semibold leading-6`,
    //caption: `font-normal leading-5 text-sm`,
    //button: `font-medium leading-6`,
    small: `leading-5 text-sm`,
    tiny: `leading-4 text-xs`,
    custom: '',

    displayXLarge: 'font-rajdhani font-semibold text-4xl leading-10 tracking-[-0.02em]',
    displayLarge: 'font-rajdhani font-semibold text-3xl leading-8 tracking-[-0.01em]',
    displayMedium: 'font-rajdhani font-semibold text-xl leading-7 tracking-[-0.01em]',
    displaySmall: 'font-rajdhani font-semibold text-lg leading-5 tracking-[-0.01em]',
    heading: 'font-rajdhani font-semibold text-base leading-6 tracking-[0.02em]',
    tab: 'font-rajdhani font-semibold text-[15px] leading-4 tracking-[0.03em]',
    label: 'font-rajdhani font-semibold text-sm leading-4 tracking-[0.03em]',
    subHeading: 'font-rajdhani font-semibold text-sm leading-4 tracking-[0.03em]',
    body: 'font-roboto font-normal text-sm leading-5',
    strong: 'font-roboto font-medium text-sm leading-5',
    caption: 'font-roboto text-xs leading-4',
    captionItalics: 'font-roboto font-medium font-light italic text-xs leading-4',
    captionBold: 'font-roboto font-medium text-xs leading-4',
    button: 'font-roboto font-medium text-sm leading-5',
  },
  color: {
    default: `text-font`,
    subdued: `text-font-muted`,
    disabled: `text-font-light`,
    info: `text-font-info-light`,
    success: `text-font-success-high`,
    warning: `text-font-warning-high`,
    critical: `text-font-critical-high`,
    custom: '',
  },
};

export const Label = ({ children, htmlFor, className }: Props) => {
  const textClasses = getClassNames(textStyleClasses, { size: 'label', color: 'default' });

  return (
    <label htmlFor={htmlFor} className={`${textClasses} mb-1 leading-5 text-font-muted ${className}`}>
      {children}
    </label>
  );
};
