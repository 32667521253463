import React from "react"
import { ElementStarter, Elements } from './elements';
import { useDropdown, DropdownItems } from "./components/Dropdown";

export function useAddElementDropdown(onNew?: (starterElement: ElementStarter) => void) {

  const state = React.useMemo(() => {
    const state: {items: DropdownItems, starters: {[id: string]: ElementStarter}} = {
      items: [],
      starters: {}
    }

    state.items = Elements.map(element => {
      return element.getElementStarters().map(item => {
        state.starters[item.id] = item;
        return {
          id: item.id,
          label: item.name,
        }
      })
    }).flatMap(a => a);

    return state;
  }, []);

  const dropdown = useDropdown({
    items: state.items,
    onActivateItem: (itemId: string) => {
      if(onNew) {
        onNew(state.starters[itemId]);
      }
    }
  });

  const open = React.useCallback((event: MouseEvent) => {
    return dropdown.open(event).then(itemId => state.starters[itemId])
  }, [state]);

  return {
    jsx: dropdown.jsx,
    open,
    close: dropdown.close,
  };
}