import { BodyDark } from './Typefaces';
export interface FormRowProps {
  children: any;
}

export function FormRow(props: FormRowProps) {
  return <div className="flex flex-row gap-4">
    {props.children}
  </div>;
}

export interface FormGroupProps {
  title: string;
  children: any;
  grow?: number;
  gap?: string
}

export function FormGroup(props: FormGroupProps) {
  return <div className="flex flex-col gap-4" style={{
    flexGrow: props.grow === undefined ? 1 : props.grow,
  }}>
    <FormLabel>{props.title}</FormLabel>
    <div
      className='flex flex-row flex-col h-[100%] justify-center'
      style={{
        gap: props.gap === undefined ? 1 : props.gap
      }}>
      {props.children}
    </div>
  </div>
}

export interface FormLabelProps {
  children: any;
}

export function FormLabel(props: FormLabelProps) {
  return <BodyDark className={'text-sm'}>{props.children}</BodyDark>
}