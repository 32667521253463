import React from 'react';
import { publish } from '@roc-digital/mxm-base/events';
import { Event } from '@roc-digital/mxm-base/types';
import { ClickEvent, PublisherData, useClickEvent, publishClick } from '@roc-digital/ui-lib';
import { Button, ButtonSizes } from './Button';
import { Text } from './Text';

type Props = {
  modalId?: string;
  className?: string;
  padding?: string;
  title?: string;
  text?: string;
  positiveButton?: string;
  positiveButtonClassName?: string;
  positiveEvent?: ClickEvent;
  positiveLoading?: boolean;
  negativeButton?: string;
  negativeButtonClassName?: string;
  negativeEvent?: ClickEvent;
  disablePositiveButton?: boolean;
  children?: React.ReactNode;
};

export const ModalContent = ({
  modalId = 'mxm.modal',
  className = '',
  padding = 'p-6',
  title,
  text,
  positiveLoading,
  positiveButton,
  positiveEvent,
  negativeButton,
  negativeEvent,
  disablePositiveButton,
  children,
}: Props) => {
  const negativeClickEvent = useClickEvent((evt: Event<PublisherData>) => {
    if (modalId) publish(modalId, 'close');

    publishClick(evt.data.htmlEvent as React.MouseEvent<HTMLButtonElement>, negativeEvent);
  });

  return (
    <div className={`flex flex-col ${className} ${padding}`}>
      <div className="flex flex-row justify-between mb-5">
        {title && <Text className="text-xl font-medium">{title}</Text>}
      </div>
      {text && <Text className="mb-6">{text}</Text>}
      {children && <div className="overflow-y-auto mb-6">{children}</div>}
      <div className="flex flex-row justify-end ml-8">
        {negativeButton && (
          <div>
            <Button size={ButtonSizes.small} clickEvent={negativeClickEvent}>
              {negativeButton}
            </Button>
          </div>
        )}
        {positiveButton && (
          <div>
            <Button
              size={ButtonSizes.small}
              clickEvent={positiveEvent}
              disabled={disablePositiveButton}
              loading={positiveLoading}
            >
              {positiveButton}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
