import React from "react";
import { Elements } from "./elements";
import { document2Dom } from "./utils/document2Dom";
import { RuiEditorApi } from "./RuiApi";
import { useValueRef } from "@/hooks/useValueRef";
import { RuiDocument, findTextTokens } from "@roc-digital/rich-ui";
import { dom2Document } from "./utils/dom2Document";

export interface EditorProps {
  value: RuiDocument;
  onChange(value: RuiDocument): void;
}

export function useDocumentValue(
  value: RuiDocument,
  onChange: (value: RuiDocument) => void
) {
  const onChangeRef = useValueRef(onChange);
  const valueRef = useValueRef(value);
  const undoData = React.useMemo<RuiDocument[]>(() => [],[]);
  const [_, setRenders] = React.useState<number>(0);

  if(!value['lineageId']) value['lineageId'] = Math.random().toString();

  const { root, api } = React.useMemo(() => {
    const api = new RuiEditorApi(Elements, () => {
      if(root) {
        const nextDocument = dom2Document(root, api);
        nextDocument['lineageId'] = value['lineageId'];
        nextDocument.textTokens = findTextTokens(nextDocument.children[0]);
        valueRef.current = nextDocument;
        undoData.push(nextDocument);
        onChangeRef.current(nextDocument);
      }
    },
    () => setRenders(r => r + 1),
    );

    const root = document2Dom(value, api) || undefined;

    return { root, api };

  }, [value['lineageId']]);

  const undo = React.useCallback(() => {
    if(undoData.length > 1) {
      undoData.pop();
      const current = undoData[undoData.length-1];
      onChangeRef.current(current);
    }
  }, [])

  const apiRef = useValueRef(api);

  return {
    root,
    apiRef,
    undo
  }
}