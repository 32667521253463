import { PopupAdminType } from '@roc-digital/types/popups';
import { genericThreePopupType } from './GenericPopup';
import { genericOnePopupType } from './GenericOnePopup';
import { genericTwoPopupType } from './GenericTwoPopup';
import { updateProfilePopupType } from './UpdateProfilePopup';

export const popupTypes: PopupAdminType<any>[] = [
  genericOnePopupType,
  genericTwoPopupType,
  genericThreePopupType,
  updateProfilePopupType,
];