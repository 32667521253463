import React from "react";
import { Viewport } from "./Viewport"
import { useAddElementDropdown } from "./useAddElementDropdown";
import { Elements } from "./elements";
import { Editor as TME } from '@tinymce/tinymce-react';
import { useContextMenu } from "./useContextMenu";
import { useValueRef } from "@/hooks/useValueRef";
import { RuiDocument, RuiNode } from "@roc-digital/rich-ui";
import { useDocumentValue } from "./useDocumentValue";
import { TreeView } from "./components/TreeView";
import { getNodeFromClipboardEvent, putNodeInClipboard } from "./utils/copy-paste";

export interface UiBlockEditorProps {
  value: RuiDocument;
  onChange(value: RuiDocument): void;
}

export function UiBlockEditor(props: UiBlockEditorProps) {
  const [copyElement, setCopyElement] = React.useState<HTMLElement | null>(null);
  const copyElementRef = useValueRef(copyElement);
  const addElementDropdown = useAddElementDropdown();
  const [selectedElement, setSelectedElement] = React.useState<HTMLElement | undefined>();
  const selectedElementRef = useValueRef(selectedElement);
  const {root, apiRef} = useDocumentValue(props.value, (doc) => {
    props.onChange(doc);
  });

  const viewControls = React.useMemo(() => {
    if (!selectedElement) return null;
    const node = apiRef.current.getRuiNodeFromDomNode(selectedElement);

    const elementDef = Elements.find(e => e.id === node?.element);

    if (elementDef && node) {
      return elementDef.renderEditControls(node, apiRef.current);
    }

    return null;
  }, [selectedElement, apiRef.current]);

  const performCopy = React.useCallback(() => {
    if(!selectedElementRef.current) return;

    setCopyElement(selectedElementRef.current);
    const node = apiRef.current.dom2Node(selectedElementRef.current);
    putNodeInClipboard(node);
  }, []);

  const performPaste = React.useCallback((sourceNode?: RuiNode) => {

    if(!selectedElementRef.current) {
      return null;
    }

    if(!sourceNode && copyElementRef.current) {
      sourceNode = apiRef.current.dom2Node(copyElementRef.current);
    }

    if(!sourceNode) return;

    const c = apiRef.current.importNodeIntoDom(sourceNode, selectedElementRef.current);
    setSelectedElement(c);
  }, []);


  React.useEffect(() => {
    const onCopy = (event: ClipboardEvent) => {
      performCopy()
    }
    const onPaste = (event: ClipboardEvent) => {
      const node = getNodeFromClipboardEvent(event);
      if(node) performPaste(node);
    }
    
    window.addEventListener('copy', onCopy as any);
    window.addEventListener('paste', onPaste as any);

    return () => {
      window.removeEventListener('copy', onCopy as any);
      window.removeEventListener('paste', onPaste as any);
    }
  }, [])

  const contextMenu = useContextMenu({
    hasElement: selectedElement ? true : false,
    canPaste: copyElement ? true : false,
    onAction: (action) => {
      if (!selectedElement) return
      if (action === 'copy') {
        performCopy();
      } else if (action === 'paste') {
        performPaste();
      } else if (action === 'delete') {
        selectedElement.remove();
        setSelectedElement(undefined);
      } else if (action === 'clone') {
        const node = apiRef.current.dom2Node(selectedElement);
        if(selectedElement.parentElement) {
          apiRef.current.importNodeIntoDom(node, selectedElement.parentElement);
        }
        
      } else if (action === 'selectParent') {
        setSelectedElement(selectedElement.parentElement as any);
      }
    }
  });

  const textApi = React.useMemo(() => {
    let currentE: any = null;
    let currentMCE: any = null;
    const editText = (element: HTMLElement) => {
      if (element !== currentE && currentMCE) {
        currentMCE.destroy();
        currentMCE = null;
      }

      if (element !== currentE && element) {
        if (element.dataset.elementId !== 'paragraph') return;
        currentE = element;
        // @ts-ignore
        tinymce.init({
          target: element,
          ...TINY_CONFIG
        }).then((e: any) => {
          currentMCE = e[0];
          currentMCE.on('change', () => apiRef.current.markChange());
        })
      }
    }

    const onElementSelectionChange = (element: HTMLElement | null) => {
      if (element === currentE) return;
      if (currentMCE) currentMCE.destroy();
      if (currentE) currentE.style.outline = 'none';
      currentE = null;;
      currentMCE = null;
    }

    return {
      editText,
      onElementSelectionChange
    }
  }, [])

  return <>
    <div className="flex-grow h-[100%] flex-col padding-[4px]" style={{
      backgroundColor: 'white',
      overflow: 'auto',
      borderTopRightRadius: '12px'
    }} onFocus={() => {
      // Remove outside outside selection when the viewport is focused.
      // Sometimes selections can linger and be hidden which prevents copy-pasting from working.
      // const selection = window.getSelection();
      // if(selection?.rangeCount) selection.empty();
    }} tabIndex={1}>
      <Viewport
        root={root}
        selectedElement={selectedElement}
        onSelectedElement={(e) => {
          setSelectedElement(e);
          textApi.onElementSelectionChange(e);
        }}
        onDoubleClickElement={(element: HTMLElement) => {
          textApi.editText(element);
        }}
        onMoveElement={(element, target, index) => {
          // TODO: Detect that target isn't in element;
          element.remove();
          target.insertBefore(element, target.children[index] || null);
        }}
        onInsertNewElement={(target, index, event) => {
          addElementDropdown.open(event).then(starter => {
            if (!starter) return null;
            return starter.createNew(starter)
          })
            .then(newNode => {
              if (!newNode) return;
              const parent = apiRef.current.getRuiNodeFromDomNode(target);
              const domNode = apiRef.current.createDomNode(newNode, parent?.id);

              if (domNode instanceof HTMLElement) {
                target.insertBefore(domNode, target.children[index]);
                apiRef.current.renderNode(newNode);
                setSelectedElement(domNode);
              }
            })
        }}
      />
    </div>
    <div className="w-[300px] min-w-[300px] h-[100%] p-2 flex flex-col gap-[8px]">
      <TreeView
        api={apiRef.current}
        root={root}
        document={props.value}
        selectedElement={selectedElement}
        onSelectedElement={(e) => {
          setSelectedElement(e);
          textApi.onElementSelectionChange(e);
        }}
        onMoveElement={(element, target, index) => {
          // TODO: Detect that target isn't in element;
          element.remove();
          target.insertBefore(element, target.children[index] || null);
        }}
      />
      {viewControls}
    </div>
    {addElementDropdown.jsx}
    {contextMenu.jsx}
    {apiRef.current.getJsxPortals()}
    <TME
      tinymceScriptSrc={"/tinymce/js/tinymce/tinymce.min.js"}
      onScriptsLoad={() => { }}
      init={TINY_CONFIG}
    />
  </>
}

const DEFAULT_FONT = 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji';
const CAPTION_FONT = 'Chivo, sans-serif';
const BUTTON_FONT = '';
const FONT_OPTIONS = [
  {name: 'Default Font', value: DEFAULT_FONT},
  {name: 'Caption Font', value: CAPTION_FONT},
  {name: 'Button Font', value: BUTTON_FONT},
].map(item => `${item.name}=${item.value}`).join(';')

const TINY_CONFIG: any = {
  inline: true,
  menubar: false,
  auto_focus: '',
  placeholder: 'Type here...',
  font_family_formats:FONT_OPTIONS,
  default_font_stack: [ '-apple-system', 'Arial' ],
  font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
  line_height_formats: '1 1.1 1.2 1.3 1.4 1.5 2',
  plugins: [
    'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview',
    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
  ],
  toolbar: 'styles bold italic strikethrough forecolor fontfamily fontsize | lineheight alignleft aligncenter ' +
    'alignright alignjustify bullist outdent indent link removeformat',
  content_style: `body { font-family:${DEFAULT_FONT}; font-size:14px; line-height: 1.1; }`,
  formats: {
    caption_1: {inline: 'span', styles: { fontSize: '48pt', fontFamily: CAPTION_FONT, fontWeight: 'bold', lineHeight: 1.1 }},
    caption_2: {inline: 'span', styles: { fontSize: '24pt', fontFamily: CAPTION_FONT, fontWeight: 'bold', lineHeight: 1.1  }},
    article_content: {inline: 'span', styles: { fontSize: '14pt', fontFamily: DEFAULT_FONT, fontWeight: 'normal', lineHeight: 1.1  }},
    button: {inline: 'span', styles: { fontSize: '15pt', fontFamily: BUTTON_FONT, fontWeight: 'bold', lineHeight: 1.1  }},
  },
  style_formats: [
    { title: 'Caption 1', format: 'caption_1' },
    { title: 'Caption 2', format: 'caption_2' },
    { title: 'Article Content', format: 'article_content' },
    { title: 'Button Text', format: 'button' },
  ]
}