import React from 'react';
export function useOnChangeHook<T extends object, K extends keyof T>(value: T, onChange?: (next: T) => void) {
  const [current, setValue] = React.useState(value);
  const lastZone = React.useRef(value);

  React.useEffect(() => {
    if(value !== lastZone.current) {
      setValue(value);
    }
  }, [value]);


  const changeProp = (key: K, value: T[K]) => {
    const next = {...current, [key]: value};
    lastZone.current = next;
    setValue(next);
    if(onChange) {
      onChange(next);
    }
  }

  const change = (value: T) => {
    const next = {...current, ...value};
    lastZone.current = next;
    setValue(next);
    if(onChange) {
      onChange(next);
    }
  }

  return [current, change, changeProp] as [
    T,
    ((value: T) => void),
    ((key: K, value: T[K]) => void),
  ];
}