import { memo, useState } from 'react';
import { mxmIcon, useCustomEvent, useMethod } from '@roc-digital/ui-lib';
import { SessionState } from '@roc-digital/mxm-base/state';
import { Button, Heading1, Icon, Input, ButtonSizes } from '@/components/elements';
import { signinWithFirebase } from '@/logic';

const Login = memo(() => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useCustomEvent(
    'signin.failed',
    () => {
      setLoading(false);
      alert('Incorrect username or password');
    },
    { namespace: 'mxm.auth' },
    {},
    []
  );

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    setLoading(true);
    signinWithFirebase(SessionState, email, password);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-200 w-full">
      <form onSubmit={handleFormSubmit}>
        <div className="mb-12 flex flex-col justify-center items-center text-center align-center text-3xl text-admin-dark">
          <Icon className={'mb-4'} src={mxmIcon} size={'large'} />
          <Heading1 className="text-3xl text-admin-dark">Admin Login</Heading1>
        </div>
        <div className="mb-4 w-80">
          <label className="block mb-2 text-sm font-medium text-gray-700">Email Address</label>
          <Input
            type="email"
            value={email}
            changeEvent={(event) => setEmail(event.target.value)}
            className="w-full mb-0 px-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-8 w-80">
          <label className="block mb-2 text-sm font-medium text-gray-700">Password</label>
          <Input
            type="password"
            value={password}
            changeEvent={(event) => setPassword(event.target.value)}
            className="w-full mb-3 px-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-4 w-80">
          <Button clickEvent={handleFormSubmit} loading={loading} size={ButtonSizes.smallFull}>
            Sign in
          </Button>
        </div>
      </form>
    </div>
  );
});

Login.displayName = 'Login';

export default Login;
