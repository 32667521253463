import { Button, ButtonSizes, Heading2 } from "@/components/elements";
import { clearCache } from "@/logic";
import { useMethod } from "@roc-digital/ui-lib";
import { toast } from "react-toastify";

export function ClearCache() {
  const query = useMethod(clearCache, {
    onBefore: async (args) => {
      if(!confirm("Are you sure you want to dump the MxM HTTP cache?")) {
        return;
      }
      return args;
    },
    onError: () => {
      toast.error('There was an error clearing the cache.')
    },
    onSuccess: () => {
      toast.success('Cache cleared!');
    }
  })
  return (
    <div className="flex flex-row">
      <div className="flex flex-col flex-grow">
        <Heading2>Clear cache</Heading2>
        <span>This will clear the HTTP cache for all of MxM News.</span>
      </div>
      <Button clickEvent={query.run} size={ButtonSizes.small} loading={query.loading} disabled={query.loading}>Clear</Button>
    </div>   
  );
}