import { memo } from 'react';
import { ClickEvent, ChangeEvent, InputData } from '@roc-digital/ui-lib';
import { User } from '@roc-digital/mxm-base/data';
import { BodyDark, BodyLight, Input, Pressable, Select, Spacer, TextArea } from '@/components/elements';
import ImageUploadForm from '@/components/elements/ImageUploadForm';
import { publish } from '@roc-digital/mxm-base/events';

type CuratorProps = {
  user?: User;
  clickEvent?: ClickEvent;
  changeEvent?: ChangeEvent;
  editMode?: boolean;
};

const Curator = memo(({ user: curator, clickEvent, changeEvent, editMode }: CuratorProps) => {

  const saveUrl = (data: any) => {
    console.log('URL captured in parent', data.publicPath);
    publish<InputData>(changeEvent?.namespace || '', 'change', {
      id: 'avatar_url', // I think should be the name of the property you want to change inside of the user object.
      value: data.publicPath, // the next value of the property,
      eventData: null as any
    });
  }

  return (
    <>
      <div className={'my-4'}>
        <BodyDark>Bio</BodyDark>
        <Pressable id={'bio'} clickEvent={clickEvent}>
          <TextArea value={curator?.bio} changeEvent={changeEvent} id={'bio'}></TextArea>
        </Pressable>
      </div>
      <Spacer />
      <div className={'flex flex-row justify-between mt-4 mb-4'}>
        <BodyDark>User Id</BodyDark>
        <BodyLight>{curator?.id}</BodyLight>
      </div>
      <Spacer />
      <div className={'flex flex-row justify-between mt-4 mb-4'}>
        <BodyDark>Login Type</BodyDark>
        <BodyLight>{curator?.login_type || '-'}</BodyLight>
      </div>
      <Spacer />
      <div className={'flex flex-row justify-between mt-4 mb-4'}>
        <BodyDark>Status</BodyDark>
        <Pressable id={'status'} clickEvent={clickEvent}>
          <Select
            id={'state'}
            changeEvent={changeEvent}
            value={curator?.state}
            size="x-small"
            options={[
              { label: 'Verified', value: 'verified' },
              { label: 'Not Verified', value: 'not_verified' },
            ]}
          />
        </Pressable>
      </div>
      <Spacer />
      <div className={'flex flex-row justify-between mt-4 mb-4'}>
        <BodyDark>Email</BodyDark>
        <Pressable clickEvent={clickEvent}>
          {editMode ? (
            <Input value={curator?.email} changeEvent={changeEvent} id={'email'} />
          ) : (
            <BodyLight>{curator?.email || '-'}</BodyLight>
          )}
        </Pressable>
      </div>
      <Spacer />
      <div className={'flex flex-row justify-between mt-4 mb-4'}>
        <BodyDark>Phone Number</BodyDark>
        <Pressable clickEvent={clickEvent}>
          {editMode ? (
            <Input value={curator?.phone} changeEvent={changeEvent} id={'phone'} />
          ) : (
            <BodyLight>{curator?.phone || '-'}</BodyLight>
          )}
        </Pressable>
      </div>
      <div className={'flex flex-row justify-between mt-4 mb-4'}>
        <BodyDark>Avatar Url</BodyDark>
        <Pressable clickEvent={clickEvent}>
          {editMode ? (
            <>
              <Input value={curator?.avatar_url} changeEvent={changeEvent} id={'avatar_url'} />
              <ImageUploadForm returnUrl={saveUrl}/>
            </>
          ) : (
            <BodyLight>{curator?.avatar_url || '-'}</BodyLight>
          )}
        </Pressable>
        
      </div>
    </>
  );
});

Curator.displayName = 'Curator';

export default Curator;
