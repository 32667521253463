import { ArticleContent } from "@roc-digital/ui-lib";
import { DropEvent } from "./Slot";
import { View, Zone } from './types';
import { ArticleSchema, ZoneSchema } from "@roc-digital/mxm-base/src/data";

export function mutateZoneDropEvent(view: View, event: DropEvent) {
  let next = {...view};

  const fromZone = next.zones.find(z => z.id === event.from.id);
  const toZone = next.zones.find(z => z.id === event.to.id);

  if(!fromZone || !toZone) {
    return view;
  }

  next.zones.splice(next.zones.indexOf(fromZone), 1);
  next.zones.splice(next.zones.indexOf(toZone), 0, fromZone);

  next.zones.forEach((zone, index) => zone.order = index + 1);
 
  return next;
}

function getEventDetails(view: View, data: any): {
  type: 'zone' | 'bucket' | 'trash',
  article: ArticleSchema,
  zone?: ZoneSchema
} | null {
  const details: {
    type: 'zone' | 'bucket' | 'trash',
    article: ArticleSchema,
    zone?: ZoneSchema
  } = {} as any;

  if(data.area === 'bucket') {
    details.type = 'bucket';
    details.zone = null as any;
    details.article = data.article;

    return details;
  }

  if(data.area === 'trash') {
    details.type = 'trash';
    details.zone = null as any;
    details.article = null as any;

    return details;
  }

  const zone = view.zones.find(z => z.id === data.area);
  
  if(!zone) {
    return null as any;
  }

  const article = zone.articles.find(a => a.id === data.articleId);

  details.type = 'zone';
  details.zone = zone;
  details.article = article as any;

  return details;
}

export function mutateViewForDropEvent(view: View, event: DropEvent, articles: {[id: string]: ArticleSchema}) {
  const from = getEventDetails(view,event.from);
  const to = getEventDetails(view, event.to);

  if(from?.type === 'trash' || to?.type === 'bucket') {
    return view;
  }

  if(from?.type === 'zone' && to?.type === 'zone') {
    // Switch
    from.zone?.articles?.splice(from.zone?.articles.indexOf(from.article), 1, to.article);
    to.zone?.articles?.splice(to.zone?.articles.indexOf(to.article), 1, from.article);
  } else if(from?.type === 'bucket' && to) {
    // Add from bucket or search
    const index = to?.zone?.articles?.indexOf(to.article);
    const toId = to.zone?.articles?.[index || 0]?.id || '';

    if(toId.startsWith('temp_')) {
      to.zone?.articles?.splice(to.zone.articles.indexOf(to.article), 1, from.article);
    } else {
      to.zone?.articles?.splice(to.zone.articles.indexOf(to.article), 0, from.article);
    }

    
  } else if(to?.type === 'trash' && from) {
    // Delete
    from.zone?.articles?.splice(from.zone?.articles.indexOf(from.article), 1, {id: 'temp_' + Math.random().toString()} as any);
  }

  return clampMaxArticles(view);
}

export function extractZoneArticles(zone: Zone, articles: ArticleContent[]) {
  return [
    ...(zone.articles || []).map(article => articles.find(a => a.id === article.id) || {id: 'temp_' + Math.random().toString()} as any),
    {id: 'temp_' + Math.random().toString()} ,{id: 'temp_' + Math.random().toString()}, {id: 'temp_' + Math.random().toString()}, {id: 'temp_' + Math.random().toString()}, {id: 'temp_' + Math.random().toString()}, {id: 'temp_' + Math.random().toString()}
  ].map(a => a || {});
}

export function insertNewZone(view: View, index: number) {
  if (!view.zones) {
    view.zones = [];
  }
  const zone: Zone = {
    id: getUUid(),
    articles: [],
    web_layout: 2,
    title: 'Untitled Zone ' + (view.zones.length + 1),
    source: 'articles'
  };

  const next = {...view};

  if(index === next.zones.length) {
    next.zones.push(zone);
  } else {
    next.zones.splice(index, 0, zone);
  }

  next.zones.forEach((zone, index) => zone.order = index + 1);

  return next;
}

export function getUUid() {
  return btoa((Date.now() + Math.random()).toString());
}


function clampMaxArticles(view: View) {
  view.zones = view.zones.map(zone => {
    if(zone.source === 'tags') return zone;

    const articles = zone.articles || [];
    const maxArticles = getMaxArticlesForLayout(zone.web_layout);
    if(articles.length > maxArticles) {
      zone.articles = articles.filter((a,i) => i < maxArticles)
      return {...zone};
    }

    return zone;
  });

  return {...view};
}

function getMaxArticlesForLayout(id: number) {
  if(id == 0 || id === 1) return 1;
  if(id == 2) return 2;
  if(id == 3) return 3;
  if(id == 4) return 3;
  if(id == 5) return 6;
  if(id == 6) return 6;
  if(id == 7) return 6;
  if(id == 8) return 6;
  if(id == 9) return 9;

  return 12;
}