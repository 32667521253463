import React, { memo } from 'react';
import { ChangeEvent, ClickEvent } from '@roc-digital/ui-lib';
import { User } from '@roc-digital/mxm-base/dist/data';
import { BodyDark, BodyLight, Input, Pressable, Select, Spacer } from '@/components/elements';

type ViewerProps = {
  user?: User;
  clickEvent?: ClickEvent;
  changeEvent?: ChangeEvent;
  editMode?: boolean;
};

const Viewer = memo(({ user: viewer, clickEvent, changeEvent, editMode }: ViewerProps) => {
  return (
    <>
      <div className={'flex flex-row justify-between mt-4 mb-4'}>
        <BodyDark>UserId</BodyDark>
        <BodyLight>{viewer?.id}</BodyLight>
      </div>
      <Spacer />
      <div className={'flex flex-row justify-between mt-4 mb-4'}>
        <BodyDark>LoginType</BodyDark>
        <BodyLight>{viewer?.login_type || '-'}</BodyLight>
      </div>
      <Spacer />
      <div className={'flex flex-row justify-between mt-4 mb-4'}>
        <BodyDark>Status</BodyDark>
        <Pressable clickEvent={clickEvent}>
          <Select
            id={'state'}
            changeEvent={changeEvent}
            value={viewer?.state}
            size="x-small"
            options={[
              { label: 'Verified', value: 'verified' },
              { label: 'Not Verified', value: 'not_verified' },
            ]}
          />
        </Pressable>
      </div>
      <Spacer />
      <div className={'flex flex-row justify-between mt-4 mb-4'}>
        <BodyDark>Email</BodyDark>
        <Pressable clickEvent={clickEvent}>
          {editMode ? (
            <Input value={viewer?.email} changeEvent={changeEvent} id={'email'} />
          ) : (
            <BodyLight>{viewer?.email || '-'}</BodyLight>
          )}
        </Pressable>
      </div>
      <Spacer />
      <div className={'flex flex-row justify-between mt-4 mb-4'}>
        <BodyDark>Phone Number</BodyDark>
        <Pressable clickEvent={clickEvent}>
          {editMode ? (
            <Input value={viewer?.phone} changeEvent={changeEvent} id={'phone'} />
          ) : (
            <BodyLight>{viewer?.phone || '-'}</BodyLight>
          )}
        </Pressable>
      </div>
    </>
  );
});

Viewer.displayName = 'Viewer';

export default Viewer;
