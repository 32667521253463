import { RuiNode } from "@roc-digital/rich-ui";

export function putNodeInClipboard(node: RuiNode) {
  const selection = window.getSelection();
  // Don't perform a copy if there is a current text selection somewhere.
  if(selection && selection.toString().length) return;

  const blob = new Blob([JSON.stringify({type: 'rui-node-copy', node})], {type: 'text/plain'});
  navigator.clipboard.write([
    new ClipboardItem({'text/plain': blob}, {
      presentationStyle: 'attachment'
    })
  ])
  .catch((e) => console.error(e));
} 

export function getNodeFromClipboardEvent(event: ClipboardEvent): RuiNode | null {
  if(!event.clipboardData) return null;
  const data = event.clipboardData?.getData('text/plain');

  if(typeof data !== 'string' || !data.startsWith('{"type":"rui-node-copy"')) return null;

  try {
    const obj = JSON.parse(data);
    return typeof obj?.node?.element === 'string' ? obj.node : null;
  } catch(err) {
    return null;
  }
}
