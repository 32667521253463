import { JsonFetch } from "@roc-digital/mxm-base/logic";
import { Promotional, PromotionalFilters } from '@roc-digital/types';
import { AUX_API } from '@/env';

export async function listPromotionals(filters?: PromotionalFilters){
  return JsonFetch<Promotional[]>({url: `${AUX_API}/admin/promotionals`, query: filters});
}

export async function readPromotional(id: number) {
  return JsonFetch<Promotional>({url: `${AUX_API}/admin/promotionals/${id}`});
}

export function createPromotional(promotional: Promotional) {
  return JsonFetch<Promotional>({method: 'POST',url: `${AUX_API}/admin/promotionals`, data: promotional});
}

export async function updatePromotional(id: number, promotional: Promotional) {
  promotional = {
    ...promotional,
    id,
  };
  return JsonFetch<Promotional>({method: 'PATCH', url: `${AUX_API}/admin/promotionals/${promotional.id}`, data: promotional});
}

export function upsertPromotional(promotional: Promotional) {
  if (promotional.id) {
    return updatePromotional(promotional.id, promotional);
  } else {
    return createPromotional(promotional);
  }
}

export async function makeDefaultPromotional(id: number) {
  return JsonFetch<Promotional>({method: 'POST', url: `${AUX_API}/admin/promotionals/${id}/make-default`});
}

export async function deletePromotional(id: number) {
  return JsonFetch<void>({method: 'DELETE', url: `${AUX_API}/admin/promotionals/${id}`});
}


