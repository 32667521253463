import React, { ReactNode, useCallback } from 'react';
import {
  closeIcon,
  searchIcon,
  useMethod,
} from '@roc-digital/ui-lib';
import { Text, Pressable, List, Icon, Loading } from '@/components/elements';
import { Label } from '@/components/elements/Label';
import { Input } from '@/components/elements/Input';

export interface Item {
  id: string;
  label: string;
  data: any;
}

export interface ItemFinderProps {
  label?: string;
  name?: string;
  placeholder?: string;
  onFind: (query: string) => Promise<Item[]>;
  onItemClick: (item: Item) => void;
  selectorHeader?: any;
  filter?: (items: Item[]) => Item[];
}

export function ItemFinder(props: ItemFinderProps) {

  const query = useMethod(props.onFind);
  const [value, setValue] = React.useState('');
  const [index, setIndex] = React.useState(0);
  const [showResults, setShowResults] = React.useState(false);
  const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue(value);

    const trimValue = value.trim();
    setShowResults(true);

    if(trimValue) {
      query.debounce([trimValue], 350);
    } else {
      query.setResult([]);
      query.close();
      setShowResults(false);
    }
  }, []);

  const clearSearch = React.useCallback(() => {
    setValue('')
    query.setResult([]);
    setShowResults(false);
  }, []);

  const results = React.useMemo(() => {
    if(!query.result) return [];
    if(props.filter) {
      return props.filter(query.result);
    }
    return query.result;
  }, [query.result, props.filter]);

  const renderItem = useCallback(
    (data: { item: Item; index: number; highlighted: number | undefined }) => {
      return (
        <Pressable
          id={data.item.id}
          className={`${data.highlighted === data.index ? 'bg-[#24aae1] text-[white]' : 'hover:bg-[#30b2e724]'} py-2 px-1 hover:opacity-[.8]`}
          clickEvent={() => {
            props.onItemClick(data.item)
            setShowResults(false);
            setValue('');
          }}
        >
          <Text>{data.item.label}</Text>
        </Pressable>
      );
    },
    []
  ) as any;

  const firstResultKey = results.length ? results[0].id : '';
  React.useEffect(() => {
    setIndex(0);
    let index = 0;

    if(!firstResultKey) return;

    const keyup = (event: KeyboardEvent) => {
      const results = query.getLastResult() || [];
      if (event.key === 'ArrowDown' && index < results.length) {
        event.preventDefault();
        index++
        setIndex(index);
      }

      if (event.key === 'ArrowUp' && index > 0) {
        event.preventDefault();
        index--
        setIndex(index);
      }

      if (event.key === 'Enter' && results[index]) {
        event.preventDefault();
        props.onItemClick(results[index]);
        index = 0;
        setIndex(index);
        setShowResults(false);
      }
    };

    document.addEventListener('keyup', keyup as any);

    return () => {
      document.removeEventListener('keyup', keyup as any);
    }

  }, [firstResultKey]);

  return (
    <div className="flex flex-col relative w-full">
       <Label htmlFor={props.name} className={`sr-only`}>
          <Text className="mb-2">{props.label || 'Search value'}</Text>
        </Label>
      <div className="flex flex-row items-center w-full">
        <Input
          name={props.name}
          className="flex-grow pr-10 w-full"
          value={value}
          placeholder={props.placeholder || 'Search value ...'}
          changeEvent={onChange}
          width={'full'}
          {...props}
        />
        {value ? (
          <Icon className="right-0 mr-3 -ml-8 max-w-none" src={closeIcon} clickEvent={clearSearch} />
        ) : (
          <Icon className="right-0 mr-3 -ml-8 max-w-none" src={searchIcon} />
        )}
      </div>
      {showResults ? (
        <SearchResultsContainer absolute={false}>
          {props.selectorHeader}
          {query.loading ? <>
            <Loading circular className="p-10" type='primary' />
          </> : <>
            <List
                className="w-full "
                data={results}
                renderItem={renderItem}
                keyExtractor={(_item, index) => index.toString()}
                emptyMessage={'No Results'}
                highlighted={index}
              />  
          </>}
          
        </SearchResultsContainer>
      ) : <></>}
    </div>
  );
}
export interface SearchResultsContainerProps {
  absolute?: boolean;
  children?: ReactNode;
}

const SearchResultsContainer = ({ absolute, children }: SearchResultsContainerProps) => {
  return (
    <div className={`${absolute ? 'absolute' : ''} top-full left-0 right-0 z-20`}>
      <div
        style={{ maxHeight: '350px', minWidth: '400px' }}
        className="bg-surface rounded-b-md shadow-lg p-1 m-4 mt-0 shadow-black drop-shadow-lg overflow-auto"
      >
        {children}
      </div>
    </div>
  );
};