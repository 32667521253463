import { Heading1 } from "./Typefaces";
import { SpinnerBackdrop } from './SpinnerBackdrop';

export interface PageLayoutProps {
  title: string;
  actions?: any;
  children?: any;
  loading?: boolean;
  sidebar?: any;
}

export function PageLayout(props: PageLayoutProps) {
  return (
    <div className="flex flex-col w-4/5">
      <div className=" flex justify-between h-16 mt-3">
        <Heading1 className="text-2xl text-black">{props.title}</Heading1>
        <div className={'flex flex-row justify-end w-1/6 gap-3'}>
          {props.actions}
        </div>
      </div>
      <div className="flex-grow flex flex-row gap-4">
        <div className="flex-grow bg-white p-4 rounded flex flex-col gap-4 min-w-[0px]">
          {props.children}
        </div>
        {
          props.sidebar ?
            <div className="rounded flex flex-col gap-4 min-w-[325px]">
              {props.sidebar}
            </div>
            : null
        }
      </div>
      <SpinnerBackdrop active={props.loading} />
    </div>
  );
}