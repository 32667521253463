import { Heading2 } from "./Typefaces";

export interface SidebarConfigBoxProps {
  title: string;
  children: any;
}

export function SidebarConfigBox(props: SidebarConfigBoxProps) {
  return <div className="bg-white p-4 rounded flex flex-col gap-4 min-w-[0px]">
    <Heading2 className="text-2xl text-black">{props.title}</Heading2>
    {props.children}
  </div>
}