import { EventCallback, EventOptions, PaginateEvent, PaginationData } from '../types';
import { Data } from '@roc-digital/mxm-base/types';
import { useCustomEvent } from './useCustomEvent';

// The usePaginateEvent hook allows listening for component paginate events.
export function usePaginateEvent(
  fn: EventCallback<PaginationData>,
  opts: EventOptions = {},
  data?: Data,
  deps: any[] = []
): PaginateEvent {
  return useCustomEvent<PaginationData>(
    'paginate',
    fn,
    { ...opts, namespace: opts.namespace || 'mxm.pagination' },
    data,
    deps
  );
}
