import React from 'react';
import { VictoryChart, VictoryLine, VictoryTooltip, VictoryVoronoiContainer } from 'victory';

export type LineChartProps = {
  histogram?: [number | null, number][];
  chartHeight?: number;
};

export function LineChart(props: LineChartProps) {
  
  const data = React.useMemo(() => {
    if(!props.histogram?.length) return [];

    return props.histogram
    .filter(h => h[0])
    .map(h => {
      const s = h[0]?.toString() || '';
      const date = s.slice(0,4) + '-' + s.slice(4,6) + '-' + s.slice(6,8);
      return {x: new Date(date), y: h[1]};
    })
    .slice(-30);

  }, [props.histogram]);
  return (
    <div className={'w-full chart-wrapper'}>
      <VictoryChart
        containerComponent={<VictoryVoronoiContainer />}
        height={props.chartHeight || 200}
        domainPadding={{ x: [1, -1], y: 0 }}
        padding={{left: 50, right: 40, top: 12, bottom: 42}}
      >
          <VictoryLine
            data={data}
            style={{ data: { stroke: '#A6A6A6' } }}
            interpolation="natural"
            scale={{x: 'time'}}
          />
      </VictoryChart>
    </div>
  );
};
