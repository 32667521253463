import { DndProvider } from "react-dnd";
import {
  Tree,
  MultiBackend,
  getBackendOptions,
  NodeModel,
  TreeMethods,
  DropOptions,
} from "@minoru/react-dnd-treeview";
import './TreeView.css';
import React from "react";
import { RuiDocument, RuiNode } from "@roc-digital/rich-ui";
import { RuiEditorApi } from "../RuiApi";
import { Icon } from "@/components/elements";

export interface TreeViewProps {

  onSelectedElement: (element: HTMLElement) => void;
  selectedElement?: HTMLElement;
  onMoveElement: (element: HTMLElement, target: HTMLElement, index: number) => void;
  document: RuiDocument;
  api: RuiEditorApi;
  root?: HTMLElement;
}

export function TreeView(props: TreeViewProps) {
  const treeData = useTreeData(props.document, props.root, props.api);
  const treeRef = React.useRef<TreeMethods | null>(null);

  const handleDrop = (tree, options: DropOptions<any>) => {
    const element = options.dragSource?.data?.element as HTMLElement;
    const target = options.dropTarget?.data?.element as HTMLElement;
    const index = options.destinationIndex || 0;
    if(!element || !target) return 
    props.onMoveElement(element, target, index);
  };


  React.useEffect(() => {
    if(!props.api || !props.selectedElement || !treeRef.current) {
      return;
    }

    const id = props.api.getIdFromDomNode(props.selectedElement);

    if(id) {

      const openIds: string[] = [];
      let openNode = treeData.find(n => n.id === id);
      while(openNode?.id) {
        openIds.push(openNode.id as string);
        openNode = treeData.find(n => n.id === openNode?.parent);
      }

      treeRef.current.open(openIds);
    }

  }, [props.api, props.selectedElement, treeRef.current, treeData]);

  return <div className="ui-block-tree-view">
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <div className={''}>
        <Tree
          ref={m => treeRef.current = m}
          tree={treeData}
          rootId={'0'}
          // initialOpen={openNodeRef}
          render={(node, { depth, isOpen, onToggle }) => (
            <CustomNode
              node={node}
              depth={depth}
              isOpen={isOpen}
              onToggle={onToggle}
              onSelect={() => props.onSelectedElement(node.data.element)}
              selected={props.selectedElement === node.data.element}
            />
          )}
          // dragPreviewRender={(monitorProps) => (
          //   <CustomDragPreview monitorProps={monitorProps} />
          // )}
          onDrop={handleDrop}
          classes={{
            // root: styles.treeRoot,
            draggingSource: 'draggingSource',
            placeholder: 'placeholderContainer'
          }}
          sort={false}
          insertDroppableFirst={false}
          canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
            if (dragSource?.parent === dropTargetId) {
              return true;
            }
          }}
          dropTargetOffset={5}
          placeholderRender={(node, { depth }) => (
            <Placeholder depth={depth} />
          )}
        />
      </div>
    </DndProvider>
  </div>
}

const Placeholder = (props: { depth: number }) => {
  const left = props.depth * 24;
  return <div className="placeholder" style={{ left }}></div>;
};

const CustomNode = (props: {
    depth: number,
    node: NodeModel<any>,
    isOpen: boolean,
    selected: boolean,
    onToggle: (id: string | number) => void,
    onSelect: () => void;

  }) => {
  const indent = props.depth * 24;

  const handleToggle = (e) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  return (
    <div
      className={`node ${props.selected ? 'selected' : ''}`}
      style={{ paddingInlineStart: indent }}
    >
      <div
        className={`expandIconWrapper ${props.isOpen ? 'isOpen' : ""}`}
      >
        {props.node.droppable && (
          <div onClick={handleToggle}>
            <Icon src={caratIcon} size="small"/>
          </div>
        )}
      </div>
      <div>
        {/* <TypeIcon droppable={droppable || false} fileType={data?.fileType} /> */}
        {props.node.data?.icon ? <Icon src={props.node.data?.icon}/> : null}
      </div>
      <div className={'labelGridItem'} onClick={props.onSelect}>
        {`${props.node.text}`}
        {/* <Typography variant="body2">{`${props.node.text}`}</Typography> */}
      </div>
    </div>
  );
}

function useTreeData(doc?: RuiDocument, root?: HTMLElement, api?: RuiEditorApi) {
  return React.useMemo(() => {
    const flatTreeItems: NodeModel<any>[] = [];
    if(!doc || !root || !api) return flatTreeItems;

    const walk = (element: HTMLElement, parentId: string | number) => {
      const id = api.getIdFromDomNode(element);
      const node = api.getRuiNodeFromDomNode(element);
      if(!id || !node) return;
      const def = api.getElementDef(node?.element);
  
      flatTreeItems.push({
        id,
        text: def.getLabel(node, api),
        parent: parentId,
        data: {
          element: element,
          icon: def.getIcon(node, api)
        },
        droppable: element.hasAttribute('insertable')
      })
  
      if(element.hasAttribute('insertable')) {
        for(let child of element.children) {
          walk(child as HTMLElement, id);
        }
      }
    }
    walk(root, '0');
    return flatTreeItems;
  }, [doc, root, api])
}


const caratIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm29.66-93.66a8,8,0,0,1,0,11.32l-40,40a8,8,0,0,1-11.32-11.32L140.69,128,106.34,93.66a8,8,0,0,1,11.32-11.32Z"></path></svg>`