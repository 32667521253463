export interface OnSelectionEvent {
  element: HTMLElement | null;
  clickEvent?: MouseEvent 
}

interface State {
  selectedElement?: HTMLElement;
  prevElement?: HTMLElement;
}

export function initSelection(element: HTMLElement, onSelection: (event: OnSelectionEvent) => void) {
  const state: State = {};

  element.addEventListener('mousedown', (event) => {
    selectElement(event.target as HTMLElement, event, true);
  });

  const selectElement = (element: HTMLElement, clickEvent: MouseEvent | undefined, sendEvent: boolean) => {
    const target = getSelectableTarget(element);
    state.selectedElement = target || undefined;
    drawSelectedElement(state);
    if (sendEvent) {
      onSelection({element: target, clickEvent})
    }
  }

  return {
    setElement: (element: HTMLElement) => selectElement(element, undefined, false)
  }
}

function drawSelectedElement(state: State) {
  if(state.prevElement && state.prevElement !== state.selectedElement) {
    state.prevElement.style.outline = 'unset';
  }

  if(state.selectedElement) {
    state.prevElement = state.selectedElement;
    state.selectedElement.style.outline = '1px dashed black';
    state.selectedElement.style.outlineOffset = '1px'
  }
}

function getSelectableTarget(target: HTMLElement): HTMLElement | null {
  if(!target) return null;
  if(target.hasAttribute('selectable')) return target;
  if(target.parentElement) return getSelectableTarget(target.parentElement as any);
  return null;
}

