import { inputProps, toggleProps, useObjectSetter } from '@/hooks/useObjectSetter'
import { FormGroup, FormRow } from './FormGroups';
import { Popup } from '@roc-digital/types';
import { Input } from './Input';
import { Toggle } from './Toggle';
import { Option, Select } from './Select';

export interface PopupFormProps {
  value: Popup;
  onChange: (next: Popup) => void;
  preview?: any;
  popupTypes: Option[]
}

export function PopupForm(props: PopupFormProps) {
  const popup = useObjectSetter(props.value, props.onChange);

  return <>
      <FormRow>
      <FormGroup title="Name*">
        <Input {...inputProps(popup, 'name')} />
      </FormGroup>
      <FormGroup title="Type*">
        <Select options={props.popupTypes} {...inputProps(popup, 'type')} />
      </FormGroup>
      <FormGroup title="Status*" grow={0}>
        <Toggle label="Active" {...toggleProps(popup, 'active')}/>
      </FormGroup>
    </FormRow>

    <div className="flex flex-row flex-grow">
      <div className="flex flex-grow min-w-[0px] flex-grow">
        {props.preview}
        {/* <div style={{overflow: 'hidden', minWidth: '0px', minHeight: '0px', backgroundColor: '#818181', height: '474px' }}>
          {props.preview}
        </div> */}
      </div>
    </div>
  </>
}