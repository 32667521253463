import { ClickEvent, closeIcon } from '@roc-digital/ui-lib';
import { Pressable } from '@roc-digital/ui-web';
import { Icon, Text } from '@/components/elements';

type TagProps = {
  id?: string;
  deleteEvent?: ClickEvent;
  className?: string;
  children: string;
  title?: string;
};

export function Tag(props: TagProps) {
  return (
    <div
      key={props.id}
      title={props.title}
      className={`bg-surface-high rounded-full px-2 border-2 flex flex-row flex-shrink items-center gap-2 ${props.className}`}
    >
      <Text size="small" className="text-font-low whitespace-nowrap">
        {props.children}
      </Text>
      {props.deleteEvent ? <>
        <Pressable id={props.id} clickEvent={props.deleteEvent} className={'flex flex-row item-center'}>
          <Icon src={closeIcon} className='min-w-[16px] min-h-[16px]' />
        </Pressable>
      </> : <></>}
    </div>
  );
}
