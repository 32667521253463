import React from 'react';
import { BodyLight, Button, ButtonSizes, ButtonVariants, Heading1, ViewEditor, Heading2 } from '@/components/elements';
import { putView, readView, deleteView } from '@roc-digital/mxm-base/logic';
import { ViewSchema } from '@roc-digital/mxm-base/src/data';
import { useMethod } from '@roc-digital/ui-lib';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loading } from '@roc-digital/ui-web';

export function EditViewNew() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const viewQuery = useMethod(readView, {
    autoRun: [id || ''],
    onBefore: async (args) => {
      if(!args[0]) return;

      return args;
    }
  });
  const [view, setView] = React.useState<ViewSchema | null>(null);
  const updateQuery = useMethod(putView, {
    onError: (err) => {
      toast.success('Failed to save the view');
      console.error(err)
    },
    onSuccess: (result, [request]) => {
      if(!request.id && result?.id) {
        navigate(`/views/${result.id}`)
      } else {
        viewQuery.reload();
      }
      toast.success('View saved!');
    }
  });

  const deleteViewQuery = useMethod(deleteView, {
    onBefore: async (args: any) => {
      if(!confirm(`Are you sure you want to delete this view?`)) {
        return;
      }
      return args;
    },
    onSuccess: () => {
      toast.success('View deleted.');
      navigate('/views')
    },
    onError: (error) => {
      console.error(error);
      toast.error('Failed to delete view!')
    }
  });

  const save = () => {
    if(view) {
      updateQuery.run(view).catch()
    }
  }

  return <>
  
  <div className="flex flex-col flex-grow w-4/5">
      <div className="flex justify-between h-20 mr-6 mt-3">
        <Heading1 className={'text-2xl text-black'}>{`Edit View`}</Heading1>
        <div className={'flex flex-row justify-end w-1/6 gap-3'}>
          <Button
              size={ButtonSizes.medium}
              clickEvent={() => navigate(`/views_preview/${id}`)}
              variant={ButtonVariants.secondary}
              disabled={false}
            >
            <BodyLight className={'font-light'}>Preview</BodyLight>
          </Button>
          <Button
            size={ButtonSizes.medium}
            clickEvent={() => navigate('/views')}
            variant={ButtonVariants.secondary}
            disabled={false}
          >
            <BodyLight className={'font-light'}>Cancel</BodyLight>
          </Button>
          <Button size={ButtonSizes.medium} clickEvent={save} disabled={!view || updateQuery.loading}>
            <BodyLight className={`${'text-white'} font-light`}>Save</BodyLight>
          </Button>
        </div>
      </div>
      <div className="flex-grow flex flex-col mr-6 rounded gap-2 relative" style={{height: 'calc(100vh - 62px)'}}>

        <ViewEditor
          view={(view || viewQuery.result || {}) as any}
          onChange={setView}
          zoneViewClassnames='rounded bg-white p-10'
          articleSearchClassnames='rounded bg-white p-10'
        />

        {viewQuery.loading || updateQuery.loading ? <>
          <div className='absolute flex flex-row justify-center items-center' style={{top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(245,245,245,.7)'}}>
            <Loading/>
          </div>
        </> : <></>}

      </div>

      {id && id !== 'new' && !viewQuery.loading ? <>
        <div className={'flex flex-col justify-between p-4 gap-4'}>
        <Heading2 className={'!text-2xl text-admin-dark'}>Danger Zone</Heading2>
        <div className="flex-row">
          <Button
            size={ButtonSizes.medium}
            clickEvent={() => deleteViewQuery.run(id, { isAdmin: true })}
            variant={ButtonVariants.destructive}
            disabled={deleteViewQuery.loading as boolean}
          >
            Delete View
          </Button>
        </div>
      </div>      
      </> : <></>}
    </div>

  </>;
}

