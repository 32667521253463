import React, { memo, useEffect, useState } from 'react';
import {
  accountIcon,
  curatorsIcon,
  InputData,
  useChangeEvent,
  useCustomEvent,
  useClickEvent,
  useStateEvent,
  useMethod,
} from '@roc-digital/ui-lib';
import { useNavigate, useParams } from 'react-router-dom';
import { readAdminUser, updateAdminUser, deleteAdminUser } from '@roc-digital/mxm-base/logic';
import { Event } from '@roc-digital/mxm-base/events';
import { User } from '@roc-digital/mxm-base/data';
import {
  Avatar,
  BodyDark,
  BodyLight,
  Button,
  ButtonSizes,
  ButtonVariants,
  Card,
  Heading1,
  Heading2,
  Icon,
  Input,
  Pressable,
  Select,
  Spacer,
} from '@/components/elements';
import Viewer from '@/components/views/Users/Viewer';
import Curator from '@/components/views/Users/Curator';
import Upvoted from '@/components/views/Users/Upvoted';
import { toast } from 'react-toastify';
import { publish } from '@roc-digital/mxm-base/events';

const Edit = memo(() => {
  const { id } = useParams<{ id: string }>();
  const [readingUser, setLoading] = useStateEvent<boolean>('mxm.http.adminUsers', 'readAdminUser.loading', false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const navigate = useNavigate();

  const deleteUserQuery = useMethod(deleteAdminUser, {
    onBefore: async (args) => {
      if(!confirm(`Are you sure you want to delete this user?`)) {
        return;
      }
      return args;
    },
    onSuccess: () => {
      toast.success('User deleted.');
      navigate('/users')
    },
    onError: (error) => {
      console.error(error);
      toast.error('Failed to delete user!')
    }
  });

  const loading = readingUser || deleteUserQuery.loading;

  const [user, setUser] = useStateEvent<User>(
    'mxm.http.adminUsers',
    'readAdminUser.success',
    new User({
      id: '',
      bio: 'this is the bio1',
      email: '',
      state: 'state',
      username: '',
      phone: '644129463',
      login_type: 'google',
      status: 'not_verified',
      role: 'curator',
    }), 
  );

  

  const handleFieldClick = useClickEvent(() => {
    setEditMode(true);
  });

  const changeEvent = useChangeEvent(
    ({ data }: Event<InputData>) => {
      setUser(new User({ ...user, [data.id as string]: data.value as string }));
    },
    {},
    {},
    [user]
  );

  useEffect(() => {
    void readAdminUser(id as string).then((user) => {
      if (user.role === 'curator') {
        setIsViewer(false);
      }
    });
  }, [id]);

  const saveUserTypeChange = (role: string) => {
    publish<InputData>(changeEvent?.namespace || '', 'change', {
      id: 'role',
      value: role,
      eventData: null as any
    });
    console.log(user);
  };

  const handleCancelClick = useClickEvent(() => {
    setEditMode(false);
  });

  const handleBackClick = useClickEvent(() => {
    navigate('/users');
  });

  const [isViewer, setIsViewer] = useState<boolean>(true);

  const handleCuratorClick = useClickEvent(() => {
    saveUserTypeChange('curator');
    setIsViewer(false);
    
  });

  const handleViewerClick = useClickEvent(() => {
    saveUserTypeChange('user');
    setIsViewer(true);
  });

  useCustomEvent(
    'updateAdminUser.failed',
    () => {
      setLoading(false);
      toast.error('Failed to modify User');
    },
    { namespace: 'mxm.http.adminUsers' }
  );

  useCustomEvent(
    'updateAdminUser.success',
    () => {
      toast.success('User updated successfully');
      setLoading(false);
    },
    { namespace: 'mxm.http.adminUsers' }
  );

  const saveEvent = useClickEvent(
    () => {
      if (user) {
        console.log(user.toParams());
        void updateAdminUser(id as string, user.toParams());
        setLoading(true);
        setEditMode(false);
      }
    },
    { namespace: 'mxm.adminUser.save' },
    {},
    [user]
  );

  const formatDate = (date: any) => {
    if (date) {
      return new Date(date * 1000 as number).toLocaleDateString('en-US');
    } else {
      return 'Loading...'
    }
  }

  return (
    <div className="flex flex-col w-5/6">
      <div className=" flex justify-between h-24 mt-3 mr-10">
        <Heading1 className="text-2xl text-black">User Details</Heading1>
        <div className={'flex flex-row justify-end w-1/6 gap-3'}>
          <Button
            size={ButtonSizes.medium}
            clickEvent={handleBackClick}
            variant={ButtonVariants.secondary}
            disabled={loading as boolean}
          >
            <BodyLight className={'font-light'}>Back</BodyLight>
          </Button>
          <Button
            size={ButtonSizes.medium}
            clickEvent={handleCancelClick}
            variant={editMode ? ButtonVariants.secondary : ButtonVariants.disabled}
            disabled={loading as boolean}
          >
            <BodyLight className={'font-light'}>Cancel</BodyLight>
          </Button>
          <Button
            size={ButtonSizes.medium}
            clickEvent={saveEvent}
            variant={editMode ? ButtonVariants.primary : ButtonVariants.disabled}
            disabled={!editMode || (loading as boolean)}
          >
            <BodyLight className={`${editMode && 'text-white'} font-light`}>Save</BodyLight>
          </Button>
        </div>
      </div>
      <div className="flex flex-row w-full">
        <div className="flex-grow bg-white px-6 py-2 mr-2 w-2/3">
          <div className="flex flex-row my-4 justify-center">
            <Pressable
              clickEvent={handleViewerClick}
              className={`flex flex-col justify-center items-center border h-16 rounded w-1/2 mr-2 ${
                isViewer ? 'border-cyan-400 shadow-md bg-gray-100' : 'border-gray-300'
              }`}
            >
              <Icon src={accountIcon} size={'medium'} fill={'none'} stroke={'#919193'} />
              <BodyLight className="text-sm">Viewer Settings</BodyLight>
            </Pressable>
            <Pressable
              clickEvent={handleCuratorClick}
              className={`flex flex-col justify-center items-center border h-16 rounded w-1/2 ml-2 ${
                !isViewer ? 'border-cyan-400 shadow-md bg-gray-100' : 'border-gray-300'
              }`}
            >
              <Icon src={curatorsIcon} size={'medium'} stroke={'#919193'} />
              <BodyLight className="text-sm">Curator Settings</BodyLight>
            </Pressable>
          </div>
          <div className="mt-8 flex flex-col">
            <Heading2 className={'mb-6 text-admin-dark !text-2xl'}>General</Heading2>
            <div className={'flex flex-row items-center mb-4'}>
              <Avatar size={'large'} image={user?.avatar_url || 'https://i.imgur.com/tdi3NGa_d.webp'} />
              <div className={'flex flex-col ml-2 justify-start'}>
                <BodyDark className={'font-bold'} size={'title'}>
                  {user?.name || '-'}
                </BodyDark>
                <Pressable clickEvent={handleFieldClick}>
                  {editMode ? (
                    <Input value={user?.username} changeEvent={changeEvent} id={'username'} />
                  ) : (
                    <BodyLight className={'text-sm'}>@{user?.username}</BodyLight>
                  )}
                </Pressable>
              </div>
            </div>
            <Spacer />
            {isViewer ? (
              <Viewer
                user={(user as User) || undefined}
                clickEvent={handleFieldClick}
                changeEvent={changeEvent}
                editMode={editMode}
              />
            ) : (
              <Curator
                user={user as User}
                clickEvent={handleFieldClick}
                changeEvent={changeEvent}
                editMode={editMode}
              />
            )}
            {editMode &&
              <div>
                <div>User Role Setting</div>
                <Select
                  id={'role'}
                  changeEvent={changeEvent}
                  value={user?.role}
                  size="x-small"
                  options={[
                    { label: 'User', value: 'user' },
                    { label: 'Curator', value: 'curator' },
                    { label: 'Admin', value: 'admin' },
                  ]}
                />
              </div>
            }
            
          </div>
        </div>
        
        <div className={'flex flex-col mr-10 w-1/3'}>
          <Card className={'!min-h-[580px] mb-32'}>
            <Heading2 className={'!text-2xl text-admin-dark'}>Usage Data</Heading2>
            <div className={'flex flex-row mt-10 mb-5 w-full'}>
              <div className={'flex flex-col w-1/2'}>
                <Heading1 className={'text-black'}>{user?.stats?.votes}</Heading1>
                <BodyLight className={'text-xs'}>Article Votes</BodyLight>
              </div>
              <div className={'flex flex-col w-1/2'}>
                <Heading1 className={'text-black'}>{user?.stats?.bookmarks}</Heading1>
                <BodyLight className={'text-xs'}>Articles Saved</BodyLight>
              </div>
            </div>
            <Spacer />
            <div className={'flex flex-row my-8 w-full'}>
              <div className={'flex flex-col w-1/2'}>
                <Heading2 className={'text-black'}><span>{formatDate(user?.created)}</span></Heading2>
                <BodyLight className={'text-xs'}>Signup Date</BodyLight>
              </div>
            </div>
            <Spacer />
            <div className={'flex flex-row my-4 w-full justify-between'}>
              <BodyDark>
                <b>Am Notifications</b>
              </BodyDark>
              <BodyDark>{user?.notification_am_pm ? 'Yes' : 'No'}</BodyDark>
            </div>
            <Spacer />
            <div className={'flex flex-row my-4 w-full justify-between'}>
              <BodyDark>
                <b>Breaking Notifications</b>
              </BodyDark>
              <BodyDark>{user?.notification_breaking ? 'Yes' : 'No'}</BodyDark>
            </div>
            <Spacer />
            <div className={'flex flex-row my-4 w-full justify-between'}>
              <BodyDark>
                <b>State</b>
              </BodyDark>
              <BodyDark>{user?.state ? user?.state : 'Not saved'}</BodyDark>
            </div>
            <Spacer />
            <div className={'flex flex-row my-4 w-full justify-between'}>
              <BodyDark>
                <b>City</b>
              </BodyDark>
              <BodyDark>{user?.city ? user?.city : 'Not saved'}</BodyDark>
            </div>
            <Spacer />
            <div className={'flex flex-row my-4 w-full justify-between'}>
              <BodyDark>
                <b>Zip Code</b>
              </BodyDark>
              <BodyDark>{user?.zipcode ? user?.zipcode : 'Not saved'}</BodyDark>
            </div>
          </Card>
        </div>
      </div>
      {/*<Upvoted />*/}

      {id ? <>
        <div className={'flex flex-col justify-between p-4 gap-4'}>
        <Heading2 className={'!text-2xl text-admin-dark'}>Danger Zone</Heading2>
        <div className="flex-row">
          <Button
            size={ButtonSizes.medium}
            clickEvent={() => deleteUserQuery.run(id)}
            variant={ButtonVariants.destructive}
            disabled={loading as boolean}
          >
            Delete User
          </Button>
        </div>
      </div>      
      </> : <></>}

    </div>
  );
});

Edit.displayName = 'Edit';

export default Edit;
