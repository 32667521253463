import { useNavigate } from 'react-router';
import { BodyLight, Button, ButtonSizes } from '@/components/elements';
import { ContentBlock, duplicateBlock } from '@/logic/blocks';
import { BlocksList } from '@/components/elements/UiBlocks';
import { PageLayout } from '@/components/elements/PageLayout';
import { notifyError } from '@roc-digital/mxm-base/logic';

export function Blocks() {
  const nav = useNavigate();

  const createNew = () => {
    nav('/blocks/new')
  }

  const edit = (block: ContentBlock) => {
    nav('/blocks/' + block.id);
  }

  const preview = (block: ContentBlock) => {
    nav('/blocks/' + block.id + '/preview');
  }

  const duplicate = (block: ContentBlock) => {

    duplicateBlock(block.id)
    .then(block => nav('/blocks/'+block.id))
    .catch(() => {
      notifyError('Failed to duplicate block.')
    })
  }

  return <PageLayout
    title='UI Blocks'
    actions={<>
      <Button size={ButtonSizes.medium} clickEvent={createNew}>
        <BodyLight className={'text-white'}>Add Block</BodyLight>
      </Button>
    </>}
  >
    <BlocksList
      onEdit={edit}
      onPreview={preview}
      onDuplicate={duplicate}
      enableDelete
    />
  </PageLayout>
};

