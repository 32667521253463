import {
  Badge,
  BodyDark,
  BodyLight,
  InputSearch,
  Pressable,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableSection,
  Toggle,
  Loading,
} from '@/components/elements';
import { toggleProps, useObjectSetter } from '@/hooks/useObjectSetter';
import { Popup } from '@roc-digital/types';
import { format } from 'date-fns';
import React from 'react';

export interface PopupFilters {
  query: string;
  showOnlyActive: boolean;
}

export const POPUP_FILTERS_DEFAULTS: PopupFilters = {query: '', showOnlyActive: false};

export interface PopupListProps {
  popups: Popup[];
  filters: PopupFilters;
  onFiltersChange: (filters: PopupFilters) => void;
  onClick: (popup: Popup) => void;
  loading?: boolean;
}

export function PopupList(props: PopupListProps) {
  const filters = useObjectSetter(props.filters, props.onFiltersChange);
  const ref = React.useRef(filters);

  return <>
    <div className="flex flex-row mb-4 justify-between items-center">
      <div className="w-1/2 pr-8">
        <InputSearch
          showLabel={false}
          placeholder={'Search Popups'}
          value={filters.query}
          changeValueEvent={v => {
            if(v !== ref.current.query) {
              ref.current.query = v;
              ref.current.set('query', v)
            }
          }}
          hideResults
        />
      </div>
      <div>
        <Toggle
          isLabelLeft
          label={'Show active Only'}
          {...toggleProps(filters, 'showOnlyActive')}
        />
      </div>
    </div>
    <Table className={'table-fixed'}>
      <TableSection type="head">
        <TableRow>
          <TableHeader className={'w-1/12'}> </TableHeader>
          <TableHeader className={'w-4/12 pl-0'}>Name</TableHeader>
          <TableHeader className={'w-3/12 pl-0'}>Type</TableHeader>
          <TableHeader className={'w-2/12 pl-0'}>Created/Modified</TableHeader>
          <TableHeader className={'w-2/12 pl-0'}>Status</TableHeader>
        </TableRow>
      </TableSection>
      <TableSection>
        {props.popups.map((popup) => (
          <TableRow key={popup.id} onClick={() => props.onClick(popup)}>
            <TableCell className={'text-center'}>
              <BodyDark>{popup.id}</BodyDark>
            </TableCell>
            <TableCell className={'pl-0 break-words'}>
              <Pressable clickEvent={() => { }}>
                <BodyDark>{popup.name}</BodyDark>
              </Pressable>
            </TableCell>
            <TableCell className={'pl-0 break-words'}>
              <BodyDark>{popup.type}</BodyDark>
            </TableCell>
            <TableCell className={'pl-0 flex flex-col'}>
              <BodyDark>{format(new Date((popup.created as number) * 1000), 'MM/dd/yyyy')}</BodyDark>
              <BodyLight>
                {'Modified ' + format(new Date((popup.modified as number) * 1000), 'MM/dd/yyyy')}
              </BodyLight>
            </TableCell>
            <TableCell className={'pl-0'}>
              {popup.active ? (
                <Badge type={'subdued'}>
                  <BodyDark className={'capitalize'}>Active</BodyDark>
                </Badge>
              ) : 'Inactive'}
            </TableCell>
          </TableRow>
        ))}
        {!props.loading && props.popups.length === 0 ? (
          <TableRow>
            <TableCell className={'text-center'} colSpan={6}>
              <div className={'p-2'}>No records found.</div>
            </TableCell>
          </TableRow>
        ) : null}
        {props.loading ? (
          <TableRow>
            <TableCell className={'text-center'} colSpan={6}>
              <Loading size={'medium'} />
            </TableCell>
          </TableRow>
        ) : null}
      </TableSection>
    </Table>
  </>
}