export interface GroupingProps {
  label?: string;
  row?: boolean;
  gap?: number;
  className?: string;
  children?: any;
  fill?: boolean
}

export function Grouping(props: GroupingProps) {
  return  <div 
    className={`flex flex-col ${props.fill ? 'flex-grow ' : ''}` + props.className}
    style={{flex: props.fill ? 1 : undefined}}
  >
    <span className='font-bold text-[8px] text-[gray] min-h-[12px]'>{props.label}</span>

    <div className='flex'
      style={{
        flexDirection: props.row ? 'row' : 'column',
        gap: props.gap ? `${props.gap}px` : '4px',
      }}>
        {props.children}
    </div>
  </div>
}