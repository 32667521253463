import { RuiDocument, RuiNode } from "@roc-digital/rich-ui";
import { BlockElementDef, RuiApi } from "../elements";

interface Api extends RuiApi {
  getRuiNodeFromDomNode(node: HTMLElement | undefined): RuiNode | undefined;
  getElementDef(element: string): BlockElementDef;
}

export function dom2Document(root: HTMLElement, api: Api): RuiDocument {

  const doc: RuiDocument = {
    children: []
  }

  const serialize: (domNode: HTMLElement | Element) => RuiNode | null = (domNode) => {
    const node = api.getRuiNodeFromDomNode(domNode as HTMLElement);
    if(!node) return null;

    // console.log(domNode);
    const element = api.getElementDef(node?.element);

    return element.serialize(node, serialize, api);
  }

  const node = serialize(root)
  
  if (node) {
    doc.children.push(node)
  }

  return doc;
}

