import { publish } from '@roc-digital/mxm-base/events';

import { ChangeEvent, CustomEvent, InputData, getClassNames } from '@roc-digital/ui-lib';
import { BodyDark } from './Typefaces';

type Props = {
  id?: string;
  className?: string;
  value?: string;
  placeholder?: string;
  label?: React.ReactNode;
  width?: string;
  changeEvent?: ChangeEvent | CustomEvent | ((event: React.ChangeEvent<HTMLTextAreaElement>) => void);
};

const styleClasses = {
  base: `border-edge bg-white mx-0 my-1 px-3 py-2 border-2 border-stone-200 border-solid box-border rounded text-font transition-all duration-200 ease-linear focus:border-font-info-high focus:outline-none`,
  width: {
    full: `w-full`,
    half: `w-1/2`,
    third: `w-1/3`,
    quarter: `w-1/4`,
  },
};

export const TextArea = ({ id, className, value, placeholder, label, width, changeEvent }: Props) => {
  const publishChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!changeEvent) {
      return;
    }

    if(typeof changeEvent === 'function') {
      changeEvent(evt);
      return;
    }

    if (changeEvent.allowDefault === false) {
      evt.preventDefault();
    }

    if (changeEvent.propagate === false) {
      evt.stopPropagation();
    }

    publish<InputData>(changeEvent.namespace, 'change', {
      id,
      eventData: changeEvent.data || {},
      value: evt.target.value,
    });
  };

  className = `${getClassNames(styleClasses, { width })} ${className}`;

  return (
    <div className={'flex flex-col'}>
      {label ? <BodyDark>{label}</BodyDark> : null}
      <textarea className={className} value={value} placeholder={placeholder} onChange={publishChange} />
    </div>
  );
};
