import React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
  colSpan?: number;
};

const tdClasses = 'px-4 py-2';

export const TableCell = ({ children, className, colSpan }: Props) => {
  className = className ? `${tdClasses} ${className}` : tdClasses;

  return (
    <td className={className} colSpan={colSpan}>
      {children}
    </td>
  );
};
