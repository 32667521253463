type Props = {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
};

export const TableRow = (props: Props) => {
  return <tr
    onClick={props.onClick}
    className={'border-b border-stone-200 text-left text-font text-xs h-2 hover:bg-slate-50'}>{props.children}</tr>;
};
