import { Icon, Button, ButtonSizes, BodyLight, ButtonVariants } from '@/components/elements';
import { mxmIcon } from '@roc-digital/ui-lib';

export interface HeaderProps {
  title: string;
  onTitleChange: (title: string) => void;
  onSave: () => void;
  onPublish: () => void;
  onBack?: () => void;
  modified: boolean;
  loading: boolean;
}

export function Header(props: HeaderProps) {
  const loading = props.loading;
  const isModified = props.modified;
  
  return  <div className='h-[48px] flex flex-row gap-[8px]' style={{alignItems: 'center', padding: '0px 8px'}}>
    <Icon src={mxmIcon} size='large' clickEvent={props.onBack}/>
    <div
      onClick={(e: any) => {e.target.contentEditable = true}}
      style={{outline: 'none'}}
      onBlur={(e) => props.onTitleChange(e.target['innerText'] || '')}
    >{props.title}</div>

    <span className='flex-grow'></span>

    <Button
      size={ButtonSizes.medium}
      clickEvent={props.onPublish}
      variant={ButtonVariants.secondary}
      disabled={loading as boolean}
    >
      <BodyLight className={'font-light'}>Publish</BodyLight>
    </Button>

    <Button
      size={ButtonSizes.medium}
      clickEvent={props.onBack}
      variant={ButtonVariants.secondary}
      disabled={loading as boolean}
    >
      <BodyLight className={'font-light'}>Cancel</BodyLight>
    </Button>

    <Button
      size={ButtonSizes.medium}
      clickEvent={props.onSave}
      disabled={loading || !isModified}
      variant={isModified || loading ? ButtonVariants.primary : ButtonVariants.disabled}
    >
      <BodyLight className={`${isModified && 'text-white'} font-light`}>Save</BodyLight>
    </Button>
  </div>
}