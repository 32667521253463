export interface OnDropEvent {
  element: HTMLElement;
  target: HTMLDivElement;
  index: number;
}

export function initDnd(
  element: HTMLDivElement,
  onDrop: (event: OnDropEvent) => void
) {
  const state: State = {
    dividers: [],
    childrenRects: [],
  }

  element.addEventListener('dragover', (e) => {
    const target = findTarget(e.target as any)
    if (target) {
      drawLines(target as any, state, e.x, e.y)
    }
  });

  element.addEventListener('dragstart', (event) => {
    state.selectedElement = event.target as any;
  });

  element.addEventListener('dragend', () => {
    if (state.target && state.selectedElement && state.selectedDividerIndex !== undefined) {
      onDrop({
        element: state.selectedElement,
        target: state.target,
        index: state.selectedDividerIndex,
      })
    }
    state.dividers.forEach(d => d.remove());
    state.dividers = [];
    state.target = undefined;
    state.selectedElement = undefined;
  })
}

interface State {
  dividers: HTMLDivElement[],
  target?: HTMLDivElement,
  targetRect?: DOMRect;
  childrenRects: DOMRect[];
  selectedDividerIndex?: number;
  selectedElement?: HTMLElement;
}

function drawLines(target: HTMLDivElement, state: State, mouseX: number, mouseY: number) {

  const SCALE_FACTOR = .2;
  const layout = getPropLayout(target);
  const mx = target.style.flexDirection === 'column' ? mouseY : mouseX;

  if (target && target !== state.target) {
    state.targetRect = target.getBoundingClientRect();
    state.childrenRects = Array.from(target.children).map(c => c.getBoundingClientRect());
    state.target = target;
  }

  if (!state.target || !state.targetRect) return;

  const edges: number[] = [];

  for (let i = 0; i <= state.childrenRects.length; i++) {
    const rect = state.childrenRects[i];
    let divider = state.dividers[i];
    if (!divider) {
      divider = document.createElement('div');
      state.dividers.push(divider);
      divider.style.position = 'fixed';
      divider.style.zIndex = '1000';
      divider.style.backgroundColor = 'red';
      document.body.appendChild(divider);
    }

    const adj = state.childrenRects[i - 1] ? state.childrenRects[i - 1] : state.targetRect;
    divider.style[layout.top] = (rect ? rect[layout.top] : adj[layout.top]) + 'px';
    divider.style[layout.height] = (rect ? rect[layout.height] : adj[layout.height]) + 'px';
    divider.style[layout.width] = '1px';

    const leftEdge = i === 0 ? state.targetRect[layout.left] : state.childrenRects[i - 1][layout.right];

    if (!rect) {
      const rightEdge = state.targetRect[layout.right];
      const offset = (rightEdge - leftEdge) / 2;
      const x = leftEdge + offset;
      divider.style[layout.left] = x + 'px';
      edges.push(x);
      continue;
    };

    const offset = (rect[layout.left] - leftEdge) / 2;
    const x = (leftEdge + offset);
    edges.push(x);
    divider.style[layout.left] = x + 'px';
  }

  // Cleanup extra dividers
  if (state.childrenRects.length < state.dividers.length) {
    state.dividers
      .splice(state.childrenRects.length + 1, state.dividers.length - state.childrenRects.length + 1)
      .forEach(d => d.remove());
  }

  // Use mx to find the edge that is nearest the mouse
  // apply a scale factor to the dividers to visually indicate nearby drop zones.
  for (let i = 0; i < edges.length; i++) {
    const leftEdge = edges[i - 1];
    const rightEdge = edges[i];
    const nextEdge = edges[i + 1];

    if (leftEdge === undefined && mx <= rightEdge) {
      // mouse is far left. Far left line is selected.
      state.dividers[i].style.transform = `scale(${1 + SCALE_FACTOR})`;
      state.selectedDividerIndex = i;
    } else if (nextEdge === undefined && mx >= rightEdge) {
      // mouse is far right; far right ine is selected
      state.dividers[i].style.transform = `scale(${1 + SCALE_FACTOR})`;
      state.selectedDividerIndex = i;
    } else if (mx >= leftEdge && mx <= rightEdge) {
      // mouse is between the left and right edge.

      const distance = rightEdge - leftEdge; // Total distance between the left edge and the right edge
      const mouseDistance = mx - leftEdge; // the mouse position in distance
      const mouseRightP = mouseDistance * 100 / distance; // How near right the mouse is as a percentage value.
      const mouseLeftP = 100 - mouseRightP; // How near left the mouse is as a percentage value;

      state.dividers[i - 1].style.transform = `scale(${scale(mouseLeftP, SCALE_FACTOR) + 1})`;
      state.dividers[i].style.transform = `scale(${scale(mouseRightP, SCALE_FACTOR) + 1})`;

      if (mouseRightP >= mouseLeftP) {
        // select the far right edge;
        state.selectedDividerIndex = i;
      } else {
        // select the far left edge;
        state.selectedDividerIndex = i - 1;
      }

    } else {
      // In all other cases... reset the edge.
      state.dividers[i].style.transform = `scale(1)`;
    }
  }

  // color the selected divider to indicator the current "drop zone".
  for (let i = 0; i < state.dividers.length; i++) {
    if (i === state.selectedDividerIndex && state.selectedDividerIndex) {
      state.dividers[i].style.backgroundColor = 'green';
    } else {
      state.dividers[i].style.backgroundColor = 'red';
    }
  }

}

function scale(p: number, maxV: number) {
  return maxV * p / 100;
}


interface PropLayout {
  width: 'width' | 'height';
  height: 'height' | 'width';
  top: 'top' | 'left';
  left: 'left' | 'top';
  right: 'right' | 'bottom';
}

function getPropLayout(target: HTMLDivElement): PropLayout {
  if (target.style.flexDirection === 'column') {
    return {
      width: 'height',
      height: 'width',
      left: 'top',
      right: 'bottom',
      top: 'left'
    }
  }

  return {
    width: 'width',
    height: 'height',
    left: 'left',
    right: 'right',
    top: 'top'
  }
}

function findTarget(target: HTMLDivElement) {
  if(target.hasAttribute('draggable') && target.style.display === 'flex') return target;
  if(target.parentElement) findTarget(target.parentElement as any);

  return null;
}