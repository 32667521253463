import { Heading1 } from "@/components/elements";
import { ClearCache } from "./ClearCache";
import { Interests } from "./Interests";

export function SettingsView() {
  return (
    <div className="flex flex-col w-4/5">
      <div className=" flex justify-between h-16 mr-6 mt-3">
        <Heading1 className="text-2xl text-black">Settings</Heading1>
      </div>
      <div className="flex-grow bg-white p-10 mr-6 rounded flex flex-col gap-4">
        <hr/>
        <ClearCache/>
        <hr/>
        <Interests/>
        <hr/>
      </div>
    </div>
  );
}