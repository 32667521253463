import { InputProps } from "@/components/elements/Input";
import { ToggleProps } from "@/components/elements/Toggle";

export function useObjectSetter<T>(value: T, onChange: (next: T) => void, defaultValue?: T) {
  function setValue<K extends keyof T>(key: K, next: T[K]) {
    let current = value || defaultValue || {} as T;
    onChange({
      ...current,
      [key]:next,
    } as T);
  }

  return {
    ...value,
    set: setValue
  };
}

export function inputProps<T>(obj: ReturnType<typeof useObjectSetter<T>>, key: keyof T): Partial<any> {
  return {
    value: obj[key],
    onChange: (e: any) => {
      let value: any = e?.target?.value || e;
      if(value !== undefined) {
        obj.set(key, value as any)
      }
    },
    changeEvent: (e: any) => {
      let value: any = e?.target?.value;
      if(value !== undefined) {
        obj.set(key, e.target.value as any)
      }
    }
  } as any
}



export function toggleProps<T>(obj: ReturnType<typeof useObjectSetter<T>>, key: keyof T): Partial<ToggleProps> {
  return {
    value: obj[key] ? true : false,
    onToggle: (v) => {
      obj.set(key, v ? true : false as any)
    }
  }
}
