import { useMethod } from '@roc-digital/ui-lib';
import {
  BodyDark,
  Button,
  InputSearch,
  Loading,
  Pressable,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableSection,
} from '@/components/elements';
import { Badge, BadgeSizes } from '@roc-digital/ui-web';
import { listBlocks, ContentBlock, deleteBlock } from '@/logic/blocks';
import React from 'react';
import { notifyError } from '@roc-digital/mxm-base/logic';

export interface BlocksListProps {
  classType?: string;
  onEdit?: (block: ContentBlock) => void;
  onPreview?: (block: ContentBlock) => void;
  onDuplicate?: (block: ContentBlock) => void;
  onSelect?: (block: ContentBlock) => void;
  enableDelete?: boolean
}

export function BlocksList(props: BlocksListProps) {
  const [search, setSearch] = React.useState('');
  const defaultFilters = React.useMemo(() => ({
    class: props.classType || undefined,
    deleted: false,
  }), [props.classType])
  const listQuery = useMethod(listBlocks, { autoRun: [defaultFilters], defaultValue: [] });
  const deleteQuery = useMethod(deleteBlock, {
    onSuccess: listQuery.reload,
    onError: () => {
      notifyError('Failed to delete block');
    }
  });

  const blocks = listQuery.result || [];

  React.useEffect(() => {
    const lastSearch = listQuery.lastArgs?.[0]?.query || '';
    if(lastSearch !== search) {
      listQuery.debounce([{
        query: search,
        class: props.classType || undefined,
        deleted: false
      }], 500);
    }
  }, [search, props.classType]);

  const doDelete = (block: ContentBlock) => {
    const yes = prompt(`Are you sure you want to delete '${block.label}'?\n\nType yes to confirm.`) || '';
    if(yes.trim().toLowerCase() !== 'yes') return;
    deleteQuery.run(block.id);
  }

  const loading = listQuery.loading || deleteQuery.loading;

  return (
    <>
      <div className="flex flex-row mb-4 justify-between">
        <InputSearch
          showLoading={false}
          showLabel={false}
          value={search}
          placeholder={'Search Views'}
          changeValueEvent={setSearch}
          hideResults
        />
      </div>
      <Table>
        <TableSection type="head">
          <TableRow>
            <TableHeader className={'w-3/5'}>Name</TableHeader>
            <TableHeader className={'w-2/5 text-right pr-6'}>Actions</TableHeader>
          </TableRow>
        </TableSection>
        <TableSection>
          {blocks.map((block, index) => (
            <TableRow key={block.id}>
              <TableCell className={'flex flex-row gap-2'}>
                <BodyDark>{block.label ? block.label : '--'}</BodyDark>
                {block.published ? <Badge selected size={BadgeSizes.xsmall} title='Published'/> : null}
                {block.draft?.children?.length ? <Badge selected size={BadgeSizes.xsmall} title='Draft'/> : null}
                {isSys(block) ? <Badge selected size={BadgeSizes.xsmall} title='System Page'/> : null}
              </TableCell>
              <TableCell className={'text-right'}>
                {props.onEdit ? <>
                  <Pressable className={'inline mx-2'} clickEvent={() => props.onEdit?.(block)}>
                    <img src={'/icons/edit_zone.svg'} title={'Edit'} className={'inline h-5'} />
                  </Pressable>
                </> : null}
                {props.onPreview ? <>
                  <Pressable className={'inline mx-2'} clickEvent={() => props.onPreview?.(block)}>
                    <img src={'/icons/preview.svg'} title={'Preview'} className={'inline h-5'} />
                  </Pressable>
                </> : null}
                {props.onDuplicate && !isSys(block) ? <>
                  <Pressable className={'inline mx-2'} clickEvent={() => props.onDuplicate?.(block)}>
                    <img src={'/icons/duplicate.svg'} title={'Duplicate'} className={'inline h-5'} />
                  </Pressable>
                </> : null}
                {props.onSelect ? <>
                  <Button size='sm' clickEvent={() => props.onSelect?.(block)}>
                    SELECT
                  </Button>
                </> : null}
                {props.enableDelete && !isSys(block) ? <>
                  <Pressable className={'inline mx-2'} clickEvent={() => doDelete(block)}>
                    <img src={'/icons/delete_zone.svg'} title={'Delete'} className={'inline h-5'} />
                  </Pressable>
                </> : null}
              </TableCell>
            </TableRow>
          ))}
          {!loading && blocks.length === 0 ? (
            <TableRow>
              <TableCell className={'text-center'} colSpan={6}>
                <div className={'p-2'}>No records found.</div>
              </TableCell>
            </TableRow>
          ) : null}
          {loading ? (
            <TableRow>
              <TableCell className={'text-center'} colSpan={2}>
                <Loading size={'medium'} />
              </TableCell>
            </TableRow>
          ) : null}
        </TableSection>
      </Table>
    </>
  );
};

function isSys(block: ContentBlock) {
  return block.id.startsWith('sys-');
}

