import { Tooltip } from './Tooltip';
import { DropdownItems, useDropdown } from './Dropdown';
import './Input.css';
import React from 'react';

export interface InputProps<T extends (string | number)> {
  type?: 'string' | 'number' | 'color';
  label?: string;
  value?:  T;
  onChange?: (value:  T) => void;
  disabled?: boolean;
  selectLabel?: string;
  selectItems?: DropdownItems;
  onSelect?: (itemId: string) => void;
  tooltip?: string
}

export function Input<T extends (string | number)>(props: InputProps<T>) {
  const id = React.useMemo(() => Math.random().toString(), []);
  const dropdown = useDropdown({
    items: props.selectItems || [],
    onActivateItem: (id) => {
      if (props.onSelect) props.onSelect(id);
    }
  });

  return <>
    <div
      data-tooltip-id={id}
      data-tooltip-content={props.tooltip}
      className='blocks input-group'
    >
      <span className='label'>
        {props.label}
      </span>
      <input
        type={props.type || 'string'}
        disabled={props.disabled} value={props.value || ''} onChange={(e) => {
        if(props.type === 'number') {
          props.onChange?.(parseInt(e.target.value as any) || 0 as any);
        } else {
          props.onChange?.(e.target.value || '' as any);
        }
      }} />
      {props.selectItems?.length ? <>
        <span className='label dropdown' onClick={dropdown.open}>{props.selectLabel || ''}</span>
      </> : null}
    </div>
    {props.tooltip ? <Tooltip id={id} delayShow={500}/> : null}
    {dropdown.jsx}
  </>
}