import { PageLayout } from '@/components/elements/PageLayout';
import { PromotionalsList } from './components/PromotionalsList';
import { useNavigate } from "react-router";
import { Button } from '@/components/elements';
import { Promotional } from '@roc-digital/types';
import { createPromotional } from '@/logic/promotionals';
import { notifyError } from '@roc-digital/mxm-base/logic';

export function Promotionals() {
  const nav = useNavigate();

  const duplicate = (promotional: Promotional) => {

    const newPromotional: Promotional = {
      ...promotional,
      id: 0,
      code: '',
      enabled: false,
      name: `Copy of ${promotional.name}`
    }
    createPromotional(newPromotional)
    .then(promotional => nav('/promotionals/'+promotional.id))
    .catch(() => {
      notifyError('Failed to duplicate promotional.')
    })
  }
  
  return <PageLayout
    title='Promotions'
    actions={<>
      <Button size="md" title="New" clickEvent={() => nav('/promotionals/new')} />
    </>}
  >
    <PromotionalsList
      onEdit={p => nav(`/promotionals/${p.id}`)}
      enableMakeDefault
      enableDelete
      onDuplicate={duplicate}
    />
  </PageLayout>;  
}
