import React, { memo, useState } from 'react';
import { Heading1 } from '@/components/elements';
import { Button, ButtonSizes, ButtonVariants, Input, Loading } from '@roc-digital/ui-web';
import { DashboardCard, DashboardGraphCard } from './DashboardCard';
import { DashboardNumberStat } from './DashboardNumberStat';
import { useMethod } from '@roc-digital/ui-lib';
import { getDashboardStats } from '@roc-digital/mxm-base/logic';
import { LineChart } from './LineChart';
import { BarChart } from './BarChart';
import { EngagementChart } from './EngagementChart';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const Dashboard2 = memo(() => {
  const navigate = useNavigate();
  const query = useMethod(getDashboardStats, {autoRun: []});

  const {
    data,
    ...calc
  } = React.useMemo(() => {
    const data = query.result || ({
      active_users_by_day: [],
      canceled_subs: 0,
      email_addresses: 0,
      new_users_by_day: [],
      total_subs: 0,
      total_users: 0,
      news_subs_by_day: [],
      views_by_day: [],
    });

    return {
      data,
      new_users_last24Hours: subNDaysFromBucket(data.new_users_by_day, 0),
      annualValue: data.total_subs * 78.99,
      monthlyValue: data.total_subs * 7.99,
      monthlyGrowth: subNDaysFromBucket(data.news_subs_by_day, 30) * 7.99,
      eng_24h: subNDaysFromBucket(data.active_users_by_day, 0),
      eng_7d: subNDaysFromBucket(data.active_users_by_day, 7),
      views_7d: subNDaysFromBucket(data.views_by_day, 7),
      subs_7d: subNDaysFromBucket(data.news_subs_by_day, 7),
      subs_30d: subNDaysFromBucket(data.news_subs_by_day, 30),

    }
  }, [query.result])

  const loadingGraphic = <div className="w-8"><Loading size='small' /></div>;

  const [enteredPin, setEnteredPin] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  const evaluatePin = (inputVal: string) => {
    setEnteredPin(inputVal);
    if (inputVal.length === 4) {
      if (inputVal === '4455' || inputVal === '6872') {
        setAuthenticated(true);
        toast.success('Access Granted');
      } else {
        toast.error('Incorrect Pin');
        setEnteredPin('');
      }
    }
  };

  return (<>
    {!authenticated ? 
    
      <div style={{left: 0, right: 0, position: 'absolute', width: '100%', height: '100%', background: '#efefef', zIndex: 100}}>
        <div className='w-[300px] border-solid rounded-md mx-auto my-auto' style={{marginTop: '30vh', border: '1px solid #999'}}>
          <div className='font-bold text-2xl bg-[#ccc] p-4'>Authorized Access Only</div>
          <div className='p-4'>
            <div><label>Please enter your PIN</label></div>
            <div><input placeholder='0000' className='p-2 w-full rounded mt-2' type='password' value={enteredPin || ''} onChange={(e) => evaluatePin(e.target.value)}/></div>
            <div className='text-center mt-4'><Button variant={ButtonVariants.square} size={ButtonSizes.small} clickEvent={() => navigate('/dashboard')}>Cancel</Button></div>
          </div>
        </div>
      </div> 
    
    : null }
    <div className={'flex flex-col w-4/5'}>
      <div className={'flex justify-between h-16 mr-6  mt-3'}>
        <Heading1 className="text-2xl text-black">Detailed Metrics</Heading1>
      </div>
      <div className ="flex flex-row w-full gap-6 pb-6">
        <div className={'flex flex-col flex-grow w-2/3 gap-6'}>
          <DashboardCard title='Users'>
            <div className='flex flex-row'>
              <DashboardNumberStat label="Total" value={data?.total_users} info="This is the count of all users in the database."/>
              <DashboardNumberStat label="New (Last 24 Hrs)" value={calc.new_users_last24Hours} info="This is the count of all new users in the database in the last 24 hours."/>
              <DashboardNumberStat label="Users with Emails" value={data?.email_addresses} info="This is the count of all users in the database with an email addresss."/>
            </div>
          </DashboardCard>
          <DashboardCard title='Subscribers'>
            <div className='flex flex-row'>
              <DashboardNumberStat label="Annual Value" value={calc.annualValue} type='dollar' valueColor='#00ade6' info="This is the estimated annual value of all active subscriptions in the database."/>
              <DashboardNumberStat label="Monthly Value" value={calc.monthlyValue} type='dollar' valueColor='#00ade6' info="This is the estimated monthly vlaue of all active subscriptions in the database."/>
              <DashboardNumberStat label="Monthly Growth" value={calc.monthlyGrowth} type='dollar' valueColor='#00ade6' info="This is the estimated monthly value of new subscriptions."/>
            </div>
            <div className='flex flex-row'>
              <DashboardNumberStat label="Total" value={data.total_subs} info="This is the total number of all active subscriptions in the database."/>
              <DashboardNumberStat label="New (Last 7 days)" value={'+' + calc.subs_7d} info="This is the count of new active subscriptions in the last 7 days."/>
              <DashboardNumberStat label="Cancelled or Lapsed (Last 7 Days)" value={data.canceled_subs} info="This is the count of subscriptions that have been either cancelled or lapsed in the past 7 days."/>
            </div>
          </DashboardCard>
          <DashboardCard title='Engagement'>
            <div className='flex flex-row'>
              <DashboardNumberStat label="24 Hour Engagement" value={calc.eng_24h} valueColor='#15759c' info="This is the number of logged in users accessing content divided by the number of total users in the database over the past 24 hours."/>
              <DashboardNumberStat label="7 Day Engagement" value={calc.eng_7d} valueColor='#15759c' info="This is the number of logged in users accessing content divided by the number of total users in the database over the past 7 days hours."/>
              <DashboardNumberStat label="7 Day content view" value={calc.views_7d} valueColor='#15759c' info="This is the number of articles accessed over the past 7 days."/>
            </div>
            <div className='flex flex-row'>
              <DashboardNumberStat label="24 Hour Engagement %" value={calcPercent(calc.eng_24h, data.total_users)} info="This is the percent of all users in the database who accessed content in the past 24 hours."/>
              <DashboardNumberStat label="7 Day Engagement %" value={calcPercent(calc.eng_7d, data.total_users)} info="This is the percent of all users in the database who accessed content in the past 7 days."/>
              <DashboardNumberStat label="Average Views/User" value={Math.floor(calc.views_7d / calc.eng_7d)} info="This is the number of articles accessed in the past 7 days averaged into the total of users in the database accessing content in the past 7 days."/>
            </div>
          </DashboardCard>
        </div>
        <div className={'flex flex-col flex-grow mr-6 w-1/3 gap-6'}>
          <DashboardGraphCard title='New Users(with emails)'>
            <LineChart
              histogram={data.new_users_by_day}
              chartHeight={155}
            />
          </DashboardGraphCard>

          <DashboardGraphCard title='New Subscribers'>
            <BarChart
              histogram={data.news_subs_by_day}
              chartHeight={257}
            />
          </DashboardGraphCard>

          {/* <DashboardGraphCard title='Engagement'>
            <BarChart
              histogram={data.news_subs_by_day}
            />
          </DashboardGraphCard> */}

          <DashboardGraphCard title={<>
            Engagement by Day
            <div style={{fontSize: '11px'}}>(in thousands)</div>
          </>}>
            <EngagementChart
              histogram={data.views_by_day}
              chartHeight={257}
            />
          </DashboardGraphCard>
        </div>
      </div>
    </div>
    </>);
});

Dashboard2.displayName = 'Dashboard';

export default Dashboard2;


function subNDaysFromBucket(bucket: [number | null, number][], days: number) {
  const since = new Date(new Date().setDate(new Date().getDate()-days));

  const day = (since.getUTCFullYear() * 10000) + ((since.getUTCMonth()+1) * 100) + since.getUTCDate();
  
  return bucket.reduce((a,b,c) => {
    if(b[0] && b[0] >= day) return a + b[1];
    return a;
  }, 0);
}

function calcPercent(part: number, whole: number) {
  return (part * 100 / whole).toFixed(0) + '%';
}