import { Input, Table, TableCell, TableHeader, TableRow, TableSection, Toggle } from "@/components/elements";
import { FormGroup, FormLabel, FormRow } from "@/components/elements/FormGroups";
import { inputProps, toggleProps, useObjectSetter } from '@/hooks/useObjectSetter'
import { UiBlockInput } from "@/components/elements/UiBlocks";
import { Promotional } from "@roc-digital/types";

export interface PromotionFormProps {
  value: Promotional;
  onChange: (next: Promotional) => void;
}

export function PromotionForm(props: PromotionFormProps) {

  const popup = useObjectSetter<Promotional>(props.value, props.onChange);

  return <>
    <FormRow>
      <FormGroup title="Promotion Code" grow={0}>
        <Input  {...inputProps(popup, 'code')} />
      </FormGroup>
      <FormGroup title="Enabled" grow={0}>
        <Toggle label="" {...toggleProps(popup, 'enabled')} />
      </FormGroup>
    </FormRow>

    <FormRow>
      <FormGroup title="Name">
        <Input  {...inputProps(popup, 'name')} />
      </FormGroup>
    </FormRow>

    <FormRow>
      <FormGroup title="Stripe Coupon Code" grow={0}>
        <Input  {...inputProps(popup, 'stripe_coupon')} />
      </FormGroup>
    </FormRow>

    <hr />

    <FormLabel>UI Blocks</FormLabel>
    <Table>
      <TableSection type="body">
        <TableRow>
          <TableHeader className="w-[300px]">Name</TableHeader>
          <TableHeader>Setting</TableHeader>
        </TableRow>

        <TableRow>
          <TableHeader>Pricing Table</TableHeader>
          <TableCell><UiBlockInput {...inputProps(popup, 'pricing_table')} classType="web-price-table" /></TableCell>
        </TableRow>

        <TableRow>
          <TableHeader>Subscribe Landing Header</TableHeader>
          <TableCell><UiBlockInput {...inputProps(popup, 'subscribe_landing_page_header')}  classType="subscribe-landing-header" /></TableCell>
        </TableRow>

        <TableRow>
          <TableHeader>Trending Subscribe Banner Large</TableHeader>
          <TableCell><UiBlockInput {...inputProps(popup, 'trending_subscribe_banner_large')} classType="trending-subscribe-banner-large" /></TableCell>
        </TableRow>
        <TableRow>
          <TableHeader>Trending Subscribe Banner Small</TableHeader>
          <TableCell><UiBlockInput {...inputProps(popup, 'trending_subscribe_banner_small')} classType="trending-subscribe-banner-small" /></TableCell>
        </TableRow>
        {/* <TableRow>
        <TableHeader>Trending Subscribe Banner Overlay</TableHeader>
        <TableCell><UiBlockInput {...inputProps(popup, 'trending_subscribe_banner_overlay')}/></TableCell>
      </TableRow> */}

        <TableRow>
          <TableHeader>Trending Signup Banner Large</TableHeader>
          <TableCell><UiBlockInput {...inputProps(popup, 'trending_signup_banner_large')} classType="trending-signup-banner-large"/></TableCell>
        </TableRow>
        <TableRow>
          <TableHeader>Trending Signup Banner Small</TableHeader>
          <TableCell><UiBlockInput {...inputProps(popup, 'trending_signup_banner_small')} classType="trending-signup-banner-small"/></TableCell>
        </TableRow>
        {/* <TableRow>
        <TableHeader>Trending Signup Banner Overlay</TableHeader>
        <TableCell><UiBlockInput {...inputProps(popup, 'trending_signup_banner_overlay')}/></TableCell>
      </TableRow> */}

      </TableSection>
    </Table>
  </>
}