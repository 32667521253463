import { RuiDocument } from '@roc-digital/rich-ui';
import { UiBlockTemplate } from './types';

const DOCUMENT: RuiDocument = {
  "children": [
    {
      "children": [
        {
          "children": [],
          "data": {
            "url": "https://www.youtube.com/watch?v=2jPHWsSPMkw"
          },
          "element": "embed"
        }
      ],
      "data": {
        "alignment": "top-left",
        "backgroundColor": "#ffffff",
        "direction": "column",
        "gap": 8,
        "height": "hug",
        "width": "fill"
      },
      "element": "container"
    }
  ],
  "textTokens": []
}

export const SubscribeLandingHeader: UiBlockTemplate = {
  class: 'subscribe-landing-header',
  label: 'Subscribe Landing header',
  description: 'Header content displayed on the subscribe landing page.',
  document: DOCUMENT,
  validate: () => null,
  webPreviewUrl: '/subscribe',
}


