import { PageLayout } from "@/components/elements/PageLayout";
import { listPopups } from "@/logic/popups";
import { useMethod } from "@roc-digital/ui-lib";
import { useNavigate } from "react-router";
import { Button } from '@/components/elements';
import { POPUP_FILTERS_DEFAULTS, PopupList } from "@/components/elements/PopupList";
import React from "react";

export function ListPopups() {
  const nav = useNavigate();
  const [filters, setFilters] = React.useState(POPUP_FILTERS_DEFAULTS);
  const query = useMethod(listPopups, { autoRun: [POPUP_FILTERS_DEFAULTS] });
  const popups = query.result || [];

  return <PageLayout
    title="Popups"
    actions={<>
      <Button size="md" title="Create Popup" clickEvent={() => nav('/popups/create')} />
    </>}
  >
    <PopupList
      popups={popups}
      onClick={popup => nav('/popups/' + popup.id)}
      loading={query.loading}
      filters={filters}
      onFiltersChange={(filters) => {
        query.debounce([filters], 350);
        setFilters(filters);
      }}
    />
  </PageLayout>;
}