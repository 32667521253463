import React, { memo, useEffect } from 'react';
import {
  useClickEvent,
  usePaginateEvent,
  PublisherData,
  useChangeEvent,
  InputData,
  curatorsIcon,
  PaginationData,
  useMethod,
} from '@roc-digital/ui-lib';
import { Event } from '@roc-digital/mxm-base/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Users as BaseUsers, User } from '@roc-digital/mxm-base/data';
import { listAdminUsers } from '@roc-digital/mxm-base/logic';
import { format } from 'date-fns';
import {
  Avatar,
  Badge,
  BodyDark,
  BodyLight,
  Heading1,
  Icon,
  InputSearch,
  Pagination,
  Pressable,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableSection,
  Toggle,
  Loading,
  Button,
  ButtonSizes,
} from '@/components/elements';
import { openExportUsersModal } from '@/components/elements/ExportUsersModal';

const Users = memo(() => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const [search, setSearch] = React.useState<string>('');
  const listUsers = useMethod(listAdminUsers);
  const allUsers = listUsers.result;

  const paginateEvent = usePaginateEvent(
    ({ data }: Event<PaginationData>) => {
      console.log('paginationEvent', data);
      listUsers.run({ page: data?.page, count: 10, role: params.get('role') || '', query:search});
    },
    { namespace: 'mxm.http.users' },
    {},
    [search]
  );

  const changeSearchEvent = useChangeEvent(({ data }: Event<InputData>) => {
    setSearch(data?.value as string);
  });

  useEffect(() => {
    listUsers.run({
      page: 1,
      count: 10,
      role: params.get('role') || '',
      query: search
    })
  }, [params.get('role'), search]);

  const handleUserClick = useClickEvent(({ data }: Event<PublisherData>) => {
    navigate(`${data?.id}` || ('/' as string));
  });

  const toggleCuratorRole = () => {
    setParams((params) => {
      if(params.get('role') === 'curator') {
        params.set('role', '');
      } else {
        params.set('role', 'curator');
      }
      return params;
    })
  };

  return (
    <div className="flex flex-col w-4/5">
      <div className="flex justify-between h-16 mr-6 mt-3">

        <Heading1 className="text-2xl text-black">Users</Heading1>
        <Button size={ButtonSizes.medium} clickEvent={openExportUsersModal}>
          <BodyLight className={'text-white'}>Export</BodyLight>
        </Button>

      </div>
      <div className="flex-grow bg-white p-10 mr-6 rounded">
        <div className="flex flex-row mb-4 justify-between items-center">
          <div className="w-1/2 pr-8">
            <InputSearch
              showLabel={false}
              placeholder={'Search Users'}
              changeValueEvent={changeSearchEvent}
              hideResults
            />
          </div>
          <div>
            <Toggle
              initialState={params.get('role') ? true : false}
              isLabelLeft
              label={'Show curators Only'}
              onToggle={toggleCuratorRole}
            />
          </div>
        </div>
        <Table className={'table-fixed'}>
          <TableSection type="head">
            <TableRow>
              <TableHeader className={'w-1/12'}> </TableHeader>
              <TableHeader className={'w-4/12 pl-0'}>Name/Email</TableHeader>
              <TableHeader className={'w-3/12 pl-0'}>Username</TableHeader>
              <TableHeader className={'w-2/12 pl-0'}>Created/Modified</TableHeader>
              <TableHeader className={'w-2/12 pl-0'}>Status</TableHeader>
            </TableRow>
          </TableSection>
          <TableSection>
            {allUsers?.map((user: User) => (
                <TableRow key={user.id}>
                  <TableCell className={'text-center align-top pt-4'}>
                    <Avatar size={'medium'} image={user?.avatar_url || 'https://i.imgur.com/tdi3NGa_d.webp'} />
                    {user.role === 'curator' && <Icon src={curatorsIcon} size={'small'} />}
                  </TableCell>
                  <TableCell className={'pl-0 break-words'}>
                    <Pressable clickEvent={handleUserClick} id={user.id}>
                      <BodyDark>{user.name && user.name !== '' ? user.name : '-'}</BodyDark>
                      <br />
                      <BodyLight>{user.email}</BodyLight>
                    </Pressable>
                  </TableCell>
                  <TableCell className={'pl-0 break-words'}>
                    <BodyDark> @{user.username}</BodyDark>
                  </TableCell>
                  <TableCell className={'pl-0 flex flex-col'}>
                    <BodyDark>{format(new Date((user?.created as number) * 1000), 'MM/dd/yyyy')}</BodyDark>
                    <BodyLight>
                      {'Modified ' + format(new Date((user?.modified as number) * 1000), 'MM/dd/yyyy')}
                    </BodyLight>
                  </TableCell>
                  <TableCell className={'pl-0'}>
                    {user.status === 'verified' ? (
                      <Badge type={'subdued'}>
                        <BodyDark className={'capitalize'}>Verified</BodyDark>
                      </Badge>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            {!listUsers.loading && allUsers?.count() === 0 ? (
              <TableRow>
                <TableCell className={'text-center'} colSpan={6}>
                  <div className={'p-2'}>No records found.</div>
                </TableCell>
              </TableRow>
            ) : null}
            {listUsers.loading ? (
              <TableRow>
                <TableCell className={'text-center'} colSpan={6}>
                  <Loading size={'medium'} />
                </TableCell>
              </TableRow>
            ) : null}
          </TableSection>
        </Table>
        {allUsers && allUsers.pagination && (
          <Pagination className={'mt-5'} paginateEvent={paginateEvent} paginator={allUsers?.pagination} />
        )}
      </div>
    </div>
  );
});

Users.displayName = 'Users';

export default Users;
