import { BodyDark, Button, ButtonSizes, ButtonVariants, Heading1, Input, TagsInput } from '@/components/elements';
import { AddTagPrompt } from '@/components/elements/AddTagPrompt';
import { Zone } from './types';
import { useOnChangeHook } from './useOnChangeHook';

export interface ConfigureZoneModalProps {
  zone: Zone;
  onChange?: (next: Zone) => void;
  onRemove?: () => void;
}

const desktopLayouts = [
  '/layouts/desktop_0.png',
  '/layouts/desktop_1.svg',
  '/layouts/desktop_2.svg',
  '/layouts/desktop_3.svg',
  '/layouts/desktop_4.svg',
  '/layouts/desktop_5.svg',
  '/layouts/desktop_6.svg',
  '/layouts/desktop_7.svg',
  '/layouts/desktop_8.svg',
  '/layouts/desktop_9.svg',
  '/layouts/desktop_10.png',
];

export function ConfigureZoneModal(props: ConfigureZoneModalProps) {
  const [zone, setZone, setZoneProp] = useOnChangeHook(props.zone)


  const handleWebLayoutClick = (index: number) => {
    setZoneProp('web_layout', index);
  };

  const tags = zone.source === 'tags' ? zone.tags || [] : [];

  return <div className="flex flex-col w-full bg-white px-2">
    <div className="flex justify-between h-12 mt-3 mr-10">
      <Heading1 className={'text-2xl text-black px-4 mt-4'}>Configure Zone</Heading1>
    </div>
    <div className="flex flex-col w-full h-full rounded-lg py-4 px-4">
      <div className="flex flex-row w-full justify-between">
        <span className="w-1/2 mr-24">
          <BodyDark>Title</BodyDark>
          <Input
            className="w-full mb-8"
            value={zone?.title}
            id="title"
            size={'large'}
            changeEvent={(e) => setZoneProp('title',e.target.value)}
          />
        </span>
      </div>

      <div className="flex flex-row w-full justify-between">
        <span className="w-full">
          <div className='flex flex-col gap-2'>
            <label className='flex gap-2 items-center'>
              <input type="checkbox" checked={zone.hide_title} onClick={() => setZoneProp('hide_title', !zone.hide_title)}/>
              Hide Title
            </label>
          </div>
        </span>
      </div>

      <div className="flex flex-row w-full justify-between">
        <span className="w-full">
          <div className='flex flex-col gap-2'>
            <label className='flex gap-2 items-center'>
              <input type="checkbox" checked={zone.pay_wall} onClick={() => setZoneProp('pay_wall', !zone.pay_wall)}/>
              Pay Wall
            </label>
          </div>
        </span>
      </div>

      <div className="flex flex-row w-full justify-between">
        <span className="w-full">
          <div className="flex flex-row justify-between">
            <BodyDark>Source</BodyDark>
          </div>
          <div className='flex flex-col gap-2'>
            <label className='flex gap-2 items-center'>
              <input type="radio" checked={zone.source === 'articles'} onClick={() => setZoneProp('source', 'articles')}/>
              Articles
            </label>

            <label className='flex gap-2 items-center'>
              <input type="radio" checked={zone.source === 'tags'} onClick={() => setZoneProp('source', 'tags')}/>
              Tags
            </label>
          </div>
        </span>
      </div>

      <div className="flex flex-row w-full justify-between relative">
        <span className="w-full">
          <div className="flex flex-row justify-between">
            <BodyDark>Tags</BodyDark>
            <AddTagPrompt returnNewTag={() => {}} tags={tags} />
          </div>
          {tags && (
            <TagsInput
              value={zone.tags || []}
              onChange={(tags) => setZoneProp('tags', tags)}
            />
          )}
        </span>
        {zone.source !== 'tags' ? <>
          <div className='absolute t-0 l-0 w-full h-full' style={{backgroundColor: 'rgba(255,255,255,.8)'}}></div>
        </> : <></>}
      </div>
      <div className="flex flex-col w-full justify-between">
        <div className="flex w-full border-b-2 border-slate-300 pb-4">
          <span className="mr-24">
            <BodyDark>Layout</BodyDark>
          </span>
        </div>

        <div className="flex w-full pb-4">
          <span className="mr-24 grid grid-cols-3 p-4 grid-rows-3">
            {desktopLayouts
              .map((layout, index) => ({
                layout,
                selected: zone?.web_layout === index,
              }))
              .map(({ layout, selected }, index) => (
                <img
                  key={index}
                  src={layout}
                  className={`${selected ? 'border-4 border-cyan-300' : 'border-4 border-white'} mb-2`}
                  onClick={() => handleWebLayoutClick(index)}
                />
              ))}
          </span>
        </div>
        <div className='flex flex-row'>
          <Button
            variant={ButtonVariants.destructive}
            size={ButtonSizes.medium}
            customClassName="w-1/8 self-center px-6 !rounded-md h-12"
            clickEvent={() => props.onRemove?.()}
          >
            Remove
          </Button>
          <div className='flex-grow'></div>
          <Button
            size={ButtonSizes.medium}
            customClassName="w-1/8 self-center px-6 !rounded-md h-12"
            clickEvent={() => props.onChange?.(zone)}
          >
            Done
          </Button>
        </div>

      </div>
    </div>
  </div>;
}