import { memo } from 'react';
import { useNavigate } from 'react-router';
import {
  BodyLight,
  Button,
  ButtonSizes,
  Heading1,
  ArticleSearch,
  openNotifyModal
} from '@/components/elements';
import { openSmsModal } from '@/components/elements/SmsModal';

const Content = memo(() => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-4/5">
      <div className="flex justify-between h-16 mr-6 mt-3 align-middle">
        <Heading1 className="text-2xl text-black">Content</Heading1>

        <div className='flex flex-row gap-4'>
        <Button size={ButtonSizes.medium} clickEvent={openSmsModal}>
            <BodyLight className={'text-white'}>Send SMS</BodyLight>
          </Button>
          <Button size={ButtonSizes.medium} clickEvent={openNotifyModal}>
            <BodyLight className={'text-white'}>Send Generic Notification</BodyLight>
          </Button>
          <Button size={ButtonSizes.medium} clickEvent={() => navigate('/articles/new')}>
            <BodyLight className={'text-white'}>Add Content</BodyLight>
          </Button>
        </div>
      </div>
      <div className="flex-grow bg-white p-10 mr-6 rounded">
        <ArticleSearch
          onView={(article) => navigate(('/articles/' + article.id + '/preview') as string)}
          onEdit={(article) => navigate(('/articles/' + article.id) as string)}
          onAi={(article) => navigate(('/articles/' + article.id + '?content=mxm_ai') as string)}
          onNotify={(article) => {
            openNotifyModal({
              articleId: article.id
            });
          }}
        />
      </div>
    </div>
  );
});

Content.displayName = 'Content';

export default Content;
