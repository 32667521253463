import { memo, useEffect, useState } from 'react';
import { useMethod } from '@roc-digital/ui-lib';
import { Article, ArticleSchema } from '@roc-digital/mxm-base/data';
import {
  Button,
  ButtonSizes,
  ButtonVariants,
  Heading2,
  Input,
  Modal,
  Select,
  TextArea,
  TextEditor
} from '@/components/elements';
import { toast } from 'react-toastify';
import { extract } from '@/logic/extract';
import { openai } from '@/logic/openai';
import PromptOptions from './PromptOptions.json';
import { unfurl } from '@/logic/unfurl';

export declare type ExtractedData = {
  title?: string;
  content?: string;
  url?: string;
};

type Props = {
  articleUrl?: string;
  onGenerate: Function;
};

export const AiModal = memo(({ articleUrl, onGenerate }: Props) => {
  const promptOptions = PromptOptions;

  const [url, setUrl] = useState<string>();
  const [extractedData, setExtractedData] = useState<ExtractedData>({});
  const [formality, setFormality] = useState('formal');
  const [tone, setTone] = useState('informative');
  const [style, setStyle] = useState('journalistic');
  const [length, setLength] = useState('300 word');
  const [layout, setLayout] = useState('Please break it into multiple paragraphs.');
  const [generating, setGenerating] = useState<boolean>(false);
  const [extracting, setExtracting] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [content, setContent] = useState<any>();
  const [generated, setGenerated] = useState(false);

  useEffect(() => {
    if (articleUrl) {
      setUrl(articleUrl);
    } else {
      setUrl(undefined);
    }
  }, []);

  const extractQuery = useMethod(extract, {
    onSuccess: (changes) => {
      if(Object.keys(changes).length === 0) {
        toast.warn('Could not retrieve any details from url. Attempting to read meta data.');
        unfurlQuery.run(articleUrl || '');
        return;
      }
      setExtractedData(changes);
      setExtracting(false);
      toast.success('Content extracted from url.');
    },
    onError: (err: any) => {
      setExtracting(false);
      unfurlQuery.run(articleUrl || '');
      return toast.error('Error extracting. Attempting to read meta.' + err.toString());
    }
  });

  const unfurlQuery = useMethod(unfurl, {
    onSuccess: (changes) => {
      console.log(changes, Object.keys(changes));
      if(Object.keys(changes).length === 0) {
        toast.warn('Could not retrieve any details from url.');
        return;
      }
      console.log('Unfurl Meta', changes);
      changeExtractedContent(changes.title);
      //changeExtractedContent(changes.content);
      toast.success('Meta content updated from url.');
      setExtracting(false);
      // return new Article({ ...article, content});
    },
    onError: (err) => {
      setExtracting(false);
      return toast.error('Error getting details from link. ' + err.toString());
    }
  });

  const generateArticle = ((extractedData: ExtractedData) => {
    const systemPrompt = `Using the content you are provided, write a new ${length} ${formality} ${extractedData.title ? 'article about ' + extractedData.title : ''} with a ${tone} tone in a ${style} style. ${layout}`
    const userPrompt = extractedData.content;
    
    const queryParams = {
      model: "gpt-4",
      messages: [
        {
          "role": "system",
          "content": systemPrompt
        },
        {
          "role": "user",
          "content": userPrompt
        }
      ],
      temperature: 0,
      max_tokens: 1024,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };

    aiGenerate.run(queryParams);
  }); 

  const aiGenerate = useMethod(openai, {
    onSuccess: (generatedContent) => {
      if(Object.keys(generatedContent).length === 0) {
        toast.warn('Could not generate content.');
        return;
      }
      const content = markdownToHtml(generatedContent['choices'][0]['message']['content']); 
      setContent(content);
      setGenerated(true);
      setStep(2);
      
      toast.success('Article generated from content.');
      setGenerating(false);
    },
    onError: (err: any) => {
      return toast.error('Error generating article from content. ' + err.toString());
    }
  });

  const markdownToHtml = (content: string) => {
    const showdown  = require('showdown');
    const converter = new showdown.Converter();
    return converter.makeHtml(content);
  }

  const changeExtractedTitle = (title: string) => {
    let newExtracted = {...extractedData};
    newExtracted.title = title;
    setExtractedData(newExtracted);
  };

  const changeExtractedContent = (content: string) => {
    let newExtracted = {...extractedData};
    newExtracted.content = content;
    setExtractedData(newExtracted);
  };

  const copyContent = async (content: any) => {
    try {
      await navigator.clipboard.writeText(content);
      toast.success('Content copied to clipboard');
    } catch (err) {
      toast.error('Could not copy content to clipboard');
      console.error('Failed to copy: ', err);
    }
  };

  const saveAndClose = () => {
    onGenerate(content);
    closeModal(false);
  };

  const closeModal = (conf: boolean = true) => {
    if (conf && generated && !confirm('Are you sure you want to close this modal? The content you generated will be lost.')) { return; }
    Modal.close('mxm.modal');
  };

  const ExtractInput = <div className="p-2 border border-gray-300 rounded-md my-4 bg-gray-100 shadow-sm">
    <div>Extract from Source URL (optional)</div>
    <div className="w-full flex gap-4 items-center">
      <Input id="url" changeEvent={(e) => setUrl(e.target.value)} placeholder={'Paste url'} value={url} className='w-full' />
      <Button
        disabled={!url || generating}
        clickEvent={() => {extractQuery.run(url || ''); setExtracting(true);}}
        size={ButtonSizes.medium}
        loading={extracting}
      >
        Extract
      </Button>
    </div>
  </div>;

  const AISettings = <div>
    <div className="w-full mt-2">
      <label>Source Title</label>
      <Input value={extractedData?.title || ''} className="w-full"  changeEvent={(e) => changeExtractedTitle(e.target.value)} />
    </div>
    <div className="w-full mt-2">
      <label>Source Text</label>
      <TextArea value={extractedData?.content || ''} className="w-full h-[202px]" changeEvent={(e) => changeExtractedContent(e.target.value)} />
    </div>
    <div className="flex flex-row gap-1 mb-8">
    <div>
        <div>Words</div>
        <Select changeEvent={(e) => setLength(e.target.value)} size="x-small" id={'length'} value={length} options={promptOptions.length}></Select>
      </div>
      <div>
        <div>Formality</div>
        <Select changeEvent={(e) => setFormality(e.target.value)} size="x-small" id={'formality'} value={formality} options={promptOptions.formality}></Select>
      </div>
      <div>
        <div>Tone</div>
        <Select changeEvent={(e) => setTone(e.target.value)} size="x-small" id={'tone'} value={tone} options={promptOptions.tones}></Select>
      </div>
      <div>
        <div>Style</div>
        <Select changeEvent={(e) => setStyle(e.target.value)} size="x-small" id={'style'} value={style} options={promptOptions.styles}></Select>
      </div>
      <div>
        <div>Layout</div>
        <Select changeEvent={(e) => setLayout(e.target.value)} size="x-small" id={'style'} value={layout} options={promptOptions.layouts}></Select>
      </div>
    </div>
    <div className="mb-2 flex inline-block gap-2">
      <Button clickEvent={() => closeModal()} size={ButtonSizes.medium} variant={ButtonVariants.secondary}>Cancel</Button>
      <Button 
        size={ButtonSizes.medium}
        clickEvent={() => {generateArticle(extractedData); setGenerating(true);}}
        loading={generating}
        disabled={!extractedData.title && !extractedData.content || extracting}
      >
        {generated ? 'Regenerate ' : 'Generate '}AI Article
      </Button>
    </div>
  </div>;

  const AIGeneratedContent = <div>
    <TextEditor onChange={(e) => {setContent(e)}} value={content || ''}/>
    <div className="mt-4 flex inline-block gap-2">
      <Button clickEvent={() => closeModal()} size={ButtonSizes.medium} variant={ButtonVariants.secondary}>Cancel</Button>
      <Button clickEvent={() => copyContent(content)} disabled={!content} size={ButtonSizes.medium} variant={ButtonVariants.secondary}>Copy to Clipboard</Button>
      <Button clickEvent={() => saveAndClose()}disabled={!content} size={ButtonSizes.medium}>Save Content</Button>
    </div>
  </div>;

  return (
    <div className="p-8">
      <Heading2 className={'mb-8 text-admin-dark !font-extrabold !text-[26px]'}>Generate AI Content</Heading2>
      <div className="flex inline-block items-center my-1">
        <span onClick={() => setStep(1)} className={`cursor-pointer ${step === 1 ? 'font-bold' : ''}`}>AI Settings</span>
        <img src={'/icons/chevron_right.svg'} className="h-4 w-4 ml-1 mr-1 opacity-50"/>
        <span onClick={() => {generated ? setStep(2) : null}} className={`${generated ? 'cursor-pointer' : ''} ${step === 2 ? 'font-bold' : ''}`}>AI Output</span>
      </div>
      {step === 1 ? <>
        {ExtractInput}
        {AISettings}
      </> : null}
      {step === 2 ? <>
        {AIGeneratedContent}
      </>: null}
    </div>
  );
});
