import { Input } from './Input';
import { DropdownItems } from './Dropdown';
import { RadioGroup, RadioGroupOption } from './RadioGroup';
import { Grouping } from './Grouping';
import { AlignmentInput } from './AlignmentInput';
import React from 'react';
import { Swatch } from './Swatch';
import { FlexLayoutProperties } from '@roc-digital/rich-ui';
import { useProperties, bindProp } from '@/hooks/useProperties';

const MXM_BLUE = '#24aae1';

export interface FlexLayoutInputProps {
  value?: FlexLayoutProperties;
  onChange?: (value: FlexLayoutProperties) => void;
}

export function FlexLayoutInput(props: FlexLayoutInputProps) {
  const state = useProperties(props.value, props.onChange || (() => {}));

  return <>
    <div className="flex flex-row gap-[4px] w-full">
      <Grouping label='Sizing' fill>
        <Input
          type={getSizeType(state.current?.width)}
          label='W'
          selectItems={sizingOptions}
          onSelect={(item) => {
            if(item === 'hug') state.set('width', 'hug');
            if(item === 'fixed') state.set('width', 100);
            if(item === 'fill') state.set('width', 'fill');
          }}
          selectLabel={getSizeLabel(state.current?.width)}
          disabled={state.current?.width === 'hug'}
          tooltip='Container Width'
          {...bindProp(state, 'width')}
        />
      </Grouping>

      <Grouping label='' fill>
        <Input
          type={getSizeType(state.current?.height)}
          label='H'
          selectItems={sizingOptions}
          onSelect={(item) => {
            if(item === 'hug') state.set('height', 'hug');
            if(item === 'fill') state.set('height', 'fill');
            if(item === 'fixed') state.set('height', 100);
          }}
          selectLabel={getSizeLabel(state.current?.height)}
          disabled={state.current?.width === 'hug'}
          tooltip='Container Height'
          {...bindProp(state, 'height')}
        />
      </Grouping>
    </div>


    <div className="flex flex-row gap-[4px] w-full">
      <Grouping label='Min Sizing' fill>
        <Input
          type='number'
          label='W'
          tooltip='Minimum Container Width'
          {...bindProp(state, 'minWidth')}
        />
      </Grouping>

      <Grouping label='' fill>
        <Input
          type="number"
          label='H'
          tooltip='Minimum Container Height'
          {...bindProp(state, 'minHeight')}
        />
      </Grouping>
    </div>

    <div className="flex flex-row gap-[4px] w-full">
      <Grouping label='Max Sizing' fill>
        <Input
          type='number'
          label='W'
          tooltip='Maximum Container Width'
          {...bindProp(state, 'maxWidth')}
        />
      </Grouping>

      <Grouping label='' fill>
        <Input
          type="number"
          label='H'
          tooltip='Maximum Container Height'
          {...bindProp(state, 'maxHeight')}
        />
      </Grouping>
    </div>

    <div className="flex flex-row gap-[4px] w-full">
      <Grouping label='Direction + Gap' fill>
        <RadioGroup
          options={flexDirections}
          {...bindProp(state, 'direction')}
        />
        <Input
          type='number'
          label='Gap'
          tooltip='Gap between child elements'
          {...bindProp(state, 'gap')}
        />
      </Grouping>

      <Grouping label='Alignment' fill>
        <AlignmentInput {...bindProp(state, 'alignment')}/>
      </Grouping>
    </div>

    <Grouping label='Padding' row>
      <Input
        type='number'
        label='H'
        tooltip='Horizontal Padding'
        {...bindProp(state, 'paddingLeft')} />
      <Input
        type='number'
        label='V'
        tooltip='Vertical Padding'
        {...bindProp(state, 'paddingTop')} />
    </Grouping>

    <Grouping label='Margin' row>
      <Input
        type='number'
        label='H'
        tooltip='Horizontal Margin'
        {...bindProp(state, 'marginLeft')} />
      <Input
        type='number'
        label='V'
        tooltip='Vertical Margin'
        {...bindProp(state, 'marginTop')} />
    </Grouping>

    <Grouping label='Style' row>
      <Input
        type={state.current?.backgroundColor === 'none' ? 'string' : 'color'}
        label='BG'
        tooltip='Background Color'
        selectItems={backgroundColorItems}
        onSelect={(item) => {
          if(item === 'none') state.set('backgroundColor', 'none');
          if(item === 'color') state.set('backgroundColor', '#ffffff');
          if(item === 'mxm-blue') state.set('backgroundColor', MXM_BLUE);
        }}
        selectLabel={state.current?.backgroundColor === 'none' ? 'None' : '-'}
        disabled={state.current?.backgroundColor === 'none'}
        {...bindProp(state, 'backgroundColor')} />
      <Input
        type='number'
        label='R'
        tooltip='Border Radius'
        {...bindProp(state, 'borderRadius')} />
    </Grouping>

  </>
}


function getSizeLabel(size: number | 'fill' | 'fixed' | 'hug' | undefined) {
  if(typeof size === 'number') return 'px';
  return '-';
}

function getSizeType(size: number | 'fill' | 'fixed' | 'hug' | undefined) {
  if(typeof size === 'number') return 'number';
  return 'string';
}

const sizingOptions: DropdownItems = [
  { id: 'fixed', label: 'Fixed' },
  { id: 'hug', label: 'Hug Contents' },
  { id: 'fill', label: 'Fill Parent' },
];

const flexDirections: RadioGroupOption<FlexLayoutProperties['direction']>[] = [
  { id: 'row', tooltip: 'Row', content: 'Row' },
  { id: 'column', tooltip: 'Column', content: 'Col' },
];


const backgroundColorItems: DropdownItems = [
  { id: 'none', label: 'None' },
  { id: 'color', label: 'Custom' },
  { id: 'mxm-blue', label: 'MXM Blue', render: () => <Swatch color={MXM_BLUE}/>},
];