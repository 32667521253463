import { JsonFetch } from "@roc-digital/mxm-base/logic";

export async function openai(prompt: any): Promise<any> {
  // TODO: Unhardcode this env setting
  const result = await JsonFetch<any>({
    method: 'POST',
    url: `${process.env.REACT_APP_AUX_API_URL}/openai`,
    data: prompt
  });

  if(!result) {
    return {} as any;
  }

  return result;
}

