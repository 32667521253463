import React, { memo, useState, useEffect } from 'react';
import { leftArrowIcon, PublisherData, useClickEvent, useCustomEvent, useStateEvent } from '@roc-digital/ui-lib';
import { Event } from '@roc-digital/mxm-base/types';
import { Stats } from '@roc-digital/mxm-base/data';
import { getStats } from '@roc-digital/mxm-base/logic';
import { useNavigate } from 'react-router';
import { Heading2, BodyDark, Heading1, Pressable, Icon } from '@/components/elements';
import Chart from '@/components/views/Dashboard/Chart';
import HorizontalPercentageBar from '@/components/views/Dashboard/ProgressBar';
import { Button, ButtonSizes, Loading } from '@roc-digital/ui-web';

type TopArticlesPeriod = 'day' | 'week' | 'month';

type Article = { title: string; views: number; url: string };
type TopArticles = Record<TopArticlesPeriod, Article[]>;

const Dashboard = memo(() => {
  const [topArticlesPeriod, setTopArticlesPeriod] = useState<TopArticlesPeriod>('day');
  const navigate = useNavigate();
  const [stats] = useStateEvent<any>('mxm.http.adminStats', 'getStats.success', undefined);
  const [loading, setLoading] = React.useState(true);

  useCustomEvent(
    'getStats.success',
    (payload: Event<Stats>) => {
    },
    { namespace: 'mxm.http.adminStats' },
    { },
    [ ]
  );

  React.useEffect(() => {
    setLoading(true);
    getStats().finally(() => setLoading(false));
  }, [ ]);

  const handleClickPeriod = useClickEvent(
    ({ data }: Event<PublisherData>) => {
      setTopArticlesPeriod(data.id as TopArticlesPeriod);
    },
    {},
    [ ],
    [topArticlesPeriod]
  );

  const contentClickEvent = useClickEvent(() => {
    navigate('/content');
  });

  const tagsClickEvent = useClickEvent(() => {
    navigate('/tags');
  });

  const usersClickEvent = useClickEvent(() => {
    navigate('/users');
  });

  const curatorsClickEvent = useClickEvent(() => {
    navigate('/users?role=curator');
  });

  const loadingGraphic = <div className="w-8"><Loading size='small' /></div>;

  return (
    <div className={'flex flex-col w-4/5'}>
      <div className={'flex justify-between h-16 mr-6  mt-3'}>
        <Heading1 className="text-2xl text-black">Dashboard</Heading1>
        <div><Button size={ButtonSizes.small} clickEvent={() => navigate('/dashboard-1')}>Detailed Metrics</Button></div>
      </div>
      <div className="flex flex-row w-full">
        <div className={'flex flex-col flex-grow w-2/3'}>
          <div className={'bg-white px-6 py-7 mr-6 mb-6 flex flex-col justify-between rounded "shadow-sm"'}>
            <div className={'flex flex-row justify-between mb-6'}>
              <Heading2 className={'text-base font-bold text-black'}>Content</Heading2>
              <Pressable clickEvent={contentClickEvent}>
                <Icon src={leftArrowIcon} />
              </Pressable>
            </div>
            <div className={'flex flex-row justify-start'}>
              <div className={'flex flex-col mr-6 mb-6 w-1/2'}>
                <Heading1 className={'text-admin-dark text-4xl'}>
                  {loading ? loadingGraphic : stats?.ArticleStats.view_count.toLocaleString("en-US")}
                </Heading1>
                <BodyDark className={'text-xs'}>Read (Last 7 days)</BodyDark>
              </div>
              <div className={'flex flex-col mr-6 w-1/2'}>
                <Heading1 className={'text-admin-dark'}>
                  {loading ? loadingGraphic : stats?.ArticleStats.new_count.toLocaleString("en-US")}
                </Heading1>
                <BodyDark className={'text-xs'}>New (Last 7 days)</BodyDark>
              </div>
            </div>
            <HorizontalPercentageBar
              sections={[
                { value: stats?.ArticleStats.type_article_count, label: 'Articles' },
                { value: stats?.ArticleStats.type_video_count, label: 'Videos' },
                { value: stats?.ArticleStats.type_tweet_count, label: 'Tweets' },
                { value: stats?.ArticleStats.type_mxm_exclusive_count, label: 'MxM Exclusives' },
              ]}
            />
          </div>
          <div className={'bg-white px-6 py-7 mr-6 rounded "shadow-sm'}>
            <div className={'flex flex-row justify-between mb-6'}>
              <Heading2 className={'text-base font-bold text-black'}>Tags</Heading2>
              <Pressable clickEvent={tagsClickEvent}>
                <Icon src={leftArrowIcon} />
              </Pressable>
            </div>
            <div className={'flex flex-row justify-start'}>
              <div className={'flex flex-col mb-6 justify-start w-1/2'}>
                <Heading1 className={'text-admin-dark mb-2 text-4xl'}>
                  {loading ? loadingGraphic : stats?.TagStats.total_count.toLocaleString("en-US")}
                </Heading1>
                <BodyDark className={'text-xs'}>Total Tags</BodyDark>
              </div>
              <div className={'flex flex-col mr-6'}>
                <Heading1 className={'text-admin-dark mb-2'}>
                  {loading ? loadingGraphic : stats?.TagStats.top_trending_title}
                </Heading1>
                <BodyDark className={'text-xs'}>Top Trending Tag (Last 24 Hrs)</BodyDark>
              </div>
            </div>
            <BodyDark className={'text-l text-black'}>Top 5 Most Popular Categories (Last Week)</BodyDark>
            <Chart histogram={stats?.TagVoteHistogram}/>
          </div>
        </div>
        <div className={'flex flex-col flex-grow mr-6 w-1/3'}>
          <div className={'bg-white rounded px-6 py-7 shadow-sm flex flex-col w-full'}>
            <div className={'flex flex-row justify-between mb-6'}>
              <Heading2 className={'text-base font-bold text-black'}>Users</Heading2>
              <Pressable clickEvent={usersClickEvent}>
                <Icon src={leftArrowIcon} />
              </Pressable>
            </div>
            <div className={'flex flex-row'}>
              <div className={'flex flex-col mr-20'}>
                <Heading1 className={'text-admin-dark text-4xl'}>
                  {loading ? loadingGraphic : stats?.UserStats.total_count.toLocaleString("en-US")}
                </Heading1>
                <BodyDark className={'text-xs'}>Total Users</BodyDark>
              </div>
            </div>
            <div className={'flex flex-row mt-8'}>
              <div className={'flex flex-col mr-20'}>
                <Heading1 className={'text-admin-dark'}>
                  {loading ? loadingGraphic : stats?.UserStats.active_count.toLocaleString("en-US")}
                </Heading1>
                <BodyDark className={'text-xs'}>Verified Users</BodyDark>
              </div>
            </div>
          </div>
          <div className={'bg-white flex flex-col rounded mt-6 px-6 py-7 shadow-sm'}>
            <div className={'flex flex-row justify-between mb-6'}>
              <Heading2 className={'text-base font-bold text-black'}>Curators</Heading2>
              <Pressable clickEvent={curatorsClickEvent}>
                <Icon src={leftArrowIcon} />
              </Pressable>
            </div>
            <div className={'flex flex-row mb-5'}>
              <div className={'flex flex-col mr-20'}>
                <Heading1 className={'text-admin-dark text-4xl'}>
                  {loading ? loadingGraphic : stats?.CuratorStats.total_count.toLocaleString("en-US")}
                </Heading1>
                <BodyDark className={'text-xs'}>Total Users</BodyDark>
              </div>
            </div>
            <div className={'flex flex-row mt-8'}>
              <div className={'flex flex-col mr-20'}>
                <Heading1 className={'text-admin-dark'}>
                  {loading ? loadingGraphic : stats?.CuratorStats.article_count.toLocaleString("en-US")}
                </Heading1>
                <BodyDark className={'text-xs'}>Active Curators</BodyDark>
              </div>
            </div>
          </div>
          <div className={'flex flex-col mt-0 px-6 py-7'}>
            <div className={'flex flex-row justify-between mb-6'}>
              <Heading2 className={'text-black'}>Top Articles</Heading2>
            </div>
            {stats?.TopArticles?.map((article: any) => (
              <Pressable key={article.id} clickEvent={() => navigate(`/articles/${article.id}/preview`)}>
                <BodyDark className={'text-black'}>{article.title}</BodyDark>
              </Pressable>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

Dashboard.displayName = 'Dashboard';

export default Dashboard;
