import { memo, useEffect, useMemo } from 'react';
import { View, Zone } from '@roc-digital/mxm-base/data';
import { ClickEvent, useMethod, mapToArticleContent, ArticleAction } from '@roc-digital/ui-lib';
import { Heading1, ZoneLayout } from '@roc-digital/ui-web';
import { listArticlesByScopesV2 } from '@roc-digital/mxm-base/logic';
import { Pressable } from '@/components/elements';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const ZonePreview = memo(
  ({
    preview,
    zone,
    view,
    editZoneEvent,
    deleteZoneEvent,
  }: {
    preview?: boolean;
    zone: Zone;
    view: View;
    editZoneEvent?: ClickEvent;
    deleteZoneEvent?: ClickEvent;
  }) => {
    const articlesQuery = useMethod(listArticlesByScopesV2);
    const navigate = useNavigate();

    useEffect(() => {
      if (zone?.articles?.length) {
        articlesQuery.setResult(zone?.articles);
      } else {
        const scopes = zone?.tags?.map(z => z.scope) as string[] || [];
        articlesQuery.run(scopes, 1, 9);
      }
    }, [zone]);

    const articleItems = useMemo(() => {
      return articlesQuery.result?.map(a => mapToArticleContent(a)) || [];
    }, [articlesQuery.result]);

    const capitalize = (s: any) => {
      return s[0].toUpperCase() + s.slice(1);
    }

    const articleAction = (action: ArticleAction) => {
      if (action.action === 'open') {
        console.log(action);
        navigate(`/articles/${action.articleId}/preview`);
      } else {
        toast.info(capitalize(action.action) + ' disabled on preview.');
      }
    }

    return (
      <div className="w-full flex flex-col mb-12">

        {zone.hide_title !== true ? <>
          <div className={'w-full flex flex-row h-12 items-center justify-between mb-4'}>
            <Heading1 className={`w-1/2 line-clamp-5 text-action leading-loose !font-extrabold tracking-normal font-chivo`}>
              {zone.title}
            </Heading1>
          </div>
        </> : null}
        {articlesQuery.loading && (
          <div className={'w-full flex flex-row h-12 items-center justify-center'}>
            <p className={'text-gray-500 text-sm'}>Loading...</p>
          </div>
        )}
        {!articlesQuery.loading && !articlesQuery.result?.length && (
          <div className={'w-full flex flex-row h-12 items-center justify-center'}>
            <p className={'text-gray-500 text-sm'}>No articles found</p>
          </div>
        )}
        {!articlesQuery.loading && articlesQuery.result?.length && (
          <ZoneLayout
            layoutId={zone.web_layout === undefined ? 9 : zone.web_layout as any}
            articles={articleItems}
            onAction={articleAction}
          />
        )}
      </div>
    );
  }
);

ZonePreview.displayName = 'ZonePreview';

export default ZonePreview;
