import { Interest } from "@roc-digital/types";
import { BodyDark } from "./Typefaces";
import { Input } from "./Input";
import React from "react";
import { TagsInput } from "./TagsInput";

export interface InterestFormProps {
  value?: Interest;
  onChange?: (change: Interest) => void;
}

export function InterestForm(props: InterestFormProps) {

  const [value, _setValue] = React.useState<Partial<Interest>>(props.value || {});
  const valueRef = React.useRef(value);
  valueRef.current = value

  const setValue = React.useCallback((interest: Partial<Interest>) => {
    _setValue(current => {
      const next = { ...current, ...interest };
      valueRef.current = next;
      if (props.onChange) props.onChange(next as Interest);
      return next;
    });
  }, []);

  React.useEffect(() => {
    if (props.value && props.value != valueRef.current) {
      _setValue(props.value || {});
    }
  }, [props.value])

  return <>

    <div className="flex flex-col flex-grow gap-4 relative">
      <BodyDark className={'text-sm'}>Description*</BodyDark>
      <Input
        className={'mb-4'}
        value={value.description || ''}
        changeEvent={(event) => setValue({ description: event.target.value })}
      />

      <BodyDark className={'text-sm'}>Match*</BodyDark>
      <Input
        className={'mb-4'}
        value={value.match || ''}
        changeEvent={(event) => setValue({ match: event.target.value })}
      />

      <BodyDark className={'text-sm'}>Tags*</BodyDark>
      <TagsInput
        value={value.tags || []}
        onChange={(tags) => setValue({ tags: tags as any })}
      />
      <hr/>
      <label className="font-bold">Target URL</label>
      {process.env.REACT_APP_AUX_API_URL + '/trending?src='+value.match}
    </div>
  </>
}
