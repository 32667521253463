import { useMethod } from '@roc-digital/ui-lib';
import { readBlock } from '@/logic/blocks';
import { useParams } from 'react-router';
import { RenderUiBlock } from '@/components/elements/RenderUiBlock';

const textTokens = {
  '*': 'UNKNOWN',
  'monthlyPrice': '$9.99',
}

export function PreviewBlock() {
  
  const id = useParams()?.id || '';
  const readQuery = useMethod(readBlock, {
    autoRun: [id]
  });

  return <div className='fixed top-[0px] left-[0px] bottom-[0px] right-[0px] flex flex-col'>
    <RenderUiBlock
      block={readQuery.result}
      textTokens={textTokens}
    />
  </div>
}
