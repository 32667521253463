import { TEMPLATES, UiBlockTemplate } from './templates';
import {
  BodyDark,
  Button,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableSection,
  Modal,
  Heading1,
} from '@/components/elements';

export interface SelectTemplateModalProps {
  onSelect: (template: UiBlockTemplate) => void;
  onCancel: () => void;
}

export function openSelectTemplateModal(props: SelectTemplateModalProps) {
  let hasSelected = false;
  const onClose = () => {
    if(!hasSelected) props.onCancel();
  }
  Modal.open('mxm.modal', <SelectTemplateModal
      {...props}
      onCancel={() => {
        Modal.close('mxm.modal');
      }}
      onSelect={(template) => {
        hasSelected = true;
        Modal.close('mxm.modal');
        props.onSelect(template)
      }}
    />, 'large', false, onClose);
}

function SelectTemplateModal(props: SelectTemplateModalProps) {
  const items = TEMPLATES;

  return <div className='p-8 flex flex-col gap-4'>
    <Heading1>Select a Template</Heading1>
    <Table>
      <TableSection type="head">
        <TableRow>
          <TableHeader className={''}>Name</TableHeader>
          <TableHeader className={'fill pr-6'}>Description</TableHeader>
          <TableHeader className={'text-right pr-6'}></TableHeader>
        </TableRow>
      </TableSection>
      <TableSection>
        {items.map((item) => (
          <TableRow key={item.class}>
            <TableCell className={'flex-col'}>
              <BodyDark>{item.label ? item.label : '--'}</BodyDark>
            </TableCell>
            <TableCell className={'flex flex-col'}>
              <BodyDark>{item.description ? item.description : '--'}</BodyDark>
            </TableCell>
            <TableCell className={'text-right'}>
              <Button size='sm' clickEvent={() => props.onSelect(item)}>
                SELECT
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableSection>
    </Table>
    <div className=''>
      <Button size='sm' clickEvent={props.onCancel} variant='destructive'>
        GO BACK
      </Button>
    </div>
  </div>;
}