import { TagsInput } from './TagsInput';
import { TagSchema } from '@roc-digital/mxm-base/data';
import React, { useEffect } from 'react';

const defaultParams = { isAdmin: true, page: 1, count: 10000 };

interface UserFilters {
  tags: TagSchema[];
}

export interface UserFiltersBuilderProps {
  onChange: (filters: UserFilters) => void;
}

export function UserFilterBuilder(props: UserFiltersBuilderProps) {
  const [tags, setTags] = React.useState<TagSchema[]>([]);

  useEffect(() => {
    props.onChange({
      tags,
    })
  }, [tags])

  return <div className='flex flex-row'>
  <TagsInput
    placeholder='Find tags'
    className="flex flex-row gap-2 items-center"
    value={tags}
    onChange={setTags}
  />
</div>;
}