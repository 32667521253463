import React from "react"
import { initDnd } from "./utils/dnd";
import { initSelection } from "./utils/selection";
import { MarkerState, clearLayoutMarkers, drawContainerMarkers } from "./utils/drawContainerMarkers";

/**
 * Handles interactions inside the viewport
 * 
 */
interface ViewportProps {
  root?: HTMLElement;
  selectedElement?: HTMLElement;
  onSelectedElement?: (element: HTMLElement) => void;
  onDoubleClickElement?: (element: HTMLElement) => void;
  onInsertNewElement?: (target: HTMLElement, index: number, event: MouseEvent) => void;
  onMoveElement?: (element: HTMLElement, target: HTMLElement, index: number) => void;
}

export function Viewport(props: ViewportProps) {

  const api = React.useMemo(() => {

    let lastMouseEvent: MouseEvent | null = null;
    let selectedElement: HTMLElement | null | undefined = undefined;
    let markSelected = (element: HTMLElement) => {}

    const markerState: MarkerState = {
      childrenRects: [],
      markers: [],
      onAddClick: (event) => {
        if (selectedElement && props.onInsertNewElement) {
          const target = selectedElement;
          const index = markerState.selectedDividerIndex || 0;
          props.onInsertNewElement(target, index, event)
        }
      },
    };

    const init = (container: HTMLDivElement | null, element?: HTMLDivElement) => {
      if (!container || !element) return;
      if(container.children[0] === element) return;

      container.innerHTML = '';
      container.appendChild(element);

      initDnd(element, (event) => {
        if (props.onMoveElement) {
          props.onMoveElement(event.element, event.target, event.index);
        }
      });

      const dbClickState = {
        e: null as null | HTMLElement,
        t: 0,
      };

      const selector = initSelection(element, (event) => {
        if(props.onSelectedElement && event.element) {
          props.onSelectedElement(event.element)
        }

        const now = Date.now();

        if(now - dbClickState.t < 1001 && props.onDoubleClickElement && event.element && event.element === dbClickState.e) {
          props.onDoubleClickElement(event.element)
        }
        
        dbClickState.e = event.element;
        dbClickState.t = now;
      });

      markSelected = selector.setElement;

      element.addEventListener('mousemove', (event) => {
        lastMouseEvent = event;
        updateSelectedElementMarker();
        if (selectedElement) {
          drawContainerMarkers(selectedElement, markerState, event.x, event.y);
        }
      })
    }
    
    const setSelectedElement = (element?: HTMLElement) => {
      selectedElement = element;
      updateSelectedElementMarker();
    }

    const updateSelectedElementMarker = () => {
      if(lastMouseEvent && selectedElement) {
        drawContainerMarkers(selectedElement, markerState, lastMouseEvent.x, lastMouseEvent.y);
        markSelected(selectedElement);
      }
    }

    const destroy = () => {
      clearLayoutMarkers(markerState)
    }

    return {
      init: init,
      setSelectedElement,
      destroy 
    }
  }, []);
  
  api.setSelectedElement(props.selectedElement);

  React.useEffect(() => {
    return api.destroy
  }, []);

  return <div
  ref={(e) => api.init(e, props.root as any)}
  droppable="droppable"
  selectable="yes"
  data-element-id="container"
  data-context-area="yes"
  style={{
    padding: 100, flexDirection: 'column', gap: 100, display: 'flex',
    backgroundColor: 'white'
  }}></div>;
}
