import { ReactNode, forwardRef, useCallback, useEffect, useState } from 'react';
import { publish } from '@roc-digital/mxm-base/events';
import { Event } from '@roc-digital/mxm-base/types';
import {
  ChangeEvent,
  CustomEvent,
  InputData,
  PublisherData,
  useChangeEvent,
  useClickEvent,
  useMountedState,
  closeIcon,
  searchIcon,
} from '@roc-digital/ui-lib';
import { debounce, t } from '@roc-digital/mxm-base/logic';
import { Text, Pressable, List, Icon, Loading } from '@/components/elements';
import { Label } from '@/components/elements/Label';
import { Input } from '@/components/elements/Input';

export interface SearchResultsContainerProps {
  absolute?: boolean;
  children?: ReactNode;
}

const SearchResultsContainer = ({ absolute, children }: SearchResultsContainerProps) => {
  return (
    <div className={`${absolute ? 'absolute' : ''} top-full left-0 right-0 z-20`}>
      <div
        style={{ maxHeight: '250px' }}
        className="bg-surface rounded-b-md shadow-lg p-4 m-4 mt-0 shadow-black drop-shadow-lg overflow-auto"
      >
        {children}
      </div>
    </div>
  );
};

interface InputSearchProps extends Pick<SearchResultsContainerProps, 'absolute'> {
  id?: string;
  name?: string;
  data?: any[];
  search?: (value: string) => Promise<any[] | undefined>;
  listItem?: ({ item, index }: { item: any; index: number }) => ReactNode;
  separator?: ReactNode;
  label?: string;
  placeholder?: string;
  value?: string;
  changeValueEvent?: ChangeEvent | ((value: string) => void);
  searchEvent?: CustomEvent;
  selectedEvent?: CustomEvent;
  enterEvent?: CustomEvent;
  absolute?: boolean;
  disabled?: boolean;
  showLabel?: boolean;
  pressableClasses?: string;
  resultsContainer?: React.FC;
  showLoading?: boolean;
  hideLabel?: boolean;
  hideResults?: boolean;
}

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  (
    {
      id,
      name,
      data,
      search,
      listItem,
      separator,
      label,
      placeholder,
      value: initialValue,
      selectedEvent,
      disabled,
      changeValueEvent,
      searchEvent,
      enterEvent,
      absolute = true,
      showLabel = true,
      pressableClasses = 'px-2 py-2 hover:bg-surface-high m-1 rounded-md',
      resultsContainer,
      showLoading = true,
      hideLabel = false,
      ...props
    }: InputSearchProps,
    ref: any
  ) => {
    const [value, setValue] = useState<string>(initialValue || '');
    const [results, setResults] = useState<any[]>();
    const isMounted = useMountedState();
    const [showResults, setShowResults] = useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState(-1);
    const [isFocused, setIsFocused] = useState(false);
    const ResultsContainer = resultsContainer || SearchResultsContainer;

    const publishSearchEvent = useCallback(() => {
      if (searchEvent) {
        publish(searchEvent.namespace, 'search', {
          id,
          eventData: searchEvent.data || {},
          value: results,
        });
      }
    }, [id, results, searchEvent]);

    const publishChangeValueEvent = useCallback(() => {
      if(typeof changeValueEvent === 'function') {
        changeValueEvent(value);
        return;
      }
      if (changeValueEvent) {
        publish(changeValueEvent.namespace, 'change', {
          id,
          eventData: changeValueEvent.data || {},
          value,
        });
      }
    }, [changeValueEvent, id, value]);

    const publishSelectedEvent = useCallback(
      (data: PublisherData) => {
        if (selectedEvent) {
          publish(selectedEvent.namespace, 'selected', {
            id,
            selectedEvent,
            selectedItem: (results as any[])[parseInt(data.id || '0')],
            selectedIndex: data.id,
          });
          setShowResults(false);
        }
      },
      [id, results, selectedEvent]
    );

    const publishCleanSearchEvent = useCallback(() => {
      if(typeof changeValueEvent === 'function') {
        changeValueEvent('');
        return;
      }
      if (changeValueEvent) {
        publish(changeValueEvent.namespace, 'change', {
          id,
          eventData: changeValueEvent.data || {},
          value: '',
        });
      }
    }, [changeValueEvent, id]);

    const reset = () => {
      setValue('');
      setShowResults(false);
      setResults(undefined);
    };

    const resetSelection = () => setSelectedSuggestion(-1);

    useEffect(() => {
      setValue(initialValue || '');
    }, [initialValue]);

    useEffect(() => {
      setShowResults(results !== undefined);
    }, [results]);

    useEffect(() => {
      publishSearchEvent();
    }, [publishSearchEvent, results]);

    useEffect(() => {
      resetSelection();

      if (!value) reset();

      publishChangeValueEvent();
    }, [value, publishChangeValueEvent]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const runExternalSearch = useCallback(
      debounce(async ([search, searchValue, isMounted]) => {
        if (!search) return;

        try {
          const results = await search(searchValue);

          if (isMounted) {
            setResults(results);
            return;
          }
        } catch (err) {
          console.warn(err);
        }

        setResults([]);
      }, 300),
      []
    );

    const changeEvent = useChangeEvent(
      (event: Event<InputData>) => {
        const searchValue = event.data.value as string;

        setValue(searchValue);

        if (searchValue.length > 0) {
          setShowResults(true);
        }

        if (searchValue) {
          if (search) {
            runExternalSearch(search, searchValue, isMounted);
          } else {
            setResults(data?.filter((item: any) => (typeof item === 'string' ? item.includes(searchValue) : false)));
          }
        }
      },
      {},
      {},
      [search, isMounted, data, search]
    );

    const searchInputFocus = (evt: React.FocusEvent<HTMLInputElement, Element>) => {
      setIsFocused(true);
      if (evt.target.value.length > 0) {
        setShowResults(true);
      }
    };

    const searchInputBlur = useCallback((evt: React.FocusEvent<HTMLInputElement, Element>) => {
      if (evt.relatedTarget === null) {
        setIsFocused(false);
      }
    }, []);

    const itemClickEvent = useClickEvent(
      (event: Event<PublisherData>) => {
        if (disabled) return;

        setShowResults(false);
        setValue('');
        publishSelectedEvent(event.data);
      },
      { propagate: false, allowDefault: false },
      undefined,
      [disabled, selectedEvent, publishSelectedEvent]
    );

    const cleanSearchEvent = useClickEvent(
      () => {
        reset();
        publishCleanSearchEvent();
      },
      {},
      {},
      [publishCleanSearchEvent]
    );

    const handleKeyDown = useCallback(
      (evt: KeyboardEvent) => {
        if (!isFocused) return;

        if (evt.key === 'ArrowDown' && selectedSuggestion < (results?.length || 0) - 1) {
          evt.preventDefault();
          setSelectedSuggestion((prev) => prev + 1);
        }

        if (evt.key === 'ArrowUp' && selectedSuggestion > 0) {
          evt.preventDefault();
          setSelectedSuggestion((prev) => prev - 1);
        }

        if (evt.key === 'Enter' && selectedSuggestion >= 0) {
          evt.preventDefault();
          publishSelectedEvent({
            id: selectedSuggestion.toString(),
            eventData: {},
          });
          setShowResults(false);
        }

        if (evt.key === 'Enter' && (selectedSuggestion === -1 || !showResults)) {
          evt.preventDefault();
          if (enterEvent) {
            publish(enterEvent?.namespace, 'enter', {
              value,
            });
            setShowResults(false);
          }
          setShowResults(false);
        }
      },
      [isFocused, selectedSuggestion, results, showResults, publishSelectedEvent, enterEvent, value]
    );

    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown);

      return () => document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const renderItem = useCallback(
      ({ item, index, highlighted }: { item: any; index: number; highlighted?: number }) => {
        return (
          <Pressable
            className={`${pressableClasses} ${highlighted === index ? 'bg-surface-high' : ''}`}
            id={index.toString()}
            clickEvent={itemClickEvent}
          >
            {listItem ? listItem({ item, index }) : <Text>{item}</Text>}
          </Pressable>
        );
      },
      [itemClickEvent, listItem, pressableClasses]
    );

    return (
      <div className="flex flex-col relative w-full">
        {!hideLabel ? (
          <Label htmlFor={name} className={`${showLabel ? '' : 'sr-only'}`}>
            <Text className="mb-2">{label || 'Search value'}</Text>
          </Label>
        ) : null}
        <div className="flex flex-row items-center w-full">
          <Input
            ref={ref}
            name={name}
            className="flex-grow pr-10 w-full"
            value={value}
            placeholder={placeholder || 'Search value ...'}
            changeEvent={changeEvent}
            onFocus={searchInputFocus}
            onBlur={searchInputBlur}
            width={'full'}
            {...props}
          />
          {value ? (
            <Icon className="right-0 mr-3 -ml-8 max-w-none" src={closeIcon} clickEvent={cleanSearchEvent} />
          ) : (
            <Icon className="right-0 mr-3 -ml-8 max-w-none" src={searchIcon} />
          )}
        </div>
        {showResults && !props.hideResults && (
          <ResultsContainer absolute={absolute}>
            {results !== undefined ? (
              <List
                className="w-full"
                data={results}
                renderItem={renderItem}
                keyExtractor={(_item, index) => index.toString()}
                separator={separator || null}
                emptyMessage={t('noResults')}
                highlighted={selectedSuggestion}
              />
            ) : showLoading ? (
              <Loading circular className="p-10" />
            ) : null}
          </ResultsContainer>
        )}
      </div>
    );
  }
);

InputSearch.displayName = 'InputSearch';
