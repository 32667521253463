import { ArticleSchema } from "@roc-digital/mxm-base/data";
import { JsonFetch } from "@roc-digital/mxm-base/logic";

export async function extract(url: string): Promise<ArticleSchema> {
  // TODO: Unhardcode this env setting
  const meta = await JsonFetch<Metadata>({
    method: 'POST',
    url: `${process.env.REACT_APP_AUX_API_URL}/fullExtract`,
    data: {"url": url}
  });

  if(!meta) {
    return {} as any;
  }

  return mapMetaToArticle(meta);
}

function mapMetaToArticle(meta: Metadata) {
  const article: ArticleSchema = {} as any;

  if(meta.title) {
    article.title = meta.title;
  }

  if(meta.text) {
    article.content = meta.text;
  }

  if(meta.author) {
    article.author_name =  meta.author;
  }

  if(meta.url) {
    article.url = meta.url;
  }

  return article;
}

export declare type Metadata = {
  title?: string;
  text?: string;
  keywords?: string[];
  favicon?: string;
  author?: string;
  theme_color?: string;
  url?: string;
};
