import { PopupAdminType, PopupProps, TriggerConfig } from '@roc-digital/types/popups';
import { GenericOnePopup } from '@roc-digital/ui-web';
import { PreviewContainer } from './PreviewContainer';
import { Input, Select } from "@/components/elements";
import { FormGroup } from "@/components/elements/FormGroups";
import { SidebarConfigBox } from "@/components/elements/SidebarConfigBox";
import { useObjectSetter, inputProps } from "@/hooks/useObjectSetter";
import { PopupConfigProps, GenericOnePopupConfig } from "@roc-digital/types";
import { Option } from '@/components/elements/Select';

export const genericOnePopupType: PopupAdminType<GenericOnePopupConfig> = {
  type: 'generic-1',
  Preview: Preview,
  ConfigForm: Config,
  defaultConfig: {
    title: 'BREAKING NEWS',
    body: `Discover the latest in news and insights with MXM News, your go-to source for breaking stories and in-depth analysis. At MXM News, we prioritize accuracy, relevance, and a fresh perspective on current events, ensuring you stay informed and engaged. Whether it's global affairs, business trends, or cultural happenings, our team of seasoned journalists delivers content that matters to you. Stay ahead of the curve and experience news like never before with MXM News—where information meets innovation.`,
    trigger: {},
    actionText: 'Subscribe Today',
    actionUrl: '/subscribe',
    frequency: 1,
    position: 'bottom',
  }
}

const positionOptions: Option[] = [
  { label: 'Center', value: 'center' },
  { label: 'Bottom', value: 'bottom' },
];

const userStateOptions: Option[] = [
  { label: 'Any', value: 'any' },                                    // Will trigger in any case
  { label: 'No Account', value: 'no-session' },                      // Will trigger for visiting users
  { label: 'Signed In', value: 'signed-in' },                        // Will trigger for any signed-in user
  { label: 'Subscribed', value: 'subscribed' },                      // Will trigger for users with an active subscription
  { label: 'No Subscription', value: 'no-subscription' },            // Will trigger for users that are not subscribed and who don't have an expired/canceled subscription
  { label: 'Expired Subscription', value: 'expired-subscription' },  // Will trigger for users who have an expired or canceled subscription
];

function Config(props: PopupConfigProps<GenericOnePopupConfig>) {
  const popup = useObjectSetter(props.popup, props.onChange);
  const config = useObjectSetter(props.popup.config, (config) => popup.set('config', config));
  const triggers = useObjectSetter(config.trigger, (trigger) => config.set('trigger', trigger));

  return <>
    <SidebarConfigBox title="Content">
      <FormGroup title="Title">
        <Input {...inputProps(config, 'title')} />
      </FormGroup>
      <FormGroup title="Body">
        <Input {...inputProps(config, 'body')} />
      </FormGroup>
    </SidebarConfigBox>

    
    <SidebarConfigBox title="Action Button">
      <FormGroup title="Text">
        <Input {...inputProps(config, 'actionText')} />
      </FormGroup>
      <FormGroup title="URL">
        <Input {...inputProps(config, 'actionUrl')} />
      </FormGroup>
    </SidebarConfigBox>

    <SidebarConfigBox title="Layout">
      <FormGroup title="Position">
        <Select
          value={config.position} changeEvent={(e) => config.set('position', e.target.value as any)}
          options={positionOptions}></Select>
      </FormGroup>
    </SidebarConfigBox>

    <SidebarConfigBox title="Conditions">
      <FormGroup title="User Status">
        <Select
          value={triggers.userState} changeEvent={(e) => triggers.set('userState', e.target.value as any)}
          options={userStateOptions}></Select>
      </FormGroup>
      <FormGroup title="URL match">
        <Input {...inputProps(triggers, 'urlMatch')} />
      </FormGroup>
      <FormGroup title="Delay">
        <Input {...inputProps(triggers, 'delay')} type='number' min="1" />
      </FormGroup>
      <FormGroup title="Scroll Depth">
        <Input {...inputProps(triggers, 'scrollDepth')} type='number'  min="0"/>
      </FormGroup>
      <FormGroup title="Frequency">
        <Input {...inputProps(config, 'frequency')} type='number' min="1"/>
      </FormGroup>
    </SidebarConfigBox>
  </>
}

function Preview(props: PopupProps) {
  return <PreviewContainer>
    <GenericOnePopup popup={props.popup} isPreview />
  </PreviewContainer>
}

