import { Interest } from "@roc-digital/types";
import { Tag } from "./Tag";

export interface InterestListProps {
  interests?: Interest[];
  onOpen?: (interest: Interest) => void;
}

export function InterestList(props: InterestListProps) {
  return <div className="flex flex-col">
    {(props.interests || []).map(interest => {
      return <div
        key={interest.id}
        onClick={() => props.onOpen && props.onOpen(interest)}
        className="hover:bg-[#e9e9e9] cursor-pointer px-4 py-2 rounded-[12px]"
      >
        <div className="flex flex-row">
          <b>{interest.match}</b>
          <div className="pl-[12px]">{interest.description}</div>
        </div>
        <div className="flex flex-row flex-wrap gap-2">
          {interest.tags.map(tag => <Tag key={tag.id}>{tag.scope}</Tag>)}
        </div>
      </div>;
    })}
  </div>
}