// eslint-disable-next-line import/no-cycle
import React from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, UserCredential, signOut, getIdTokenResult } from 'firebase/auth';
import { User } from '@roc-digital/mxm-base/data';
import { publish } from '@roc-digital/mxm-base/events';
import { SessionState } from '@roc-digital/mxm-base/state';
import { getConfig } from '@roc-digital/mxm-base/config';
import { logError, logInfo, setGetTokenHook } from '@roc-digital/mxm-base/logic';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
let initPromiseRes = () => {};
let initPromise: Promise<void> | null = new Promise<void>(res => initPromiseRes = res);

auth.onAuthStateChanged(user => {
  if(user) {
    updateState({authenticated: true, loading: false})
  } else {
    updateState({authenticated: false, loading: false})
  }
  initPromise = null;
  initPromiseRes();
});

let logoutTimer = null as any;
const HOUR_24 = 1000 * 60 * 60 * 24; 

auth.onIdTokenChanged(async (user) => {
  if(user) {
    getIdTokenResult(user)
    .then((fbtoken) => {
      SessionState.setToken(fbtoken.token);
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(signOutAndReload, HOUR_24);
    })
    .catch(error => console.error(error))
  }
}, (error) => {
  console.log(error)
});

function signOutAndReload() {
  signOut(auth)
  .then(() => {
    publish('mxm.auth', 'signout');
  })
  .catch((error) => {})
  .finally(() => {
    location.reload()
  })
}

export interface OAuthState {
  authenticated: boolean;
  loading: boolean;
  authenticating?: boolean;
}

const state: OAuthState = {
  authenticated: false,
  loading: true,
}
const events = new EventTarget();

function updateState(next: Partial<OAuthState>) {
  Object.assign(state, next);
  events.dispatchEvent(new Event('next'));
}

setGetTokenHook(async () => {
  if(initPromise) {
    await initPromise;
  }

  if(!auth.currentUser) {
    return '';
  }

  const token = await auth.currentUser?.getIdToken();

  return token || '';
});

export function useAuthState() {
  const [current, setNext] = React.useState(state);
  React.useEffect(() => {
    const cb = () => {
      setNext({...state});
    };

    events.addEventListener('next', cb);

    return () => {
      events.removeEventListener('next', cb);
    }
  });

  return current;
}

async function mockSignin(sessionState: typeof SessionState, token: string, id: string, email: string): Promise<User> {
  if (token) {
    await sessionState.setToken(token);
    await sessionState.setLoggedIn(new Date());

    publish('mxm.auth', 'signin.success', {});

    return {} as User;
  }

  throw new Error('Invalid token');
}

export async function signinWithFirebase(
  sessionState: typeof SessionState,
  username: string,
  password: string
): Promise<User> {
  if (getConfig().mocking) {
    return mockSignin(sessionState, 'mock', '1', username);
  }

  updateState({authenticating: false});

  return signInWithEmailAndPassword(auth, username, password)
    .then(async (credentials: UserCredential) => {
      return mockSignin(
        sessionState,
        await credentials.user?.getIdToken(),
        credentials.user.uid,
        credentials.user.email || ''
      );
    })
    .catch((error) => {
      publish('mxm.auth', 'signin.failed', { error: new Error('Invalid username or password') });
      publish('mxm.auth', 'signout');
      throw error;
    })
    .finally(() => {
      updateState({authenticating: false});
    });
}

export function signoutWithFirebase(sessionState: typeof SessionState): Promise<void> {
  if (getConfig().mocking) {
    sessionState.clear();
    publish('mxm.auth', 'signout');
    return Promise.resolve();
  }


  return signOut(auth)
    .then(() => {
      sessionState.clear();
      publish('mxm.auth', 'signout');
    })
    .catch((error) => {
      sessionState.clear();
      publish('mxm.auth', 'signout');
      logError('signoutWithFirebase', error);
    });
}

export async function refreshTokenIfExpired(sessionState: typeof SessionState): Promise<string> {
  logInfo('[firebase.refreshTokenIfExpired]');

  if (getConfig().mocking) {
    return '';
  }

  if (!auth.currentUser) {
    return '';
  }

  const fbtoken = await getIdTokenResult(auth.currentUser);

  if (!fbtoken) {
    return '';
  }

  const currentToken = await sessionState.getToken();

  if (currentToken !== fbtoken.token) {
    logInfo('[firebase.refreshTokenIfExpired] Token has changed, updating');
    await sessionState.setToken(fbtoken.token);
    return fbtoken.token;
  }

  const expTime = new Date(fbtoken.expirationTime).getTime();

  if (expTime < Date.now() - 600) {
    logInfo('[firebase.refreshTokenIfExpired] Refreshing token');
    const fbtoken = await getIdTokenResult(auth.currentUser);

    await sessionState.setToken(fbtoken.token);
    return fbtoken.token;
  }

  return '';
}
