import { getToken } from "@roc-digital/mxm-base/logic";

export async function uploadImageToBucket(image: File): Promise<any> {
  // TODO: Unhardcode this env setting
  const formData = new FormData();
  formData.append('image', image);

  const url = process.env.REACT_APP_AUX_API_URL;

  const result = await fetch(`${url}/uploadImage`, {
    method: 'POST',
    body: formData,
    headers: {
      'Authorization': 'Bearer ' + await getToken()
    }
  });

  if(!result) {
    return {} as any;
  }

  return await result.json();
}

