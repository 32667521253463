import { memo, useEffect, useState } from 'react';
import {
  InputData,
  useChangeEvent,
  useClickEvent,
  articlesIcon,
  mxmIcon,
  calendarIcon,
  useMethod,
} from '@roc-digital/ui-lib';
import DatePicker from 'react-datepicker';
import { Event } from '@roc-digital/mxm-base/types';
import { useParams, useSearchParams } from 'react-router-dom';
import { Article, ArticleSchema, TagSchema, Tags } from '@roc-digital/mxm-base/data';
import {
  readArticle,
  searchTags,
  putArticle,
  deleteArticle,
} from '@roc-digital/mxm-base/logic';
import { useNavigate } from 'react-router';
import {
  BodyDark,
  BodyLight,
  Button,
  ButtonSizes,
  ButtonVariants,
  Heading1,
  Heading2,
  Icon,
  Input,
  TagsInput,
  Pressable,
  Select,
  TextArea,
  TextEditor,
  SpinnerBackdrop,
  Loading,
  Modal,
  AiModal
} from '@/components/elements';
import { toast } from 'react-toastify';
import { unfurl } from '@/logic/unfurl';
import ImageUploadForm from '@/components/elements/ImageUploadForm';
import { publish } from '@roc-digital/mxm-base/events';

enum ContentType {
  article = 'article',
  blog = 'blog',
  video = 'video',
  tweet = 'tweet',
  mxm_exclusive = 'mxm_exclusive',
  mxm_ai = 'mxm_ai'
}

export declare type ExtractedData = {
  title?: string;
  content?: string;
  url?: string;
};

const Edit = memo(() => {
  const [article, setArticle] = useState<Article>(() => new Article({
    status: 'published',
  } as any));

  const tagsQuery = useMethod(loadTagsAndCache, {autoRun: []})
  const tags = tagsQuery.result;

  const navigate = useNavigate();
  const [isModified, setIsModified] = useState<boolean>(false);
  const [contentType, setContentType] = useState<string>(article?.content_type || 'article');
  const [selectedTags, setSelectedTags] = useState<TagSchema[]>([]);
  const [params, setParams] = useSearchParams();
  const [embedType, setEmbedType] = useState('');

  const { id } = useParams<{ id: string }>();

  const unfurlQuery = useMethod(unfurl, {
    onSuccess: (changes) => {
      console.log(changes, Object.keys(changes));
      if(Object.keys(changes).length === 0) {
        toast.warn('Could not retrieve any details from url.');
        return;
      }

      setArticle(article => new Article({...article, ...changes}));
      validateUrlLoad(article.url);
      toast.success('Content updated from url.');
    },
    onError: (err) => {
      return toast.error('Error getting details from link. ' + err.toString());
    }
  });

  const openModal = (url?: string) => {
    Modal.open('mxm.modal', <AiModal articleUrl={url} onGenerate={onGenerate}/>, 'large');
  };

  const onGenerate = (content: string) => {
    setContent(content);
    setSelectedTags([]);
    autoTag(['publication'], 'mxm news+', false);
    console.log(tags, selectedTags);
  }

  const deleteArticleQuery = useMethod(deleteArticle, {
    onBefore: async (args: any) => {
      if(!confirm(`Are you sure you want to delete this content?`)) {
        return;
      }
      return args;
    },
    onSuccess: () => {
      toast.success('Content deleted.');
      navigate('/content')
    },
    onError: (error) => {
      console.error(error);
      toast.error('Failed to delete content!')
    }
  });

  const readArticleQuery = useMethod((id: string) => readArticle(id, {isAdmin: true}), {
    onSuccess: (article) => {
      setArticle(article);
      setContentType(article.content_type as string);
      setSelectedTags(article.tags || []);
      setIsModified(false);
      if(params.get('content') === 'mxm_ai') {
        setContentType('mxm_exclusive');
        autoTag(['publication'], 'MXM News+', false);
        openModal(article.url);
      }
    }
  });

  const putArticleQuery = useMethod(putArticle, {
    onSuccess: (article, args) => {
      readArticleQuery.run(article.id as string);
      if(args[0]?.id) {
        toast.success('Article updated successfully');
      } else {
        toast.success(`Article created successfully`);
        navigate(`/articles/${article?.id}/preview`);
      }
    },
    onError: (err, args) => {
      console.error(err);
      if(args[0]?.id) {
        toast.error('Article failed to update:' + err);
      } else {
        toast.error('Article failed to create:' + err);
      }
    }
  });

  const loading = readArticleQuery.loading || putArticleQuery.loading;

  const validateUrlLoad = (url: string) => {
    let embedId = '';
    let embedType = '';
    const isTweet = () => {
      const regex = /\b(https:\/\/x\.com|https:\/\/twitter\.com)\/\S*/i; ///(^|\s)(https:\/\/)?(x\.com|twitter\.com\/\w+\/status\/\d+)($|\s)/i;
      return regex.test(url);
    };
    const isYoutube = url?.includes('youtube.com');
    const isRumble = url?.includes('rumble.com');
    if (isTweet()) {
      const regex = /\/(\d+)(?:\?s=\d+)?$/;
      const tweetId: any = url.match(regex);
      if (!tweetId || !tweetId.length) { 
        if (confirm('You tweet includes tracking info. Trim the tracking off of your tweet url?')) {
          article.url = trimTwitterUrl(url);
          setArticle(article);
        } else {
          toast.error('Incompatible Tweet URL')
        }
      } else {
        embedId = tweetId[1];
        embedType = 'Tweet';
      }
    }
    if (isYoutube) {
      const regex = /(?:youtube\.com\/(?:embed\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const videoId: any = url.match(regex);
      embedId = videoId[1];
      embedType = 'YouTube';
    }
    if (isRumble) {
      const urlSections = url?.split('/');
      embedId = urlSections[4];
      embedType = 'Rumble';
    }
    setEmbedType(embedType ? embedType + ' with id: ' + embedId : '');
  };

  function trimTwitterUrl(url: string) {
    const regex = /\?.*$/;
    return url.replace(regex, '');
  }

  const validateArticle = (article: any) => {
    if (selectedTags.length === 0) { toast.warn('Publication not set'); }
    if (!article.url && contentType === 'article') { toast.error('Missing URL.'); return false; }
    if (!article.date) { toast.error('Missing date.'); return false; }
    if (!article.title) { toast.error('Missing title.'); return false; }
    return true;
  };

  const defaultDate = () => {
    const today = new Date();
    return Math.floor(today.getTime() / 1000);
  }

  const saveArticle = () => {
    if(article) {
      if (!article.date) { article.date = defaultDate() }
      if (!validateArticle(article)) { 
        console.log('Incomplete article', article);
        return;
      }
      putArticleQuery.run({
        ...article.toParams(),
        content_type: contentType as any,
        tags: selectedTags,
      });
    }
  }

  const setArticleProperties = (updates?: Partial<ArticleSchema>) => {
    setArticle(new Article({ ...article, ...updates}));
    setIsModified(true);
  }

  const setTags = (tags: TagSchema[]) => {
    setIsModified(true);
    setSelectedTags(tags);
  }

  const setContent = (content: string) => {
    setArticle((article) => {
      return new Article({ ...article, content});
    });
    setIsModified(true);
  }

  useEffect(() => {
    if (id !== 'new') {
      readArticleQuery.run(id as string);
    }

  }, [id]);

  const handleArticleClick = useClickEvent(() => {
    setContentType('article');
    setIsModified(true);
    setArticle((article) => new Article({ ...article, content_type: ContentType.article }));
  });

  const handleMxmClick = useClickEvent(() => {
    setContentType('mxm_exclusive');
    setIsModified(true);
    autoTag(['publication'], 'MXM News+', false);
    setArticle((article) => new Article({ ...article, content_type: ContentType.mxm_exclusive }));
  });

  const handleCancelClick = useClickEvent(() => {
    navigate('/content');
  });

  const autoTag = (types: any = [], value?: string, notify: boolean = true) => {
    const tempTags: TagSchema[] = [];
    let query = value?.toLocaleLowerCase();

    tags?.forEach((tag) => {
      const tagName = tag?.name?.toLowerCase() || '';
      const tagType = tag?.type?.toLocaleLowerCase() || '';
       
      if (tagName !== 'home' && tagType !== 'flag') { // don't auto tag as home tag
        if (types?.length === 0 || types.length > 0 && (types.indexOf(tagType) > -1)) {
          if (tag.name) {
            if (query) {
              if (tagName.search(query) > -1) {
                tempTags.push(tag);
              }
            } else {
              if (article.title.toLowerCase().search(tagName) > -1 && ignorePublicationsOnExcusive(tagType)
                || article.url && article.url.toLowerCase().search(tagName) > -1 && ignorePublicationsOnExcusive(tagType)
                || (article.content && article.content.toLowerCase().search(' ' + tagName + ' ') > -1 && (tagType === 'topic' || tagType === 'category'))
              ) {
                if (returnDuplicates(tag).length === 0) {
                  tempTags.push(tag);
                }
              }
            }
          }
        }
      }
    });
    setSelectedTags(selectedTags.concat(tempTags));
    if (notify) {
      if (tempTags.length > 0) {
        toast.success(`Found ${tempTags.length} tags. Please review the tags to assure accuracy.`);
      } else {
        toast.warning('Unable to find any auto tags. Tags will have to be added manually.');
      }
    }
  }

  const ignorePublicationsOnExcusive = (tagType: string) => {
    if (contentType === 'mxm_exclusive' && (tagType === 'publication' || tagType === 'author')) {
      return false;
    } else {
      return true;
    }
  }

  const returnDuplicates = (newTag: TagSchema) => {
    return selectedTags.filter(function (tag) {
      return (tag.name === newTag.name);
    });
  };

  const changeEvent = useChangeEvent(
    ({ data }: Event<InputData>) => {
      setIsModified(true);
      setArticle(new Article({ ...article, [data.id as string]: data.value as string }));
    },
    {},
    {},
    [article, isModified]
  );

  const saveUrl = (data: any) => {
    publish<InputData>(changeEvent?.namespace || '', 'change', {
      id: 'image_url',
      value: data.publicPath,
      eventData: null as any
    });
  }

  return (
    <div className="flex flex-col w-4/5">
      <div className="flex justify-between h-20 mt-3 mr-10">
        <Heading1 className={'text-2xl text-black capitalize'}>
          {`${article?.id ? 'Edit' : 'Add'} ${contentType.replace('_', ' ')}`}
        </Heading1>
        <div className={'flex flex-row justify-end w-1/6 gap-3'}>
          <Button
            size={ButtonSizes.medium}
            clickEvent={handleCancelClick}
            variant={ButtonVariants.secondary}
            disabled={loading as boolean}
          >
            <BodyLight className={'font-light'}>Cancel</BodyLight>
          </Button>
          <Button
            disabled={!article?.id || loading as boolean}
            size={ButtonSizes.medium}
            clickEvent={() => navigate(`/articles/${article.id}/preview`)}
            variant={ButtonVariants.secondary}
          >
            <BodyLight className={'font-light'}>Preview</BodyLight>
          </Button>
          <Button
            size={ButtonSizes.medium}
            clickEvent={saveArticle}
            disabled={loading || !isModified}
            variant={isModified || loading ? ButtonVariants.primary : ButtonVariants.disabled}
          >
            <BodyLight className={`${isModified && 'text-white'} font-light`}>Save</BodyLight>
          </Button>
        </div>
      </div>
      <div className="flex flex-row flex-grow-0">
        <div className="bg-white p-10 mr-6 w-2/3 relative">
          <div className="flex flex-row my-4 justify-items-start gap-3">
            <Pressable
              clickEvent={handleArticleClick}
              className={`flex flex-col justify-center items-center border h-16 rounded w-1/2 ${
                contentType === 'article' ? 'border-cyan-400 shadow-md bg-gray-100' : 'border-gray-300'
              }`}
            >
              <Icon src={articlesIcon} size={'medium'} fill={'none'} stroke={'#919193'} />
              <BodyLight size={'tiny'}>Article</BodyLight>
            </Pressable>
            <Pressable
              clickEvent={handleMxmClick}
              className={`flex flex-col justify-center items-center border h-16 rounded w-1/2 ${
                contentType === 'mxm_exclusive' ? 'border-cyan-400 shadow-md bg-gray-100' : 'border-gray-300'
              }`}
            >
              <Icon src={mxmIcon} size={'medium'} fill={'none'} stroke={'#919193'} />
              <BodyLight size={'tiny'}>MxM Exclusive</BodyLight>
            </Pressable>
          </div>
          <div className="mt-8 flex flex-col">
            <Heading2 className={'mb-6 text-admin-dark'}>{contentType=== 'mxm_ai' ? 'Extracted AI Inputs' : 'General'}</Heading2>
            <BodyDark>{contentType === 'mxm_exclusive' ? 'URL (article, youtube, rumble or tweet)' : 'URL (article)'}*</BodyDark>
            <div className='flex flex-row gap-2 items-center'>
              <Input id="url" changeEvent={changeEvent} placeholder={'Paste url'} value={article?.url} className='flex-grow' />
              <Button
                    disabled={!article?.url || unfurlQuery.loading}
                    clickEvent={() => unfurlQuery.run(article.url || '')}
                    size={ButtonSizes.medium}
                    loading={unfurlQuery.loading}
                  >
                Load
              </Button>
            </div>
            <div className='text-xs italic text-gray-600 text-center'>{embedType}</div>

            <BodyDark className={'mt-4 text-sm'}>Title*</BodyDark>
            <Input id="title" changeEvent={changeEvent} placeholder={'Title'} value={article?.title} />
            <BodyDark className={'mt-4 text-sm'}>{contentType === 'mxm_exclusive' || contentType === 'mxm_ai' ? 'Article Content' : 'Description'}*</BodyDark>
            {contentType !== 'mxm_exclusive' ? <TextArea className="h-[140px]" id={'content'} changeEvent={changeEvent} value={article?.content || ''}/> : null}
            {contentType === 'mxm_exclusive' ? <TextEditor onChange={setContent} value={article?.content || ''}/> : null}
            
            <Heading2 className={'text-admin-dark mt-4 '}>About</Heading2>
            <BodyDark className={'mt-4 text-sm'}>Tags*</BodyDark>
            <div className='flex flex-row gap-2 items-top'>
              <div className="w-full">
                <TagsInput
                  value={selectedTags}
                  onChange={setTags}
                />
              </div>
              <div className="mt-1 w-[140px]">
                <Button
                  disabled={!article?.title || unfurlQuery.loading}
                  clickEvent={() => autoTag()}
                  size={ButtonSizes.medium}
                >
                  Auto <span className="hidden-xs">Tag</span>
                </Button>
              </div>
            </div>
            <BodyDark className={'mt-4 text-sm'}>Date*</BodyDark>
            <div
              className={
                'border border-slate-300 text-sm bg-transparent flex flex-row rounded gap-2 px-2 py-1 items-center w-[200px]'
              }
            >
              <DatePicker
                selected={article?.date ? new Date(article?.date * 1000) : new Date()}
                onChange={(date: Date) => setArticleProperties({date: Math.floor(date.getTime() / 1000)})}
                placeholderText={'Select'}
              />
              <Icon src={calendarIcon} size={'small'} fill={'none'} stroke={'#919193'}/>
            </div>
          </div>
          <SpinnerBackdrop active={loading}><Loading type='critical' size='large'/></SpinnerBackdrop>
        </div>
        <div className={'flex flex-col mr-10 w-1/3 flex-grow-0'}>
          <div className={'bg-white px-6 py-7 mb-6 flex flex-col justify-between rounded shadow-sm relative'}>
            <Heading2 className={'mb-4 text-admin-dark'}>AI Generation</Heading2>
            <Button
              size={ButtonSizes.medium}
              clickEvent={() => openModal(article.url)}
              variant={ButtonVariants.secondary}
              disabled={loading as boolean}
            >
              <BodyLight className={'font-light'}>Generate AI Content</BodyLight>
            </Button>
          </div>

          <div className={'bg-white px-6 pb-7 mb-6 flex flex-col justify-between rounded shadow-sm relative'}>
            <Heading2 className={'mb-4 mt-3 text-admin-dark '}>Image</Heading2>
            <BodyDark className={'text-sm'}>Image URL{contentType === 'article' && '*'}</BodyDark>
            <Input
              className={'text-s'}
              placeholder={'Paste URL'}
              id="image_url"
              changeEvent={changeEvent}
              value={article?.image_url}
            />
            <ImageUploadForm returnUrl={saveUrl}/>
            <SpinnerBackdrop active={loading}/>
          </div>

          <div className={'bg-white px-6 py-7 mb-6 flex flex-col justify-between rounded shadow-sm relative'}>
            <Heading2 className={'mb-4 text-admin-dark'}>Settings</Heading2>
            <div><small>Status</small></div>
            <Select
              className={'mb-4'}
              changeEvent={changeEvent}
              size="x-small"
              id={'status'}
              value={article?.status || 'published'}
              options={[
                { label: 'Published', value: 'published' },
                { label: 'Draft', value: 'drafted' },
              ]}
            ></Select>
            <div><small>Paywall Setting</small></div>
            <Select
              className={'mb-4'}
              changeEvent={changeEvent}
              size="x-small"
              id={'paywall'}
              value={article?.paywall || 'free'}
              options={[
                { label: 'Free', value: 'free' },
                { label: 'Paid', value: 'paid' },
                { label: 'Logged In', value: 'authorized' },
              ]}
            ></Select>

            <div><small>Publish Date</small></div>

            <div
              className={
                'flex flex-row gap-2 items-center'
              }
            >
              <DatePicker
                showTimeInput
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                selected={article?.scheduled_date ? new Date(article?.scheduled_date * 1000) : undefined}
                onChange={(date: Date) => setArticleProperties({scheduled_date: Math.floor(date.getTime() / 1000)})}
                placeholderText={'Select'}
                disabled={article?.status !== 'drafted'}
                className='border border-slate-300 text-sm bg-transparent flex flex-row rounded gap-2 px-2 py-1 items-center w-[240px]'
              />

              {article?.scheduled_date ? <>
                <Button
                  size='sm'
                  disabled={!article?.scheduled_date || article.status !== 'drafted'}
                  clickEvent={() => setArticleProperties({scheduled_date: 0})}>Clear</Button>
              </> : null}
            </div>

            <SpinnerBackdrop active={loading}/>
          </div>

          <div className={'bg-white px-6 py-7 mb-6 flex flex-col justify-between rounded shadow-sm relative'}>
            <Heading2 className={'mb-4 text-admin-dark '}>Source Information</Heading2>
            <BodyDark className={'text-sm'}>Publication Name *</BodyDark>
            <div className="border px-3 py-1 bg-gray-100 my-2 rounded-md">
              {selectedTags?.find((tag) => tag.type === 'publication')?.name || 'Not Set - Add a Publication Tag'}
            </div>
            <label htmlFor="author_name"><BodyDark className={'text-sm'}>Author Name</BodyDark></label>
            <Input id={'author_name'} value={article?.author_name } changeEvent={changeEvent} placeholder={'author (optional)'} title={'Optional name of the author'}/>
            <SpinnerBackdrop active={loading}/>
          </div>
        </div>
      </div>
      {article?.id && !loading ? <>
        <div className={'flex flex-col justify-between p-4 gap-4'}>
        <Heading2 className={'!text-2xl text-admin-dark'}>Danger Zone</Heading2>
        <div className="flex-row">
          <Button
            size={ButtonSizes.medium}
            clickEvent={() => deleteArticleQuery.run(article?.id as string, {isAdmin: true})}
            variant={ButtonVariants.destructive}
            disabled={loading as boolean}
          >
            Delete Content
          </Button>
        </div>
      </div>      
      </> : <></>}
    </div>
  );
});

Edit.displayName = 'Edit';

export default Edit;


let _tags: Tags | null = null;
async function loadTagsAndCache() {
  if(_tags) return _tags;

  _tags = await searchTags('', { isAdmin: true, page: 1, count: 10000, exclude_types:'author' })

  return _tags;
}