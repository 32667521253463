import { Heading2 } from '@/components/elements';

export  interface DashboardCardProps {
  title?: any;
  header?: any;
  children: any;

}

export function DashboardCard(props: DashboardCardProps) {
  return <div className={'bg-white px-6 py-7 gap-6 flex flex-col justify-between rounded "shadow-sm"'}>
    <div className={'flex flex-row justify-between mb-6'}>
      {props.title ? <Heading2 className={'text-base font-bold text-black'}>{props.title}</Heading2> : null}
      {props.header}
    </div>
    {props.children}
  </div>
}

export function DashboardGraphCard(props: DashboardCardProps) {
  return <div className={'bg-white flex flex-col justify-between rounded "shadow-sm"'}>
    <div className={'flex flex-row justify-between'} style={{padding: '24px 24px 0px 24px'}}>
      {props.title ? <Heading2 className={'text-base font-bold text-black'}>{props.title}</Heading2> : null}
      {props.header}
    </div>
    <div style={{paddingLeft: '8px'}}>
      {props.children}
    </div>
  </div>
}