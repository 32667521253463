import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router';

import {
  useCustomEvent,
  useMountedState,
  useClickEvent,
  PublisherData,
  accountFillBrandIcon,
  mxmIcon,
} from '@roc-digital/ui-lib';
import React, { useEffect, useState } from 'react';
import { Event } from '@roc-digital/mxm-base/types';
import { User } from '@roc-digital/mxm-base/data';
import { SessionState } from '@roc-digital/mxm-base/state';
import { notifyError } from '@roc-digital/mxm-base/logic';
import { refreshTokenIfExpired, signoutWithFirebase, useAuthState } from '@/logic';
import { BodyDark, Icon, Modal, Notifications, Pressable, AdminMenu, SpinnerBackdrop, Loading } from '@/components/elements';
import AppContainer from './elements/AppContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Content from '@/components/views/Content';
import Tags from '@/components/views/Tags';
import Users from '@/components/views/Users';
import Views from '@/components/views/Views';
import Dashboard from '@/components/views/Dashboard';
import UserDetails from '@/components/views/Users/UserDetails';
import Login from '@/components/views/Login';
import Edit from '@/components/views/Content/Edit';
import PreviewView from '@/components/views/Views/PreviewView';
import { ViewArticle } from '@/components/views/Content/ViewArticle';
import { EditTag } from '@/components/views/Tags/EditTag';
import { EditViewNew } from '@/components/views/Views/EditViewNew';
import Dashboard2 from './views/Dashboard-2/Dashboard';
import { EditInterest, SettingsView } from './views/Settings';
import { EditPopup, ListPopups } from './views/Popups';
import EditBlock from './views/Blocks/EditBlock';
import { PreviewBlock } from './views/Blocks/PreviewBlock';
import { Blocks } from './views/Blocks/Blocks';
import { Promotionals } from './views/Promotionals/Promotionals'
import { EditPromotional } from './views/Promotionals/EditPromotional';

export const Admin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuthState();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [active, setActive] = React.useState<string>('content');
  const handleMenuClick = useClickEvent(({ data }: Event<PublisherData>) => {
    navigate(data?.id || ('/' as string));
    setActive(data?.id?.includes('users') ? 'users' : data?.id || 'content');
  });

  const handleAccountClick = useClickEvent(async () => {
    await signoutWithFirebase(SessionState);
  });

  const isMounted = useMountedState();

  const isFullScreenPage = location.pathname.startsWith('/blocks/');

  useEffect(() => {
    const path = location.pathname.replace('/', '');
    const intervals = setInterval(() => refreshTokenIfExpired(SessionState), 1000 * 60 * 5);

    if (path.includes('users')) {
      setActive('users')
    } else if (path.includes('article') || path.includes('content')) {
      setActive('content')
    } else if (path.includes('view')) {
      setActive('views')
    } else if (path.includes('dashboard')) {
      setActive('dashboard')
    } else if (path.includes('tags')) {
      setActive('tags')
    } else if (path.includes('settings')) {
      setActive('settings')
    } else if (path.includes('popups')) {
      setActive('popups')
    } else if (path.includes('blocks')) {
      setActive('blocks')
    } else if (path.includes('promotionals')) {
      setActive('promotionals')
    } else {
      setActive('');
    }

    return () => {
      clearInterval(intervals);
    };
  }, [isMounted, location, navigate]);

  useCustomEvent(
    'listArticles.failed',
    ({ data }: Event<any>) => {
      notifyError('Problem with session' + data?.e);
    },
    { namespace: 'mxm.http.articles' }
  );

  useCustomEvent(
    'listTags.failed',
    ({ data }: Event<any>) => {
      notifyError('Problem with session' + data?.e);
    },
    { namespace: 'mxm.http.tags' }
  );

  useCustomEvent(
    'readArticle.failed',
    ({ data }: Event<any>) => {
      notifyError('Problem with session' + data?.e);

    },
    { namespace: 'mxm.http.articles' }
  );

  useCustomEvent(
    'readTag.failed',
    ({ data }: Event<any>) => {
      notifyError('Problem with session' + data?.e);
    },
    { namespace: 'mxm.http.tags' }
  );

  useCustomEvent(
    'readAdminUser.failed',
    ({ data }: Event<any>) => {
      notifyError('Problem with session' + data?.e);
    },
    { namespace: 'mxm.http.adminUsers' }
  );

  useCustomEvent(
    'listAdminUsers.failed',
    ({ data }: Event<any>) => {
      notifyError('Problem with session' + data?.e);
    },
    { namespace: 'mxm.http.adminUsers' }
  );

  useCustomEvent(
    'signin.success',
    async () => {
      setUser(await SessionState.getUser());
      navigate('/dashboard');
    },
    { namespace: 'mxm.auth' }
  );

  useCustomEvent(
    'signout',
    () => {
      if (user) {
        SessionState.clear();
      }
      setUser(undefined);
      navigate('/login');
    },
    { namespace: 'mxm.auth' }
  );

  return (
    <div className={"min-h-screen flex " + (isFullScreenPage ?  '' : 'mt-7')}>
      {isFullScreenPage === false ? <>
        <div className="flex flex-col w-[250px]">
          <div className="h-20 ml-6 w-5/6 mt-1">
            <span className="fixed mt-1">
              <span className="flex items-center">
                <Icon src={mxmIcon} size="large" />
                <span className="!font-extrabold !text-[20px] !leading-9 !tracking-tight !font-chivo ml-2 hidden-850">MxM News</span>
              </span>
            </span>
          </div>
          <div className="flex-grow flex flex-col w-full h-full justify-between">
            {auth.authenticated && <AdminMenu clickEvent={handleMenuClick} active={active} />}
            <Pressable className={'fixed bottom-0 left-0 mb-4 ml-4'} clickEvent={handleAccountClick}>
              <Icon src={accountFillBrandIcon} size="small" />
              <BodyDark className="ml-2">{auth.authenticated ? 'Logout' : 'Login'}</BodyDark>
            </Pressable>
          </div>
        </div>
      </> : <></>}
      <AppContainer>
        {auth.loading ? <>
          <SpinnerBackdrop active={auth.loading}>
            <Loading />
          </SpinnerBackdrop>
        </> : <>
          <Routes>
            {auth.authenticated ? <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard-1" element={<Dashboard2 />} />
              <Route path="/content" element={<Content />} />
              <Route path="/articles/:id" element={<Edit />} />
              <Route path="/articles/:id/preview" element={<ViewArticle />} />
              <Route path="/blocks/:id" element={<EditBlock />} />
              <Route path="/blocks/:id/preview" element={<PreviewBlock />} />
              <Route path="/blocks" element={<Blocks />} />
              <Route path="/tags" element={<Tags />} />
              <Route path="/tags/:id" element={<EditTag />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/:id" element={<UserDetails />} />
              <Route path="/views" element={<Views />} />
              <Route path="/views/:id" element={<EditViewNew />} />
              <Route path="/views_preview/:id" element={<PreviewView />} />
              <Route path="/settings" element={<SettingsView />} />
              <Route path="/settings/interests" element={<EditInterest />} />
              <Route path="/settings/interests/:id" element={<EditInterest />} />
              <Route path="/popups" element={<ListPopups />} />
              <Route path="/popups/:id" element={<EditPopup />} />
              <Route path="/promotionals" element={<Promotionals />} />
              <Route path="/promotionals/:id" element={<EditPromotional />} />
              <Route path="/login" element={<Navigate to="/dashboard" replace={true} />} />
              <Route path="/" element={<Navigate to="/dashboard" replace={true} />} />
            </> : <>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Navigate to="/login" replace={true} />} />
              <Route path="*" element={<Navigate to="/login" replace={true} />} />
            </>}
          </Routes>
        </>}
      </AppContainer>
      <Modal id="mxm.modal" />
      <Notifications />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};
