import React, { useState } from 'react';
import { publish } from '@roc-digital/mxm-base/events';
import { Pagination as Paginator } from '@roc-digital/mxm-base/data';

import {
  PaginateEvent,
  CustomEvent,
  PaginationData,
  getClassNames,
  paginationChevronRight,
  paginationChevronLeft,
  useChangeEvent,
  InputData,
} from '@roc-digital/ui-lib';
import { Event } from '@roc-digital/mxm-base/dist/events';
import { Select } from '@/components/elements/Select';
type Props = {
  id?: string;
  className?: string;
  paginator: Paginator;
  paginateEvent: PaginateEvent | CustomEvent;
  infinite?: boolean;
};

const paginationClasses = 'w-full';
const pageButtonClasses = `border-edge flex items-center border-1 border-solid rounded-md cursor-default hover:border-edge-high`;

const prevButtonClasses = {
  base: `${pageButtonClasses} w-9 h-9 justify-center`,
  enabled: `border-edge bg-none cursor-pointer hover:border-edge-high`,
};
const prevImgButtonClasses = `w-auto`;

const nextButtonClasses = {
  base: `${pageButtonClasses} w-9 h-9 justify-center`,
  enabled: `border-edge bg-none cursor-pointer hover:border-edge-high`,
};
const nextImgButtonClasses = `w-auto cursor-pointer`;

const statsClasses = `text-font-low float-right pt-2 text-sm`;

export const Pagination = ({ id, className, paginator, paginateEvent, infinite }: Props) => {
  const publishPaginatePrev = () => {
    if (paginator.page === 1) {
      return;
    }

    if(infinite) {
      publish<PaginationData>(paginateEvent.namespace, 'paginate', {
        id,
        eventData: paginateEvent.data || {},
        page: paginator.page - 1,
        count: paginator.count,
      });
      return;
    }

    publish<PaginationData>(paginateEvent.namespace, 'paginate', {
      id,
      eventData: paginateEvent.data || {},
      page: paginator.prev(),
      count: paginator.count,
    });
  };

  const publishPaginate = (page: number) => {
    publish<PaginationData>(paginateEvent.namespace, 'paginate', {
      id,
      eventData: paginateEvent.data || {},
      page: page,
      count: paginator.count,
    });
  };

  const publishPaginateNext = () => {
    if (paginator.page === paginator.pages) {
      return;
    }

    if(infinite) {
      publish<PaginationData>(paginateEvent.namespace, 'paginate', {
        id,
        eventData: paginateEvent.data || {},
        page: paginator.page + 1,
        count: paginator.count,
      });
      return;
    }

    publish<PaginationData>(paginateEvent.namespace, 'paginate', {
      id,
      eventData: paginateEvent.data || {},
      page: paginator.next(),
      count: paginator.count,
    });
  };

  const { page, pages } = paginator;

  const prevClassName = getClassNames(prevButtonClasses, { enabled: page > 1 });
  const nextClassName = getClassNames(nextButtonClasses, { enabled: pages > page });

  className = className ? `${paginationClasses} ${className}` : paginationClasses;

  const changeEvent = useChangeEvent(
    ({ data }: Event<InputData>) => {
      publishPaginate(data.value as number);
    },
    {},
    {},
    []
  );

  let countText = `Viewing ${paginator.page} of ${!infinite ? paginator.pages : 'many'}`;

  if(infinite && paginator.current < paginator.count) {
    countText = `Viewing ${paginator.page} of ${paginator.page}`;
  }

  return (
    <div className={className}>
      <div className="flex justify-between">
        { !infinite ? <>
          <Select
            id={'page-select'}
            changeEvent={changeEvent}
            className={'w-48 bg-white'}
            value={paginator.page}
            size="x-small"
            options={Array.from(Array(paginator.pages).keys()).map((i) => ({
              value: `${i + 1}`,
              label: `View page: ${i + 1}`,
            }))}
          />
        </> : null}
        <span className={'flex flex-row'}>
          <span className={prevClassName} onClick={publishPaginatePrev}>
            <img className={prevImgButtonClasses} src={paginationChevronLeft} title="Previous page" />
          </span>
          <div className={statsClasses}>
            {countText}
          </div>
          <span className={nextClassName} onClick={publishPaginateNext}>
            <img className={nextImgButtonClasses} src={paginationChevronRight} title="Next page"/>
          </span>
        </span>
      </div>
    </div>
  );
};
