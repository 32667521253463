import { ArticleSchema } from "@roc-digital/mxm-base/data";
import { JsonFetch } from "@roc-digital/mxm-base/logic";

export async function unfurl(url: string): Promise<ArticleSchema> {
  // TODO: Unhardcode this env setting
  const meta = await JsonFetch<Metadata>({
    method: 'POST',
    url: `${process.env.REACT_APP_AUX_API_URL}/unfurl`,
    data: {url}
  });

  if(!meta) {
    return {} as any;
  }

  return mapMetaToArticle(meta, url);
}

const detectMedia = (url: string) => {
  if (url.includes('twitter.com') ||
      url.includes('https://x.com') ||
      url.includes('youtube.com') ||
      url.includes('rumble.com')) {
    return true;
  } else {
    return false;
  }
};

function mapMetaToArticle(meta: Metadata, url: string) {
  const article: ArticleSchema = {} as any;

  if(meta.title && !detectMedia(url)) {
    article.title = meta.title;
  }

  if(meta.description && !detectMedia(url)) {
    article.content = meta.description;
  }

  if(meta.open_graph?.images?.[0].url) {
    article.image_url = meta.open_graph?.images?.[0].url;
  } else if(meta.twitter_card?.images?.[0].url) {
    article.image_url = meta.twitter_card?.images?.[0].url;
  } else if(meta.oEmbed?.thumbnails?.[0]?.url) {
    article.image_url = meta.oEmbed?.thumbnails?.[0]?.url;
  } 

  if(meta.author) {
    article.author_name =  meta.author;
  } else if(meta?.twitter_card?.creator) {
    article.author_name =  meta.twitter_card.creator;
  } else if(meta?.open_graph?.article?.author) {
    article.author_name =  meta?.open_graph?.article?.author;
  }

  return article;
}

export declare type Metadata = {
  title?: string;
  description?: string;
  keywords?: string[];
  favicon?: string;
  author?: string;
  theme_color?: string;
  canonical_url?: string;
  oEmbed?: {
      type: "photo" | "video" | "link" | "rich";
      width?: number;
      height?: number;
      version?: string;
      title?: string;
      author_name?: string;
      author_url?: string;
      provider_name?: string;
      provider_url?: string;
      cache_age?: number;
      thumbnails?: [
          {
              url?: string;
              width?: number;
              height?: number;
          }
      ];
  };
  twitter_card: {
      card: string;
      site?: string;
      creator?: string;
      creator_id?: string;
      title?: string;
      description?: string;
      players?: {
          url: string;
          stream?: string;
          height?: number;
          width?: number;
      }[];
      apps: {
          iphone: {
              id: string;
              name: string;
              url: string;
          };
          ipad: {
              id: string;
              name: string;
              url: string;
          };
          googleplay: {
              id: string;
              name: string;
              url: string;
          };
      };
      images: {
          url: string;
          alt: string;
      }[];
  };
  open_graph: {
      title: string;
      type: string;
      images?: {
          url: string;
          secure_url?: string;
          type: string;
          width: number;
          height: number;
          alt?: string;
      }[];
      url?: string;
      audio?: {
          url: string;
          secure_url?: string;
          type: string;
      }[];
      description?: string;
      determiner?: string;
      site_name?: string;
      locale: string;
      locale_alt: string;
      videos: {
          url: string;
          stream?: string;
          height?: number;
          width?: number;
          tags?: string[];
      }[];
      article: {
          published_time?: string;
          modified_time?: string;
          expiration_time?: string;
          author?: string;
          section?: string;
          tags?: string[];
      };
  };
};
