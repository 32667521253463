import React from "react"
import { ElementStarter, Elements } from './elements';
import { useDropdown, DropdownItems, DropdownItem } from "./components/Dropdown";
import { RuiEditorApi } from "./RuiApi";
import { useValueRef } from "@/hooks/useValueRef";

interface ContextMenuProps {
  canPaste?: boolean;
  hasElement?: boolean;
  onAction: (action: 'copy' | 'paste' | 'delete' | 'clone' | 'selectParent') => void
}

export function useContextMenu(props: ContextMenuProps) {
  const propsRef = useValueRef(props);

  const contextItems = React.useMemo<DropdownItems>(() => {
    return [
      {id: 'copy', label: 'Copy', disabled: !props.hasElement},
      {id: 'paste', label: 'Paste', disabled: !props.canPaste || !props.hasElement},
      {id: 'clone', label: 'Clone', disabled: !props.hasElement},
      {id: 'selectParent', label: 'Select Parent', disabled: !props.hasElement},
      '-',
      {id: 'delete', label: 'Delete', disabled: !props.hasElement},
    ];
  }, [props.canPaste, props.hasElement]);


  const dropdown = useDropdown({
    items: contextItems,
    onActivateItem: (itemId: string) => {
      propsRef.current.onAction(itemId as any);
    }
  });

  React.useEffect(() => {
    const onRightClick = (event: MouseEvent) => {
      if (!isInContextArea(event.target as HTMLElement)) return;

      event.preventDefault();
      
      dropdown.open(event)
    };

    window.addEventListener('contextmenu', onRightClick);
    return () => {
      window.removeEventListener('contextmenu', onRightClick);
    }
  }, []);

  return {
    jsx: dropdown.jsx,
    open,
    close: dropdown.close,
  };
}

function isInContextArea(element: HTMLElement | null): boolean {
  if(!element) return false;
  if(element.dataset['contextArea']) {
    return true;
  }
  return isInContextArea(element.parentElement);
}