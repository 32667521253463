import React from 'react';
import {
  BodyLight,
  Button,
  ButtonSizes,
  ButtonVariants,
  Heading1,
  Heading2,
  Loading,
  openNotifyModal
} from '@/components/elements';
import { readArticle } from '@roc-digital/mxm-base/logic';
import { useMethod, mapToArticleContent } from '@roc-digital/ui-lib';
import { useNavigate, useParams } from 'react-router-dom';
import { ArticleView } from '@roc-digital/ui-web';
import { toast } from 'react-toastify';
import { ActivityLogView } from '@/components/elements/ActivityLogView';

export function ViewArticle() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const readArticleQuery = useMethod(readArticle);

  const liveURL = process.env.REACT_APP_AUX_API_URL + '/article/';

  React.useEffect(() => {
    if(!id) return;
    readArticleQuery.run(id);
  }, [id]);

  const articles = React.useMemo(() => {
    if(readArticleQuery.result) {
      return [mapToArticleContent(readArticleQuery.result.toParams(), {}, true)];
    } else {
      return []
    }
  }, [readArticleQuery.result]);

  const capitalize = (s: any) => {
    return s[0].toUpperCase() + s.slice(1);
  }

  const articleAction = (action: any) => {
    if (action.action === 'open') {
      window.open(liveURL + articles[0].id, '_blank');
    } else {
      toast.info(capitalize(action.action) + ' disabled on preview.');
    }
  }

  return <>
    <div className="flex flex-col w-4/5 ">
      <div className="flex h-16 mr-6 mt-3 align-middle gap-4 ">
        <Heading1 className="text-2xl text-black flex-grow">Content Preview</Heading1>
        <Button size={ButtonSizes.medium} variant={ButtonVariants.secondary} clickEvent={() => {
          openNotifyModal({
            articleId: id || ''
          });
        }}>
          Send Notification
        </Button>
        <a href={liveURL + id} target={'BLANK'} title={'View article'}>
          <Button size={ButtonSizes.medium} variant={ButtonVariants.secondary}>
            Live Preview
          </Button>
        </a>
        <a href={readArticleQuery.result?.url} target={'BLANK'} title={'View article'}>
          <Button size={ButtonSizes.medium} variant={ButtonVariants.secondary}>
            Source
          </Button>
        </a>
        <Button size={ButtonSizes.medium} clickEvent={() => navigate(`/articles/${id}`)}>
          <BodyLight className={'text-white'}>Edit</BodyLight>
        </Button>
        
      </div>

      <div className="flex flex-col flex-grow p-10 mr-6 rounded gap-8 bg-gray-200 p-5">

        {readArticleQuery.count == 0 || readArticleQuery.loading ? <>
          <Loading/>
        </> : <>
          
          <div className="bg-white p-4">
            <b>Headline</b><br/>{articles[0]?.headline}
            <hr className='my-2'/>
            <div className='flex flex-wrap gap-4'>
              <ul className='max-w-[300px] overflow-hidden'>
                <li className='pb-2 text-sm'>
                  <b>Image Url</b><br/>
                  <a href={articles[0]?.image} target='_BLANK'>{articles[0]?.image}</a>
                </li>
                <li className='pb-2 text-sm'>
                  <b>Publication Image</b><br/>
                  <a href={articles[0]?.publicationImage} target="_BLANK">{articles[0]?.publicationImage}</a>
                </li>
                <li className='pb-2 text-sm'><b>Publication</b><br/>{articles[0]?.publication}</li>
                <li className='pb-2 text-sm'><b>Is Free?</b><br/>{articles[0]?.isFree ? 'true' : 'false'}</li>
              </ul>
              <ul className='max-w-[300px]'>
                <li className='pb-2 text-sm'><b>Title</b><br/>{articles[0]?.headline}</li>
                <li className='pb-2 text-sm'>
                  <b>Source URL</b><br/>
                  <a href={articles[0]?.url} target="_blank">{articles[0]?.url}</a>
                </li>
                <li className='pb-2 text-sm'><b>Upvotes</b><br/>{articles[0]?.upvotes}</li>
                <li className='pb-2 text-sm'><b>Tags</b><br/>{articles[0]?.tag}</li>
              </ul>
            </div>
            
          </div>

          <Heading2 className="text-2xl text-black">Landscape Large</Heading2>
          <ArticleView
            article={articles[0]}
            orientation='landscape'
            size='lg'
            onAction={(action) => articleAction(action)}
          />
          
          <Heading2 className="text-2xl text-black">Landscape Small</Heading2>
          <div className="max-w-[375px]">
            <ArticleView
              article={articles[0]}
              orientation='landscape'
              size='sm'
              onAction={(action) => articleAction(action)}
            />
          </div>

          <Heading2 className="text-2xl text-black">Portrait Large</Heading2>
          <div className="max-w-[475px]">
            <ArticleView
              article={articles[0]}
              orientation='portrait'
              size='lg'
              onAction={(action) => articleAction(action)}
            />
          </div>

          <Heading2 className="text-2xl text-black">Portrait Small</Heading2>
          <div className="max-w-[275px]">
            <ArticleView
              article={articles[0]}
              orientation='portrait'
              size='sm'
              onAction={(action) => articleAction(action)}
            />
          </div>

        </>}

      </div>

      <div className='flex-col gap-4 mt-6 pb-8 rounded gap-8'>
        <Heading2 className="text-2xl text-black pb-4">Activity Logs</Heading2>
        <ActivityLogView articleId={id}/>
      </div>
    </div>
  </>
}