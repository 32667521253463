import React, { useState, useRef, useEffect } from 'react';
import { TagSchema } from '@roc-digital/mxm-base/data';
import { searchTagsV2, } from '@roc-digital/mxm-base/logic';
import { Item, ItemFinder } from './ItemFinder';
import { Text } from './Text';
import { Icon } from './Icon';
import { closeIcon, trendingBrandIcon } from '@roc-digital/ui-lib';
import Toggle from './Toggle';

type TagsInputProps = {
  value?: TagSchema[];
  onChange?: (tags: TagSchema[]) => void;
  placeholder?: string;
  className?: string;
  label?: string;
};

interface TagFilters {
  author: boolean;
  topic: boolean;
  location: boolean;
  interest: boolean;
  publication: boolean;
  category: boolean;
}

export const TagsInput = (props: TagsInputProps) => {

  const state = useRef({
    lastValue: [] as TagSchema[] | undefined,
  })
  const [selection, setSelection] = useState<TagSchema[]>(props.value || []);
  const [filters, setFilters] = useState<TagFilters>(() => {
    return {
      author: false,
      interest: true,
      topic: true,
      publication: true,
      location: false,
      category: true,
    }
  });

  const setFilter = React.useCallback((key: keyof TagFilters, value: boolean) => {
    setFilters((c) => ({...c, [key]: value}));
  }, [])

  useEffect(() => {
    if (props.value !== state.current.lastValue) {
      state.current.lastValue = props.value;
      setSelection(props.value || []);
    }
  }, [props.value]);

  useEffect(() => {
    if (selection !== props.value && state.current.lastValue == selection) {
      if (props.onChange) {
        props.onChange(selection);
      }
    }
  }, [selection, props.value])

  const removeTag = React.useCallback((tag: TagSchema) => {
    setSelection((selection) => {
      const next = selection
      .filter((t) => t.id !== tag?.id)
      .filter((t, i, l) => l.findIndex(item => item.id === t.id) === i)
      state.current.lastValue = next;
      return next;
    });
  }, []);

  const addTag = React.useCallback((tag: TagSchema) => {
    setSelection((selection) => {
      const next = [...selection, tag]
      .filter((t, i, l) => l.findIndex(item => item.id === t.id) === i)
      state.current.lastValue = next;
      return next;
    });
  }, []);

  const onFind = React.useCallback((query: string) => {
    return searchTagsV2(query, { isAdmin: true })
      .then(tags => {
        return tags
        .sort((a,b) => {
          if(a.type === 'topic') {
            return -1
          } else if(b.type === 'topic') {
            return 1
          } else {
            return 0;
          }
        })
        .map(t => ({ id: t.id as string, label: tagToText(t) as string, data: t }))
      })
  }, []);

  const filter = React.useCallback((items: Item[]) => {
    return items.filter(item => {
      return filters[item.data.type as keyof TagFilters] ? true : false
    });
  }, [filters]);

  return (
    <div className={props.className}>
      <div className='flex max-w-[300px]'>
        <ItemFinder
          onFind={onFind}
          onItemClick={(item) => addTag(item.data)}
          selectorHeader={<>
            <div style={{fontSize: '.75rem', marginBottom: '12px'}}>Filter Tags:</div>
            <div className='flex flex-row gap-4 flex-wrap'>
              <Toggle label='Topics' initialState={filters.topic} onToggle={(e) => setFilter('topic', e)}/>
              <Toggle label='Interests' initialState={filters.interest} onToggle={(e) => setFilter('interest', e)}/>
              <Toggle label='Author' initialState={filters.author} onToggle={(e) => setFilter('author', e)}/>
              <Toggle label='Location' initialState={filters.location} onToggle={(e) => setFilter('location', e)}/>
              <Toggle label='Publications' initialState={filters.publication} onToggle={(e) => setFilter('publication', e)}/>  
              <Toggle label='Category' initialState={filters.category} onToggle={(e) => setFilter('category', e)}/>    
            </div>
            <div style={{fontSize: '.75rem', marginTop: '24px'}}>Results:</div>
          </>}
          filter={filter}
        />
      </div>

      <div className="mt-3 flex flex-row flex-wrap">
        {selection.map((tag) => {

          const query = tagToText(tag);

          return <div
            key={tag.id}
            onClick={() => removeTag(tag)}
            className={`mr-2 mb-2 bg-surface-high rounded-full pt-0 pl-3 border-2 pr-1 flex flex-row items-center`}
          >
            <Text size="small" className="text-font-low whitespace-nowrap">
              {query}
            </Text>
            <div className={'ml-1'}>
              <Icon src={closeIcon} className='min-w-[16px] min-h-[16px]' />
            </div>
          </div>
        })}
      </div>
    </div>
  );
};

function tagToText(tag: TagSchema) {
  const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return capitalize(tag.type as string) + ': ' + tag.tag;
}
