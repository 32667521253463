import './AlignmentInput.css';

type AlignmentTypes = 'top-left' | 'top-center' | 'top-right' |
'middle-left' | 'middle-center' | 'middle-right' |
'bottom-left' | 'bottom-center' | 'bottom-right';

export interface AlignmentInputProps {
  value?: AlignmentTypes;
  onChange?: (value: AlignmentTypes) => void;

}

export function AlignmentInput(props: AlignmentInputProps) {

  return <div className="blocks alignment-input">
    <div className="row">
      <div
        className={'option '+(props.value === 'top-left' ? 'active' : '')}
        onClick={() => props.onChange?.('top-left')}
      >TL</div>
      <div
        className={'option '+(props.value === 'top-center' ? 'active' : '')}
        onClick={() => props.onChange?.('top-center')}
      >TC</div>
      <div
        className={'option '+(props.value === 'top-right' ? 'active' : '')}
        onClick={() => props.onChange?.('top-right')}
      >TR</div>
    </div>
    <div className="row">
      <div
        className={'option '+(props.value === 'middle-left' ? 'active' : '')}
        onClick={() => props.onChange?.('middle-left')}
      >ML</div>
      <div
        className={'option '+(props.value === 'middle-center' ? 'active' : '')}
        onClick={() => props.onChange?.('middle-center')}
      >MC</div>
      <div
        className={'option '+(props.value === 'middle-right' ? 'active' : '')}
        onClick={() => props.onChange?.('middle-right')}
      >MR</div>
    </div>
    <div className="row">
      <div
        className={'option '+(props.value === 'bottom-left' ? 'active' : '')}
        onClick={() => props.onChange?.('bottom-left')}
      >BL</div>
      <div
        className={'option '+(props.value === 'bottom-center' ? 'active' : '')}
        onClick={() => props.onChange?.('bottom-center')}
      >BC</div>
      <div
        className={'option '+(props.value === 'bottom-right' ? 'active' : '')}
        onClick={() => props.onChange?.('bottom-right')}
      >BR</div>
    </div>
  </div>
}