import { Heading1, InterestForm } from "@/components/elements";
import {
  BodyDark,
  BodyLight,
  Button,
  ButtonSizes,
  ButtonVariants,
  Heading2,
  Icon,
  Input,
  TagsInput,
  Pressable,
  Select,
  TextArea,
  TextEditor,
  SpinnerBackdrop,
  Loading,
  Modal,
  AiModal
} from '@/components/elements';
import { createInterest, readInterest, upsertInterest } from "@/logic/interests";
import { Interest } from "@roc-digital/types";
import { useMethod } from "@roc-digital/ui-lib";
import React from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

export function EditInterest() {
  const navigate = useNavigate();
  const [interest, setInterest] = React.useState({} as Interest);
  const saveQuery = useMethod(upsertInterest);
  const param = useParams();
  const readQuery = useMethod(readInterest, {
    autoRun: param.id && param.id !== 'new' ? [param.id] : undefined,
    onSuccess: (interest) => setInterest(interest),
  });
  const loading = false;

  const save = () => {
    saveQuery.run(interest);
  };


  return (
    <div className="flex flex-col w-4/5">
      <div className=" flex justify-between h-16 mr-6 mt-3">
        <Heading1 className="text-2xl text-black">Interest</Heading1>
        <div className={'flex flex-row justify-end w-1/6 gap-3'}>
          <Button
            size={ButtonSizes.medium}
            clickEvent={() => navigate('/settings')}
            variant={ButtonVariants.secondary}
            disabled={loading as boolean}
          >
            <BodyLight className={'font-light'}>Back</BodyLight>
          </Button>
          <Button
            size={ButtonSizes.medium}
            clickEvent={save}
            disabled={loading}
            variant={!loading ? ButtonVariants.primary : ButtonVariants.disabled}
          >
            <BodyLight className={`text-white font-light`}>Save</BodyLight>
          </Button>
        </div>
      </div>
      <div className="flex-grow bg-white p-10 mr-6 rounded flex flex-col gap-4">
        <InterestForm value={interest} onChange={setInterest}/>
      </div>
      <SpinnerBackdrop active={saveQuery.loading || readQuery.loading}/>
    </div>
  );
}