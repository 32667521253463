import React from 'react';
import { publishChange, useMethod } from '@roc-digital/ui-lib';
import { readTag, putTag } from '@roc-digital/mxm-base/logic';
import { Button, ButtonSizes } from '@roc-digital/ui-web';
import { TagSchema } from '@roc-digital/mxm-base/src/data';
import { toast } from 'react-toastify';

type TagPromptProps = {
  id?: string;
  tags?: any[];
  returnNewTag: Function;
};

export const AddTagPrompt = (props: TagPromptProps) => {
  const addTopic = () => {
    const name = prompt('What is the name of your topic?');
    if (name) {
      if (!checkDuplicate(name)){
        let newTag: Partial<TagSchema> = {
          name: name,
          tag: formatTag(name),
          type: 'topic',
          is_active: true
        }
        putTagQuery.run(newTag);
      } else {
        toast.error('Tag already exists');
      }
    }
  };

  const checkDuplicate = (name: string) => {
    let duplicate = false;
    if(props.tags) {
      props.tags.forEach((tag) => {
        if(tag.type === 'topic' && tag.tag === formatTag(name)) {
          duplicate = true;
        }
      });
    }
    return duplicate;
  };

  const formatTag = (name: string) => {
    const modified = name.replace(/\s+/g, '-');
    return modified.toLowerCase();
  };

  const putTagQuery = useMethod(putTag, {
    onSuccess: (tag, args) => {
      toast.success('Tag created successfully');
      // navigate(`/tags/${tag?.id}`);
      console.log('New Tag', tag);
      props.returnNewTag(tag);
    },
    onError: (err, args) => {
      console.error(err);
        toast.error('Tag failed to create:' + err);
    }
  });

  return (
    <div className="mb-1">
      <Button size={ButtonSizes.small} clickEvent={() => addTopic()}>Add Topic</Button>
    </div>
  );
};
