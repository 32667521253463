import {
  upvoteIcon,
  downvoteIcon,
  bookmarkFillIcon,
  bookmarkIcon,
  shareIcon,
  CustomEvent,
  upvoteFillIcon,
  downvoteFillIcon,
} from '@roc-digital/ui-lib';
import { Badge } from './Badge';
import { Image } from './Image';

import { Pressable } from './Pressable';
import { Icon } from './Icon';
import { Heading1 } from './Typefaces';

export enum CardSizes {
  large = 'lg',
  medium = 'md',
  small = 'sm',
}

export type ArticleProps = {
  id?: string;
  size?: 'lg' | 'md' | 'sm';
  image?: string;
  logo?: string;
  timeLapse?: string;
  tag?: string;
  headline?: string;
  upvoted?: boolean;
  downvoted?: boolean;
  upvotes?: number;
  downvotes?: number;
  saved?: boolean;
  upVoteEvent?: CustomEvent;
  downVoteEvent?: CustomEvent;
  saveEvent?: CustomEvent;
  shareEvent?: CustomEvent;
  shareOptions?: { name: string; icon: string; action: string }[];
  className?: string;
};

const viewContainerClasses =
  'bg-white flex flex-col items-center justify-between w-full relative shadow shadow-gray-300';

const imageContainerClasses = {
  lg: 'w-full',
  md: 'w-full',
  sm: 'w-[80%] h-[300px]',
};

const imageClasses = {
  lg: 'w-full h-full',
  md: 'w-full h-full',
  sm: 'w-[80%] h-full',
};

const articleGrayFontClasses = 'text-gray-400 text-xs ml-2';

const sourceLogoClasses = '!h-[30px] ml-2 !w-auto';

export const ArticleView = ({
  id,
  size = 'lg',
  image,
  logo,
  timeLapse,
  tag,
  headline = '',
  upvoted,
  downvoted,
  saved,
  upVoteEvent,
  downVoteEvent,
  saveEvent,
  shareEvent,
  className,
}: ArticleProps): JSX.Element => {
  return (
    <div id={id} className={`${viewContainerClasses} ${className}`}>
      {/*Image*/}
      {size === 'lg' || size === 'md' ? (
        <div className={`${imageContainerClasses[size]} h-5/12`}>
          <Image
            className={`bg-gray-200 ${imageClasses[size]}`}
            src={image as string}
            style={{ maxHeight: '300px' }}
          />
        </div>
      ) : null}

      {/*Content*/}
      <div className={'flex flex-col justify-between w-full flex-grow h-5/12'}>
        <div className="flex flex-row justify-between w-full items-center mt-2 h-1/4">
          <div className={`flex flex-row grow-0 items-center justify-center`}>
            {logo && <Image className={sourceLogoClasses} src={logo} />}
            <span className={articleGrayFontClasses}>{timeLapse}</span>
          </div>
          {tag && (
            <Badge type={'web'} className={'bg-neutral-200 rounded-full px-3 text-xs py-1 mr-4 capitalize'}>
              {tag.toString().length > 15 ? tag.toString().slice(0, 10) + '...' : tag.toString() || ''}
            </Badge>
          )}
        </div>
        <div className="flex justify-items-start mt-2 px-3 py-1.5 w-full h-3/4">
          <Heading1 className={''}>{headline}</Heading1>
        </div>
      </div>

      {/*Footer*/}
      <div className="flex flex-row w-full justify-between px-2 pt-1 pb-2 h-2/12">
        <div className="flex flex-row items-center rounded-full border border-gray-100 px-2">
          <Pressable id={id} clickEvent={upVoteEvent} className="px-1 border-gray-100">
            <Icon size="medium" src={upvoted ? upvoteFillIcon : upvoteIcon} />
          </Pressable>
          <span className={'text-gray-400 text-xs pr-1'}>{49}</span>

          <div className="h-[100%] bg-gray-100 mx-1 border border-gray-100" />

          <Pressable id={id} clickEvent={downVoteEvent} className={'px-1'}>
            <Icon size="medium" src={downvoted ? downvoteFillIcon : downvoteIcon} />
          </Pressable>
          <span className={'text-gray-400 text-xs border-gray-100'}>{200}</span>
        </div>

        <div className="flex flex-row items-center">
          <Pressable
            className={'flex items-center rounded-full border border-gray-100 p-2'}
            id={id}
            clickEvent={saveEvent}
          >
            <Icon size="medium" src={saved ? bookmarkFillIcon : bookmarkIcon} />
          </Pressable>
          <div className="h-full bg-gray-100 mx-1" />
          <Pressable
            className={'rounded-full border border-gray-100 p-2 flex items-center'}
            id={id}
            clickEvent={shareEvent}
          >
            <Icon size="medium" src={shareIcon} />
          </Pressable>
        </div>
      </div>
    </div>
  );
};

ArticleView.displayName = 'ArticleView';
