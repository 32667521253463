import { JsonFetch } from "@roc-digital/mxm-base/logic";
import { CreatePushNotificationParams, CreateSmsNotificationParams } from '@roc-digital/types';
import { AUX_API } from '@/env';

export async function createPushNotification(params: CreatePushNotificationParams) {
  await JsonFetch({
    method: 'POST',
    url: `${AUX_API}/notifications/create-push-notification`,
    data: params,
  });;
}

export async function createSmsNotification(params: CreateSmsNotificationParams) {
  await JsonFetch({
    method: 'POST',
    url: `${AUX_API}/notifications/create-sms-notification`,
    data: params,
  });;
}