import React, { memo, useEffect } from 'react';
import {
  useClickEvent,
  usePaginateEvent,
  useStateEvent,
  useChangeEvent,
  InputData,
  PublisherData,
  useCustomEvent,
  PaginationData,
  useMethod,
  useDebouncedState,
} from '@roc-digital/ui-lib';
import { activateView, listViews, duplicateView } from '@roc-digital/mxm-base/logic';
import { View, Views as ModelViews } from '@roc-digital/mxm-base/data';
import { Event } from '@roc-digital/mxm-base/events';
import { useNavigate } from 'react-router';
import {
  BodyDark,
  BodyLight,
  Button,
  ButtonSizes,
  Heading1,
  InputSearch,
  Loading,
  Pagination,
  Pressable,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableSection,
} from '@/components/elements';
import { toast } from 'react-toastify';

const Views = memo(() => {
  const [allViews, setAllViews] = useStateEvent<ModelViews>('mxm.http.views', 'listViews.success', undefined);
  const [search, setSearch] = useDebouncedState<string>('', 500);
  const navigate = useNavigate();
  const listViewsQuery = useMethod(listViews);
  const duplicateQuery = useMethod(duplicateView, {
    onSuccess: () => {
      toast.success('View duplicated.');
      listViewsQuery.reload();
    },
    onError: () => {
      toast.error('Failed to duplicate view.');
    }
  });
  const paginateEvent = usePaginateEvent(
    ({ data }: Event<PaginationData>) => {
      listViewsQuery.run({ isAdmin: true, page: data?.page, count: 10, query: search });
    },
    { namespace: 'mxm.http.views' },
    {},
    []
  );

  useEffect(() => {
    listViewsQuery.run({ isAdmin: true, page: 1, count: 10, query: search })
  }, [search]);

  const createClickEvent = useClickEvent(() => {
    navigate('/views/new');
  });

  const makeActiveEvent = useClickEvent(
    ({ data }: Event<PublisherData>) => {
      const id = data?.id as string;

      if (id) {
        void activateView(id);
      }
    },
    {},
    {},
    []
  );

  const openViewsEvent = useClickEvent(({ data }: Event<PublisherData>) => {
    navigate(('/views/' + data?.id) as string);
  });

  const previewViewsEvent = useClickEvent(({ data }: Event<PublisherData>) => {
    navigate(('/views_preview/' + data?.id) as string);
  });

  const changeSearchEvent = useChangeEvent(({ data }: Event<InputData>) => {
    setSearch(data?.value as string);
  });

  const deleteViewEvent = useChangeEvent(({ data }: Event<PublisherData>) => {
    if (!confirm('Are you sure you want to delete this view?')) { return; }
  });

  useCustomEvent(
    'listViews.success',
    ({ data }: { data: ModelViews }) => {
      setAllViews(data);
    },
    { namespace: 'mxm.http.views' },
    {},
    [allViews]
  );

  useCustomEvent(
    'activateView.failed',
    ({ data }: any) => {
      toast.error(('Error activating view: ' + data?.e?.message) as string);
    },
    { namespace: 'mxm.http.views' },
    {},
    []
  );

  useCustomEvent(
    'activateView.success',
    () => {
      if (!confirm('Confirm you want to activate this view?')) { return; }
      void listViews({ isAdmin: true, page: 1, count: 20 });
      toast.success('View activated successfully. Please allow 2 minutes for the cache to update.');
    },
    { namespace: 'mxm.http.views' },
    {},
    [allViews]
  );

  return (
    <div className="flex flex-col w-4/5">
      <div className=" flex justify-between h-16 mr-6 mt-3">
        <Heading1 className="text-2xl text-black">Views</Heading1>
        <Button size={ButtonSizes.medium} clickEvent={createClickEvent}>
          <BodyLight className={'text-white'}>Add Layout</BodyLight>
        </Button>
      </div>
      <div className="flex-grow bg-white p-10 mr-6 rounded">
        <div className="flex flex-row mb-4 justify-between">
          <InputSearch
            showLoading={false}
            showLabel={false}
            placeholder={'Search Views'}
            changeValueEvent={changeSearchEvent}
            hideResults
          />
        </div>
        <Table>
          <TableSection type="head">
            <TableRow>
              <TableHeader className={'w-3/5'}>Name</TableHeader>
              <TableHeader className={'w-2/5 text-right pr-6'}>Actions</TableHeader>
            </TableRow>
          </TableSection>
          <TableSection>
            {allViews?.map((view: View, key: number | undefined) => (
                <TableRow key={key}>
                  <TableCell className={'flex flex-col'}>
                    <BodyDark>{view.title ? view.title : '--'}</BodyDark>
                  </TableCell>
                  <TableCell className={'text-right'}>
                    {view.is_active || (
                      <Pressable className={'inline'} clickEvent={makeActiveEvent} id={view?.id}>
                        <span className={'mr-2 inline px-1.5 py-1 bg-green-200 rounded-md whitespace-pre'}>
                          Activate
                        </span>
                      </Pressable>
                    )}
                    <Pressable id={view.id} className={'inline mx-2'} clickEvent={openViewsEvent}>
                      <img src={'/icons/edit_zone.svg'} title={'Edit'} className={'inline h-5'} />
                    </Pressable>
                    <Pressable id={view.id} className={'inline mx-2'} clickEvent={previewViewsEvent}>
                      <img src={'/icons/preview.svg'} title={'Preview'} className={'inline h-5'} />
                    </Pressable>
                    <Pressable id={view.id} className={'inline mx-2'} clickEvent={() => duplicateQuery.run(view.id as string)}>
                      <img src={'/icons/duplicate.svg'} title={'Duplicate'} className={'inline h-5'} />
                    </Pressable>
                  </TableCell>
                </TableRow>
              ))}
            {!listViewsQuery.loading && allViews?.count() === 0 ? (
              <TableRow>
                <TableCell className={'text-center'} colSpan={6}>
                  <div className={'p-2'}>No records found.</div>
                </TableCell>
              </TableRow>
            ) : null}
            {listViewsQuery.loading || duplicateQuery.loading ? (
              <TableRow>
                <TableCell className={'text-center'} colSpan={2}>
                  <Loading size={'medium'} />
                </TableCell>
              </TableRow>
            ) : null}
          </TableSection>
        </Table>
        {allViews?.pagination && (
          <Pagination className={'mt-5'} paginateEvent={paginateEvent} paginator={allViews?.pagination} />
        )}
      </div>
    </div>
  );
});

Views.displayName = 'Views';

export default Views;
