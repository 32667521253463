import { Pagination as CorePagination } from '@roc-digital/mxm-base/data';
import { t } from '@roc-digital/mxm-base/logic';
import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { PaginateEvent } from '@roc-digital/ui-lib';
import { Loading } from './Loading';
import { Pagination } from './Pagination';
import { Text } from './Text';

const EmptyMessageItem = ({ emptyMessage }: { emptyMessage: string }) => (
  <div className="mp-4 bg-surface-high p-2 m-2">
    <Text className="text-font-low text-center my-4">{emptyMessage}</Text>
  </div>
);

const SeparatedItem = ({ separator, renderedItem }: { separator: React.ReactNode; renderedItem: React.ReactNode }) => (
  <div>
    {separator}
    {renderedItem}
  </div>
);

type ListProps = {
  data: any[] | undefined;
  renderItem: ({ item, index }: { item: any; index: number; highlighted?: number }) => ReactNode;
  keyExtractor: (item: any, index: number) => string;
  separator?: ReactNode;
  emptyMessage?: string | ReactNode;
  className?: string;
  paginator?: CorePagination;
  paginateEvent?: PaginateEvent;
  paginatorClassName?: string;
  listClassName?: string;
  highlighted?: number;
};

export function List({
  data,
  renderItem,
  keyExtractor,
  separator,
  emptyMessage,
  className = '',
  paginator,
  paginateEvent,
  paginatorClassName = '',
  listClassName = '',
  highlighted,
}: ListProps) {
  const container = useRef<HTMLDivElement>(null);

  const getDefaultEmptyMessage = (emptyMessage: string | React.ReactNode) =>
    typeof emptyMessage === 'string' ? <EmptyMessageItem emptyMessage={emptyMessage} /> : emptyMessage;

  const getDataItem = useCallback(
    (item: any, key: string, index: number) =>
      separator && index > 0 ? (
        <SeparatedItem key={key} separator={separator} renderedItem={item} />
      ) : (
        <div key={key}>{item}</div>
      ),
    [separator]
  );

  const getDataContent = useCallback(() => {
    return data?.map((item, index) =>
      getDataItem(renderItem({ item, index, highlighted }), keyExtractor(item, data.indexOf(item)), data.indexOf(item))
    );
  }, [data, getDataItem, highlighted, keyExtractor, renderItem]);

  const getContent = useCallback(
    () =>
      data === undefined || data === null ? (
        <Loading circular={false} size="medium" className={`mt-4 ${listClassName} `} />
      ) : data.length ? (
        getDataContent()
      ) : (
        getDefaultEmptyMessage(emptyMessage)
      ),
    [data, emptyMessage, getDataContent, listClassName]
  );

  useEffect(() => {
    if (container.current && highlighted !== undefined) {
      const element = container.current.children[highlighted];

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      }
    }
  }, [highlighted]);

  return (
    <div className={`flex flex-col items-stretch ${className}`}>
      <div ref={container} className={`flex-grow overflow-y-auto flex flex-col items-stretch ${listClassName}`}>
        {getContent()}
      </div>
      {data?.length && paginator !== undefined && paginateEvent ? (
        <Pagination className={paginatorClassName} paginator={paginator} paginateEvent={paginateEvent} />
      ) : null}
    </div>
  );
}

List.defaultProps = {
  separator: undefined,
  emptyMessage: t('noResults'),
  className: '',
};
