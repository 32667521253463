export interface SwatchProps {
  color: string;
}

export function Swatch(props: SwatchProps) {
  return <div style={{
    width: '15px',
    height: '15px',
    borderRadius: '15px',
    backgroundColor: props.color || 'white',
  }}></div>
}
