import React, { memo } from 'react';
import { useMethod } from '@roc-digital/ui-lib';
import {
  Button,
  ButtonSizes,
  ButtonVariants,
  Heading2,
  Input,
  Modal,
  SpinnerBackdrop,
  TextArea
} from '@/components/elements';
import { toast } from 'react-toastify';
import { UserFilterBuilder } from './UsersFilterBuilder';
import { TagSchema } from '@roc-digital/mxm-base/src/data';
import { createSmsNotification } from '@/logic/notifications';
import { CreateSmsNotificationParams } from '@roc-digital/types';

export interface SmsModalProps { }

export function openSmsModal(props: SmsModalProps) {
  Modal.open('mxm.modal', <SmsModal {...props} />, 'medium');
}

export const SmsModal = memo((props: SmsModalProps) => {
  const [target, setTarget] = React.useState<'tags' | 'all' | 'numbers'>('tags');
  const [message, setMessage] = React.useState('');
  const [numbers, setNumbers] = React.useState('');
  const [tags, setTags] = React.useState<TagSchema[]>([]);
  const [page, setPage] = React.useState<'notification' | 'audience'>('notification');

  const notifyQuery = useMethod(createSmsNotification, {
    onBefore: async (args) => {
      if (!confirm('Ready to send SMS? This will send a SMS message to all users in your selected audience.')) {
        return;
      }

      return args;
    },
    onSuccess: () => {
      toast.success('SMS sent successfully');
      closeModal();
    },
    onError: (e) => {
      toast.error(('Error sending SMS: ' + e?.message) as string);
    }
  });

  const send = () => {
    const params: CreateSmsNotificationParams = {
      message,
      target: target,
    };

    if(target === 'numbers') {
      params.numbers = numbers.split(',').map(n => n.trim()).filter(n => n);
      if(!params.numbers.length) throw new Error('No valid numbers entered!');
    } else if(target === 'tags') {
      params.scopes = tags.map(t => t.scope || '').filter(n => n);
      if(!params.scopes.length) throw new Error('No valid tags selected!');
    }

    notifyQuery.run(params)
  };

  const closeModal = () => {
    Modal.close('mxm.modal');
  };

  const loading =  notifyQuery.loading;
  const valid = message.trim()
    && ((target === 'all') || (target === 'numbers' && numbers.trim()) || (target === 'tags' && tags.length))
    && !loading ? true : false;
  const canSend = valid;

  return (
    <div className="p-8">
      {page === 'notification' ? <>
        <Heading2 className={'mb-8 text-admin-dark !font-extrabold !text-[26px]'}>Send SMS Message</Heading2>


        <div className='mb-8'>
          <div className="w-full mt-2">
            <label>Title</label>
            <Input value={message} className="w-full" changeEvent={(e) => setMessage(e.target.value)} />
          </div>
        </div>
        <div>
          <div className="mt-4 flex inline-block gap-2">
            <Button clickEvent={closeModal} size={ButtonSizes.medium} variant={ButtonVariants.secondary}>Cancel</Button>
            <span className='flex-grow'></span>
            <Button clickEvent={() => setPage('audience')} disabled={message.trim().length == 0} size={ButtonSizes.medium}>Select Audience</Button>
          </div>
        </div>
      </> : <></>}
      {page === 'audience' ? <>
        <Heading2 className={'mb-8 text-admin-dark !font-extrabold !text-[26px]'}>Send SMS Message - Audience</Heading2>

        <div className='min-h-[400px] pt-8 gap-2 flex flex-col'>
          <select value={target} onChange={e => setTarget(e.target.value as any)}>
            <option value="numbers">Set of phone numbers</option>
            <option value="tags">User with tags</option>
            <option value="all">Everyone with a registered phone number</option>
          </select>
          {target === 'all' ? <>
            All users that have registered a phone number will receive this SMS.
          </> : <></>}

          {target === 'tags' ? <>
            <div className='mt-2'>
              Target users with the following tags:
              <UserFilterBuilder
                onChange={filters => {
                  setTags(filters.tags)
                }}
              />
            </div>
          </> : <></>}

          {target === 'numbers' ? <>
          <div className="w-full mt-2">
            <label>Phone Numbers (Comma seperated list)</label>
            <TextArea value={numbers} className="w-full h-[202px]" changeEvent={(e) => setNumbers(e.target.value)} />
          </div>
          </> : <></>}
        </div>

        <div>
          <div className="mt-4 flex inline-block gap-2">
            <Button clickEvent={closeModal} size={ButtonSizes.medium} variant={ButtonVariants.secondary}>Cancel</Button>
            <Button clickEvent={() => setPage('notification')} size={ButtonSizes.medium} variant={ButtonVariants.secondary}>Back</Button>
            <span className='flex-grow'></span>
            <Button clickEvent={send} disabled={!canSend} size={ButtonSizes.medium}>Send SMS</Button>
          </div>
        </div>
      </> : <></>}

      <SpinnerBackdrop active={loading} />
    </div>
  );
});
