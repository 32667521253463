import { RuiContext, Render, ContentBlock } from "@roc-digital/rich-ui";
import { getWebComponent } from '@roc-digital/ui-web';
import React from "react";

export interface RenderUiBlockProps {
  block?: ContentBlock;
  onAction?: RuiContext['doAction'];
  textTokens?: RuiContext['textTokens'];
}

export function RenderUiBlock(props: RenderUiBlockProps) {
  const components = React.useMemo(getWebComponent, []);

  if(!props.block) return null;

  return <div className='flex flex-col overflow-y-auto'>
    <Render
      block={props.block}
      onAction={props.onAction}
      components={components}
      textTokens={props.textTokens}
    />
  </div>
}

