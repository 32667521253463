import { JsonFetch } from "@roc-digital/mxm-base/logic";

export interface ActivityLogFilters {
  type?: string;
  user_id?: string;
  article_id?: string;
  id_lte?: number;
  id_gte?: number;
}

export interface ActivityLog {
  id: number;
  user_id?: string;
  article_id?: string;
  date: number;
  type: string;
  description: string;
  before: object;
  after: object
}

export async function listActivityLogs(filters?: ActivityLogFilters): Promise<ActivityLog[]> {
  return await JsonFetch<ActivityLog[]>({
    url: `${process.env.REACT_APP_AUX_API_URL}/activity-logs`,
    query: filters
  });
}