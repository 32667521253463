import React from 'react';
import { FlexLayoutInput } from '../components/FlexLayoutInput';
import { ContainerAttributes } from '@roc-digital/rich-ui';
import { BlockElementDef, RuiApi, createNode } from './types';
import { RuiNode } from '@roc-digital/rich-ui';
import { useValueRef } from '@/hooks/useValueRef';
import { applyLayoutToElement } from '@roc-digital/ui-web';
import { Grouping } from '../components/Grouping';
import { Input } from '../components/Input';
import { bindProp, useProperties } from '@/hooks/useProperties';
import { Select } from '../components/Select';
import { DropdownItems } from '../components/Dropdown';

const defaultValues: Partial<ContainerAttributes> = {
  alignment: 'top-left',
  direction: 'column',
  width: 'fill',
  height: 'hug',
  backgroundColor: 'none',
}

export const containerElement: BlockElementDef = {
  id: 'container',
  getLabel: (node) => node.data?.direction === 'row' ? 'Row' : 'Column',
  getIcon: (node) => node.data?.direction === 'row' ? rowIcon : colIcon,
  getElementStarters: () => [
    {id: 'container-col', elementId: 'container', name: 'Column Container', createNew: createColumnContainer},
    {id: 'container-row', elementId: 'container', name: 'Row Container', createNew: createRowContainer},
  ],
  renderEditControls: (node, api) => {
    return <ContainerControls node={node} api={api}/>
  },

  createDomNode:(node, api) => {
    const div = document.createElement('div');
    div.setAttribute('draggable', 'true');
    div.setAttribute('selectable', 'yes');
    div.setAttribute('insertable', 'yes');
    return div;
  },
  renderNode,
  serialize
}

function createColumnContainer(): RuiNode {
  return createNode('container', {
    direction: 'column',
    width: 'fill',
    height: 'fill',
    backgroundColor: '#e1e1e1',
  });
}

function createRowContainer() {
  return createNode('container', {
    direction: 'row',
    width: 'fill',
    height: 'fill',
    backgroundColor: '#e1e1e1',
  });
}

interface ContainerControlsProps {
  node: RuiNode;
  api: RuiApi
}

function setNodeLayout(node: RuiNode, layout: ContainerAttributes) {
  node.data = layout;
}

function getNodeLayout(node: RuiNode): ContainerAttributes {
  return {
    ...defaultValues,
    ...(node.data?.style || node.data || {} )as ContainerAttributes
  }
}

function ContainerControls(props: ContainerControlsProps) {

  const nodeRef = useValueRef(props.node);
  const [layout, setLayout] = React.useState<ContainerAttributes>(() => getNodeLayout(props.node));

  React.useEffect(() => {
    setLayout(getNodeLayout(props.node));
  }, [props.node]);

  const onChange = (next: ContainerAttributes) => {
    const node = nodeRef.current;
    setNodeLayout(node, next);
    renderNode(node, props.api);
    setLayout(next);
    props.api.markChange();
  }

  const state = useProperties(layout, onChange);

  return <>
    <FlexLayoutInput value={layout} onChange={onChange}/>
    <Grouping label='Background Image' fill row>
      <Input
        type='string'
        label='URL'
        tooltip='The image url.'
        {...bindProp(state, 'backgroundImage')}
      />
      <Select
        label='Fit'
        items={imageFitOptions}
        tooltip='Instructs how the image should be fit in the background of the container.'
        {...bindProp(state, 'imageFit')}
      />
    </Grouping>
  </>
}

function renderNode(node: RuiNode, api: RuiApi) {
  const element = api.getDomNode(node);
  if (!element) {
    console.warn('Attempted to render node that had no dom element', node);
    return;
  }

  const parent = api.getParent(node);
  const parentDir = parent?.data?.direction === 'row' ? 'row' : 'column';
  const layout = (node.data || {}) as ContainerAttributes;
  applyLayoutToElement(layout, element, parentDir);
  
}

function serialize(node: RuiNode, serializeChild: (domNode: HTMLElement | Element) => RuiNode, api: RuiApi) {
  const domNode = api.getDomNode(node);

  if (!domNode) return null;

  const nextNode: RuiNode = {
    element: 'container',
    children: [],
    data: node?.data || {}
  }

  if(nextNode.data?.style) {
    delete nextNode.data.style;
  }

  for(let childDom of domNode.children) {
    const child = serializeChild(childDom);
    if(child) {
      nextNode.children?.push(child);
    }
  }

  return nextNode;
}

const rowIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M208,136H48a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A16,16,0,0,0,208,136Zm0,56H48V152H208v40Zm0-144H48A16,16,0,0,0,32,64v40a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V64A16,16,0,0,0,208,48Zm0,56H48V64H208v40Z"></path></svg>`;
const colIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M104,32H64A16,16,0,0,0,48,48V208a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V48A16,16,0,0,0,104,32Zm0,176H64V48h40ZM192,32H152a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V48A16,16,0,0,0,192,32Zm0,176H152V48h40Z"></path></svg>`;

const imageFitOptions: DropdownItems = [
  { id: 'center', label: 'Center' },
  { id: 'cover', label: 'Cover' },
  { id: 'contain', label: 'Contain' },
  { id: 'repeat', label: 'Repeat' },
  { id: 'stretch', label: 'Stretch' },
];