import React from "react";
import { Button } from "@/components/elements";
import { PageLayout } from "@/components/elements/PageLayout";
import { Popup } from '@roc-digital/types/popups';
import { popupTypes } from './Types';
import { useNavigate, useParams } from "react-router-dom";
import { useMethod } from "@roc-digital/ui-lib";
import { readPopup, upsertPopup } from "@/logic/popups";
import { notifyError, notifySuccess } from "@roc-digital/mxm-base/logic";
import { PopupForm } from "@/components/elements/PopupForm";
import { AUX_API } from "@/env";

export function EditPopup() {
  const nav = useNavigate();
  const params = useParams();
  const [popup, setPopup] = React.useState<Popup>({type: 'generic-1'} as any);
  const options = React.useMemo(() => {
    return popupTypes.map(popupType => ({
      label: popupType.type,
      value: popupType.type
    }))
  }, [popupTypes]);
  const popupType = popupTypes.find(pt => pt.type === popup.type) || popupTypes[0];
  const previousTypeRef = React.useRef(popupType);

  React.useEffect(() => {
    setPopup(popup => {

      const data = {
        ...popup,
        config: {
          ...popupType.defaultConfig,
          ...(popup.config || {})
        }
      };

      data.config.trigger = {
        ...popupType.defaultConfig.trigger || {},
        ...data.config.trigger || {},
      }

      if(previousTypeRef.current) {
        const p = previousTypeRef.current;
        if(data.config['title'] === p.defaultConfig['title'] && popupType.defaultConfig?.title) {
          data.config['title'] = popupType.defaultConfig.title;
        }
        if(data.config['body'] === p.defaultConfig['body'] && popupType.defaultConfig?.body) {
          data.config['body'] = popupType.defaultConfig.body;
        }
      }
      
      return data;
    })

    return () => {
      previousTypeRef.current = popupType;
    }
  }, [popupType])
  
  const readQuery = useMethod(readPopup, {
    autoRun: [params.id === 'create' ? 0 : parseInt(params.id || '0')],
    onBefore: async (args) => {
      if (!args[0]) return;

      return args;
    },
    onSuccess: (result) => {
      if (result) setPopup(result);
    },
    onError: () => {
      notifyError('Failed to read popup');
    }
  });

  const saveQuery = useMethod(upsertPopup, {
    onSuccess: (result) => {
      if(result.id !== parseInt(params.id || '')) {
        notifySuccess('Popup created!');
        nav(`/popups/${result.id}`)
      } else {
        notifySuccess('Popup saved!');
      }
    },
    onError: (err) => {
      notifyError('Failed to update popup');
    }
  });

  const loading = saveQuery.loading || readQuery.loading;
  const previewJsx = popupType?.Preview ? <popupType.Preview popup={popup} /> : null;
  const configJsx = popupType?.ConfigForm ? <popupType.ConfigForm popup={popup} onChange={setPopup} /> : null;

  return <PageLayout
    title="Edit Popup"
    actions={<>
      <Button
        size="md"
        title="Cancel"
        variant="secondary" clickEvent={() => nav('/popups')}
        disabled={loading}
      />
      <Button
        size="md"
        title="Save"
        clickEvent={() => saveQuery.run(popup)}
        disabled={loading}
      />
      <Button
        size="md"
        title="Preview"
        clickEvent={() => openPreview(popup)}
        disabled={loading}
      />
    </>}
    sidebar={configJsx}
    loading={readQuery.loading}
  >

    <PopupForm
      value={popup}
      preview={previewJsx}
      popupTypes={options}
      onChange={setPopup} />

  </PageLayout>;
}

const popupRefs: {[id: string]: {
  target: Window,
  popup: Popup,
}} = {};
function openPreview(popup: Popup) {
  const id = 'p' + (popup.id || 'new').toString();
  const target = window.open(`${AUX_API}?popupPreview=${id}@${window.origin}`, '__blank');
  if(!target) return;
  popupRefs[id] = {
    popup,
    target,
  };
}

window.addEventListener('message', (message) => {
  const type = message.data?.['messageType'] as string;
  const popupRef = message.data?.['popupRef'] as string;
  const origin = message.data?.['origin'] as string;

  const ref = popupRefs[popupRef];

  if(type === 'getPopup' && ref && origin) {
    ref.target.postMessage({
      messageType: 'previewPopup',
      popup: ref.popup,
    }, origin);
  }
});