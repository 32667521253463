import React, { useState } from 'react';
import { publish } from '@roc-digital/mxm-base/events';
import { ClickEvent, CustomEvent, PublisherData } from '@roc-digital/ui-lib';
import { BodyDark } from './Typefaces';

export interface ToggleProps {
  onToggle?: (toggled: boolean) => void;
  label: string;
  initialState?: boolean;
  value?: boolean;
  clickEvent?: ClickEvent | CustomEvent | ((value: boolean) => void);
  disabled?: boolean;
  loading?: boolean;
  id?: string;
  isLabelLeft?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
  isLabelLeft,
  onToggle = () => {},
  label,
  initialState = false,
  value = false,
  clickEvent,
  disabled,
  loading,
  id,
}) => {
  const [isToggled, setIsToggled] = useState(initialState || value);
  let toggledRef = React.useRef(isToggled);
  toggledRef.current = isToggled;

  React.useEffect(() => {
    if(value !== toggledRef.current) {
      setIsToggled(value);
    }
  }, [value]);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    onToggle(!isToggled);

    if (!clickEvent || disabled || loading) {
      return;
    }

    if(typeof clickEvent === 'function') {
      clickEvent(!isToggled);
      return;
    }

    publish<PublisherData>(clickEvent.namespace, 'click', {
      id,
      eventData: clickEvent.data || {},
    });
  };

  return (
    <div className="flex items-center">
      {!isLabelLeft ? <BodyDark className="mr-2 text-sm text-black">{label}</BodyDark> : null}
      <button
        className={`w-12 h-6 flex items-center rounded-full p-1 transition-colors duration-300 ${
          isToggled ? 'bg-surface-primary' : 'bg-gray-300'
        }`}
        onClick={handleToggle}
      >
        <span
          className={`w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 z-50 ${
            isToggled ? 'translate-x-5 bg-white' : 'translate-x-0 bg-white'
          }`}
        ></span>
      </button>
      {isLabelLeft ? <BodyDark className="ml-2 text-sm text-black">{label}</BodyDark> : null}
    </div>
  );
};

export default Toggle;
