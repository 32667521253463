import { Popup } from "@roc-digital/types";
import { JsonFetch } from "@roc-digital/mxm-base/logic";
import { AUX_API } from '@/env';

export interface PopupFilters {
  query: string;
  showOnlyActive: boolean;
}

export function listPopups(filters?: PopupFilters) {
  return JsonFetch<Popup[]>({url: `${AUX_API}/admin/popups`, query: filters});
}

export function readPopup(id: number) {
  return JsonFetch<Popup>({url: `${AUX_API}/admin/popups/${id}`});
}

export function createPopup(popup: Popup) {
  return JsonFetch<Popup>({method: 'POST',url: `${AUX_API}/admin/popups`, data: popup});
}

export function updatePopup(id: number, popup: Popup) {
  return JsonFetch<Popup>({method: 'PATCH',url: `${AUX_API}/admin/popups/${id}`, data: popup});
}

export function upsertPopup(popup: Popup) {
  if (popup.id) {
    return updatePopup(popup.id, popup);
  } else {
    return createPopup(popup);
  }
}

export async function deletePopup(id: number) {
  await JsonFetch({method: 'DELETE',url: `${AUX_API}/admin/popups/${id}`});
}