import React from 'react';
import { Zone } from './types';
import { ZoneLayout } from '@roc-digital/ui-web';
import { useMethod, mapToArticleContent} from '@roc-digital/ui-lib';
import { listArticlesByScopesV2 } from '@roc-digital/mxm-base/logic';

export interface TagZonePreviewProps {
  zone: Zone;
}

export function TagZonePreview(props: TagZonePreviewProps) {

  const articlesQuery = useMethod(listArticlesByScopesV2);

  const articles = React.useMemo(() => {
    if(!articlesQuery.result) return [];

    return articlesQuery.result.map(a => mapToArticleContent(a, {}, true));
  }, [articlesQuery.result]);

  const scopes = props.zone?.tags?.map(z => z.scope) as string[] || [];
  const scopesKey= scopes.join(',');

  React.useEffect(() => {
    if(scopes.length) {
      articlesQuery.run(scopes, 1, 9);
    } else {
      articlesQuery.setResult([]);
    }
  }, [scopesKey]);

  return <ZoneLayout
    articles={articles}
    layoutId={props.zone.web_layout as any}
    onAction={() => {}}
  />;
}