import React from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, } from 'victory';
import { getWeek, setDay } from 'date-fns'

export type EngagementChartProps = {
  histogram?: [number | null, number][]
  chartHeight?: number;
  yTickLabel?: (value: number) => string;
  yMap?: (value: number) => number;
  
};

export function EngagementChart(props: EngagementChartProps) {
  
  const data = React.useMemo(() => {
    if(!props.histogram?.length) return [];

    const values =  props.histogram
    .filter(h => h[0])
    .map(h => {
      const s = h[0]?.toString() || '';
      const date = s.slice(0,4) + '-' + s.slice(4,6) + '-' + s.slice(6,8);
      const week = new Date(date);
      if(!week.getTime()) return null as any as {day: Date, value: number, w: number};
      return {day: new Date(date), value: h[1], w: getWeek(week)};
    });

    return values
    .filter(Boolean)
    .map(v => ({x: v.day, y: Math.floor(v.value/ 1000)}))
    .slice(-30);
  }, [props.histogram]);

  return (
    <div className={'w-full chart-wrapper'}>
      <VictoryChart
        height={props.chartHeight || 200}
        domainPadding={{ x: [20, -20]}}
      >
        <VictoryBar
          data={data}
          style={{ data: { stroke: '#A6A6A6' } }}
          scale={{x: 'time', y: 'linear'}}
        />
      </VictoryChart>
    </div>
  );
};
