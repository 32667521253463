import React, { useState, useEffect, useRef, ReactElement, ReactNode } from 'react';
import ReactModal from 'react-modal';
import { publish } from '@roc-digital/mxm-base/events';

import { useClickEvent, useCustomEvent, closeIcon } from '@roc-digital/ui-lib';
import { Data, Event } from '@roc-digital/mxm-base/types';
import { Icon } from './Icon';
import { useValueRef } from '@/hooks/useValueRef';

type Props = {
  id: string;
  size?: 'small' | 'medium' | 'large';
  onClose?: () => void;

  // Public Modal functions
  readonly open?: (id: string) => void;
  readonly close?: (id: string) => void;
};

const contentClasses = `bg-surface border-edge overflow-x-hidden border-1 border-solid rounded-none flex flex-col text-font shadow-xl drop-shadow-xl relative z-4 focus:outline-none w-full my-12`;
const overlayClasses =
  'bg-overlay w-full h-full fixed block top-0 left-0 flex flex-col items-center justify-center z-40';
const closeIconClasses = 'absolute top-6 right-6';

export const Modal = ({ id, size: initialSize = 'medium', onClose }: Props) => {
  const [modalContent, setModalContent] = useState<ReactElement>();
  const [size, setSize] = useState(initialSize);
  const [showCloseButton, setShowCloseButton] = useState(true);
  const [blurBg, setBlurBg] = useState(false);
  const onCloseRef = React.useRef(onClose);

  useCustomEvent(
    'open',
    (evt: Event<Data>) => {
      const content = evt.data.content;

      setSize(evt.data.size || initialSize);
      setBlurBg(evt.data.blurBg || false);
      setModalContent(content);
      setIsOpen(true);
      setShowCloseButton(evt.data.showCloseButton ?? true);
      onCloseRef.current = evt.data.onClose;
    },
    { namespace: id },
    undefined,
    [id, modalContent]
  );

  useCustomEvent('close', () => {
    if(onCloseRef.current && isOpenRef.current) {
      onCloseRef.current()
    }
    setIsOpen(false)
  }, { namespace: id }, undefined, [id]);

  const rendered = useRef(false);

  const [isOpen, setIsOpen] = useState(false);
  const isOpenRef = useValueRef(isOpen);

  useEffect(() => {
    // Mark the component as rendered
    rendered.current = true;

    // Make sure ReactModal knows how to find the app element
    ReactModal.setAppElement('#root');
  }, []);

  const close = () => {
    if(onCloseRef.current && isOpenRef.current) {
      const cb = onCloseRef.current;
      onCloseRef.current = () => {};
      cb();
    }
    setIsOpen(false)
  };

  // Close the modal when the close icon is clicked
  const iconClickEvent = useClickEvent(() => close());

  const sizeClass = size === 'small' ? 'max-w-md' : size === 'medium' ? 'max-w-4xl' : 'max-w-7xl';

  return (
    <ReactModal
      id={id}
      className={`!rounded-lg ${contentClasses} ${sizeClass}`}
      overlayClassName={`rounded-lg ${overlayClasses} ${blurBg ? 'backdrop-blur-lg' : ''}`}
      isOpen={isOpen}
      onRequestClose={close}
      closeTimeoutMS={170}
    >
      {showCloseButton && <Icon clickEvent={iconClickEvent} className={closeIconClasses} src={closeIcon} />}
      {modalContent}
    </ReactModal>
  );
};

Modal.open = (id: string, content: ReactNode, size?: 'small' | 'medium' | 'large', blurBg?: boolean, onClose?: () => void) => {
  publish(id, 'open', { content, size, blurBg, onClose });
};

Modal.close = (id: string) => {
  publish(id, 'close');
};
