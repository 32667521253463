import { t } from '@roc-digital/mxm-base/logic';
import { Text } from './Text';
import styles from './Loading.module.css';

type Props = {
  type?: 'primary' | 'secondary' | 'critical' | 'borderless' | 'disabled' | 'custom';
  size?: 'x-small' | 'small' | 'medium' | 'large';
  circular?: boolean;
  className?: string;
};

const color = {
  primary: `border-t-surface-low border-r-surface-low border-l-surface-low border-b-transparent`,
  secondary: `border-t-white border-r-white border-l-white border-b-transparent`,
  critical: `border-t-white border-r-white border-l-white border-b-transparent`,
  disabled: `border-t-edge border-r-edge border-l-edge border-b-transparent`,
  custom: `border-t-white border-r-white border-l-white border-b-transparent`,
};

const loadingClasses = 'text-center';

const loadingImgClasses = 'mx-auto';

export const Loading = ({ size = 'medium', className, circular = false, type = 'secondary' }: Props) => {
  className = className || '';

  const sizeClass =
    size === 'x-small' ? 'w-3 h-3' : size === 'small' ? 'w-4 h-4' : size === 'medium' ? 'w-6 h-6' : 'w-8 h-8';

  if (circular) {
    className = `${loadingClasses} flex flex-row justify-center items-center ${className}`;

    return (
      <div className={className}>
        <span className={`${styles.loader} ${sizeClass} ${(color as any)[type]}`} />
      </div>
    );
  }

  className = `${loadingClasses} flex flex-col justify-center items-center ${className}`;

  return (
    <div className={className}>
      <img
        width={size === 'large' ? 48 : size === 'medium' ? 36 : 24}
        className={loadingImgClasses}
        src={'/spinner2.gif'}
        //src={loadingGif} feel free to move this to the UI library
        alt={t('loading')}
      />
      {size === 'large' && (
        <Text size="small" color="subdued">
          {t('loading')}
        </Text>
      )}
    </div>
  );
};

Loading.deafultProps = {
  size: 'large',
};
