import { PageLayout } from '@/components/elements/PageLayout';
import { PromotionForm } from './components/PromotionForm';
import { Button } from "@/components/elements";
import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useMethod } from '@roc-digital/ui-lib';
import { notifyError, notifySuccess } from '@roc-digital/mxm-base/logic';
import { readPromotional, upsertPromotional } from '@/logic/promotionals';

const DEFAULT_VALUE = {} as any;

export function EditPromotional() {
  const nav = useNavigate();
  const params = useParams();
  const originalRef = React.useRef<any>(DEFAULT_VALUE);
  const id = parseInt(params.id || '');

  const readQuery = useMethod(readPromotional, {
    autoRun: [id],
    onBefore: async (args) => {
      return args[0] ? args : undefined;
    },
    onSuccess: (result) => {
      originalRef.current = result;
    },
    onError: () => {
      notifyError('Failed to read promotional');
    },
    loading: id && params.id !== 'new' ? true : false,
    defaultValue: DEFAULT_VALUE,
  });

  const updateQuery = useMethod(upsertPromotional, {
    onSuccess(result) {
      notifySuccess('Promotional saved');
      if (result.id !== id) {
        nav(`/promotionals/${result.id}`)
      }
    },
    onError(err, args) {
      notifyError('Failed to update promotional')
    }
  })

  const loading = readQuery.loading;
  const changed = readQuery.result !== originalRef.current;
  const isNew = params.id === 'new';

  return <PageLayout
    title='Edit Promotions'
    actions={<>
      <Button
        size="md"
        title="Cancel"
        variant="secondary" clickEvent={() => nav('/promotions')}
        disabled={loading || !changed}
      />
      <Button
        size="md"
        title="Save"
        clickEvent={() => updateQuery.run(readQuery.result)}
        disabled={loading || !changed}
      />
      <Button
        size="md"
        title="Preview"
        clickEvent={() => { }}
        disabled={loading || !isNew}
      />
    </>}
  >
    <PromotionForm value={readQuery.result} onChange={readQuery.setResult} />
  </PageLayout>;
}
