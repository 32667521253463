import React from 'react';
import { DropEvent } from './Slot';
import { View, Zone } from './types';
import { mutateViewForDropEvent, extractZoneArticles, mutateZoneDropEvent, insertNewZone, getUUid } from './utils';
import { ArticleContent, mapToArticleContent } from '@roc-digital/ui-lib';
import { DnDZonePreview } from './DnDZonePreview';
import { Slot } from './Slot';
import { openModal, closeModal } from '@roc-digital/ui-lib';
import { ConfigureZoneModal } from './ConfigureZoneModal';
import { TagZonePreview } from './TagZonePreview';
import { Pressable } from '../Pressable';
import { ArticleSearch } from '../ArticleSearch';
import { ArticleSchema } from '@roc-digital/mxm-base/src/data';
import { useOnChangeHook } from './useOnChangeHook';
import { Input } from '../Input';
import { useDrop } from 'react-dnd';

export interface ViewEditorProps {
  view: View;
  onChange?: (view: View) => void;
  zoneViewClassnames?: string;
  articleSearchClassnames?: string;
}

export function ViewEditor(props: ViewEditorProps) {
  const [view, setView, setViewProp] = useOnChangeHook(props.view, props.onChange);
  const articlesRef = React.useRef<{ [id: string]: ArticleSchema }>({});
  const state = React.useRef(view);
  state.current = view;

  view.zones?.forEach?.(zone => zone.articles?.forEach?.((article) => {
    articlesRef.current[article.id] = article;
  }))

  const onDrop = (event: DropEvent) => {

    if (event.from.type === 'article') {
      articlesRef.current[event.from.article.id] = event.from.article;
      event.from.area = 'bucket';
      event.from.id = event.from.article.id;
    }

    console.log(event);

    setView(mutateViewForDropEvent(view, event, articlesRef.current));
  }

  const onZoneDrop = (event: DropEvent) => {
    if(event.from === event.to) return;
    
    setView(mutateZoneDropEvent(view, event))
  }

  const addZone = (index: number) => {
    setView(insertNewZone(view, index));
  }

  const updateZone = (zone: Zone) => {

    const index = view.zones.findIndex(z => z.id === zone.id);

    if (index !== -1) {
      const next = { ...view };
      next.zones[index] = { ...next.zones[index], ...zone };
      setView(next);
    }
  }

  const removeZone = (zone: Zone) => {
    const next = {
      ...view
    };

    next.zones = next.zones.filter(z => z.id !== zone.id);
    setView(next);
  }

  const ref = React.useRef<HTMLDivElement>()

  const articles = Object.keys(articlesRef.current)
    .map(a => mapToArticleContent(articlesRef.current[a], {}, true));

  const [scale, setScale] = React.useState('1');
  const [transform, height] = React.useMemo(() => {

    if (!ref.current) return ['', ''];

    let scaleValue = parseFloat(scale) || 1;

    if (scaleValue === 1) return ['', ''];

    const height = (ref.current.parentElement?.offsetHeight || 0) - (4 * 35);

    if (!height) return ['', ''];

    const scaledHeight = height * scaleValue;
    const newTop = ((height / 2) - (scaledHeight / 2)) / scaleValue;

    const y = (newTop / scaleValue) * -1;
    const newHeight = height / scaleValue;

    return [
      `scale(${scaleValue}) translateY(${y}px)`,
      `${newHeight - 64}px`
    ];
  }, [scale, ref.current]);

  return <div className='flex-grow flex flex-row gap-4 h-full' style={{maxHeight: ''}}>
    <div className={`flex-grow flex flex-col gap-8 ${props.zoneViewClassnames || ''}`}>
      <div className='flex flex-row gap-4'>
        <div className='flex-grow'>
          <label className="block text-sm font-medium mb-4 text-gray-700">Layout Name *</label>
          <Input
            className="w-full"
            value={view?.title}
            size={'large'}
            id={'title'}
            placeholder="Enter name for view"
            changeEvent={(e) => setViewProp('title', e.target.value)}
          />
        </div>
        <div className="slidecontainer flex flex-col align-center gap-8">
          <label className="block text-sm font-medium text-gray-700">Zoom</label>
          <input type='range' min=".1" max="1" value={scale} step=".05" className='slider' onChange={(e) => setScale(e.target.value)} />
        </div>
      </div>      
      <div ref={ref as any} className='overflow-auto flex-grow flex flex-col gap-8 pr-8' style={{ transform: transform, minHeight: height }}>
        <AddZoneBarButton onClick={() => addZone(0)} />
        {view.zones?.map((zone, i) => {
          return <React.Fragment key={zone.id}>
            <ZoneItem
              zone={zone}
              articles={articles}
              onArticleDrop={onDrop}
              onZoneDrop={onZoneDrop}
              onChange={(zone) => updateZone(zone)}
              onRemove={() => removeZone(zone)}
            />
            <AddZoneBarButton onClick={() => addZone(i + 1)} />
          </React.Fragment>
        })}
      </div>
    </div>
    <div className={`flex-grow flex flex-col gap-2 max-w-[950px] ${props.articleSearchClassnames || ''}`}>
      <div className='overflow-auto flex-grow flex flex-col gap-8 pr-8'>
        <ArticleSearch draggable />
      </div>
              <Slot
          type='article'
          className='w-full'
          disableDrag
          getItem={() => ({area: 'trash'})}
          onDrop={onDrop}
        >
          <div style={{border: '4px dashed gray', padding: '8px', width: '100%', textAlign: 'center', minHeight: '50px'}}>
            Drag articles here to remove them.
          </div>
        </Slot>
    </div>
  </div>;
}

interface ZoneProps {
  zone: Zone;
  articles: ArticleContent[];
  onArticleDrop: (event: DropEvent) => void;
  onZoneDrop: (event: DropEvent) => void;
  onChange?: (zone: Zone) => void;
  onRemove?: () => void;
}

const loadedMap = {} as any;

function ZoneItem(props: ZoneProps) {
  const ref = React.useRef<any>();
  const [zone, setZone] = React.useState(props.zone);
  const lastZone = React.useRef(props.zone);

  React.useEffect(() => {
    if (props.zone !== lastZone.current) {
      setZone(props.zone);
    }
  }, [props.zone]);


  const changeProp = (key: keyof Zone, value: Zone[typeof key]) => {
    const next = { ...zone, [key]: value };
    lastZone.current = next;
    setZone(next);
    if (props.onChange) {
      props.onChange(next);
    }
  }

  const onConfigure = () => {
    openModal(<ConfigureZoneModal
      zone={props.zone}
      onChange={(next) => {
        closeModal();
        props.onChange?.(next);
      }}
      onRemove={() => {
        closeModal();
        props.onRemove?.();
      }}
    />, 'medium', true);
  }

  const inputClass = "text-admin-default font-normal leading-6 font-[Chivo-ExtraBold] text-3xl tracking-tighter leading-tight w-full flex flex-col mt-6 mb-4 max-w-5xl mx-auto px-2 text-action !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo";

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    if (loadedMap[zone.id]) {
      ref.current.style.opacity = 1;
      ref.current.style.transform = 'scale(1)';
      return;
    }
    loadedMap[zone.id] = true;
    setTimeout(() => {
      ref.current.style.opacity = 1;
      ref.current.style.transform = 'scale(1)';
    }, 16);
  }, []);

  return <Slot onDrop={props.onZoneDrop} getItem={() => props.zone} type='zone'>
    <div className='flex flex-row gap-4 easeload flex-1' ref={ref as any}>
      <div style={{ width: '28px', maxWidth: '28px', border: '4px solid #e5e7eb', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
        <div style={{ backgroundImage: 'url(/drag-handle.png)', backgroundRepeat: 'repeat', width: '20px', height: '100%' }} />
      </div>
      <div className='flex flex-grow flex-col max-w-5xl'>
        <div className='flex flex-row justify-center items-center'>
          <input
            value={zone?.title || ''}
            onChange={(e) => changeProp('title', e.target.value)}
            placeholder='Untitled Zone'
            className={inputClass}
            style={{textDecoration: zone?.hide_title ? 'line-through' : ''}}
          />
          <span className='flex-grow'></span>
          <Pressable clickEvent={onConfigure} className={'inline'}>
            <img src={'/icons/edit_zone.svg'} title={'Edit article'} className={'h-4 inline mb-2'} />
          </Pressable>
        </div>
        {zone.source === 'articles' ? <>
          <DnDZonePreview
            key={zone.id}
            onDrop={props.onArticleDrop}
            zoneId={zone.id}
            layoutId={zone.web_layout}
            articles={extractZoneArticles(zone, props.articles)}
          />
        </> : <>
          <TagZonePreview zone={zone} />
        </>}
      </div>
    </div>
  </Slot>
}

function AddZoneBarButton(props: { onClick: () => void }) {
  return <div className='w-full flex items-center justify-center'>
    <span
      onClick={props.onClick}
      style={{ border: '2px dashed #e5e7eb', color: ' #585858', padding: '4px 16px', borderRadius: 'px', cursor: 'pointer' }}
    >Add Zone</span>
  </div>
}


interface TrashCanProps {
  onDrop: (event: any) => void;
}

function TrashCan(props: TrashCanProps) {
  const [collectedProps, drop] = useDrop(() => ({
    accept: 'article',
  }))

  return <div ref={drop}>Drop Target</div>
}