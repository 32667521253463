import { PopupAdminType, PopupProps } from '@roc-digital/types/popups';
import { GenericThreePopup } from '@roc-digital/ui-web';
import { PreviewContainer } from './PreviewContainer';
import { Input, Select } from "@/components/elements";
import { FormGroup } from "@/components/elements/FormGroups";
import { SidebarConfigBox } from "@/components/elements/SidebarConfigBox";
import { useObjectSetter, inputProps } from "@/hooks/useObjectSetter";
import { PopupConfigProps, GenericThreePopupConfig } from "@roc-digital/types";
import { Option } from '@/components/elements/Select';

export const genericThreePopupType: PopupAdminType<GenericThreePopupConfig> = {
  type: 'generic-3',
  Preview: Preview,
  ConfigForm: Config,
  defaultConfig: {
    title: 'BREAKING NEWS',
    subtitle: 'Signup TODAY for 50% off your First Month of MXM News+',
    body: `Discover the latest in news and insights with MXM News, your go-to source for breaking stories and in-depth analysis. At MXM News, we prioritize accuracy, relevance, and a fresh perspective on current events, ensuring you stay informed and engaged. Whether it's global affairs, business trends, or cultural happenings, our team of seasoned journalists delivers content that matters to you. Stay ahead of the curve and experience news like never before with MXM News—where information meets innovation.`,
    trigger: {},
    actionText: 'Subscribe Today',
    actionUrl: '/subscribe',
    imageUrl: '/photos/example_image.png',
    frequency: 1,
    position: 'bottom',
    textColor: 'white'
  }
}

const positionOptions: Option[] = [
  { label: 'Center', value: 'center' },
  { label: 'Bottom', value: 'bottom' },
];

const userStateOptions: Option[] = [
  { label: 'Any', value: 'any' },
  { label: 'No Account', value: 'no-session' },
  { label: 'Signed In', value: 'signed-in' },
  { label: 'Subscribed', value: 'subscribed' },
  { label: 'No Subscription', value: 'no-subscription' },
  { label: 'Expired Subscription', value: 'expired-subscription' },
];

function Config(props: PopupConfigProps<GenericThreePopupConfig>) {
  const popup = useObjectSetter(props.popup, props.onChange);
  const config = useObjectSetter(props.popup.config, (config) => popup.set('config', config));
  const triggers = useObjectSetter(config.trigger, (trigger) => config.set('trigger', trigger));

  return <>
    <SidebarConfigBox title="Content">
      <FormGroup title="Title">
        <Input {...inputProps(config, 'title')} />
      </FormGroup>
      <FormGroup title="Sub Title">
        <Input {...inputProps(config, 'subtitle')} />
      </FormGroup>
      <FormGroup title="Body">
        <Input {...inputProps(config, 'body')} />
      </FormGroup>
    </SidebarConfigBox>

    <SidebarConfigBox title="Action Button">
      <FormGroup title="Text">
        <Input {...inputProps(config, 'actionText')} />
      </FormGroup>
      <FormGroup title="URL">
        <Input {...inputProps(config, 'actionUrl')} />
      </FormGroup>
    </SidebarConfigBox>

    <SidebarConfigBox title="Styles">
      <FormGroup title="Position">
        <Select
          value={config.position} changeEvent={(e) => config.set('position', e.target.value as any)}
          options={positionOptions}></Select>
      </FormGroup>
      <FormGroup title="Text Color">
        <Input {...inputProps(config, 'textColor')} />
      </FormGroup>
      <FormGroup title="Image Url">
        <Input {...inputProps(config, 'imageUrl')} />
      </FormGroup>

    </SidebarConfigBox>

    <SidebarConfigBox title="Conditions">
      <FormGroup title="User Status">
        <Select
          value={triggers.userState} changeEvent={(e) => triggers.set('userState', e.target.value as any)}
          options={userStateOptions}></Select>
      </FormGroup>
      <FormGroup title="URL match">
        <Input {...inputProps(triggers, 'urlMatch')} />
      </FormGroup>
      <FormGroup title="Delay">
        <Input {...inputProps(triggers, 'delay')} type='number' min="1"/>
      </FormGroup>
      <FormGroup title="Scroll Depth">
        <Input {...inputProps(triggers, 'scrollDepth')} type='number' min="0"/>
      </FormGroup>
      <FormGroup title="Frequency">
        <Input {...inputProps(config, 'frequency')} type='number' min="1"/>
      </FormGroup>
    </SidebarConfigBox>
  </>
}

function Preview(props: PopupProps) {
  return <PreviewContainer>
    <GenericThreePopup popup={props.popup} isPreview />
  </PreviewContainer>
}

