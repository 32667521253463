import React, { useEffect, useState } from 'react';
import {
  mxmIcon,
  twitterIcon,
  videoIcon,
  usePaginateEvent,
  trendingBrandIcon,
  useChangeEvent,
  InputData,
  articlesIcon,
  PaginationData,
  useMethod,
} from '@roc-digital/ui-lib';
import { listArticles } from '@roc-digital/mxm-base/logic';
import { Article, ArticleSchema, TagSchema } from '@roc-digital/mxm-base/data';
import { Event } from '@roc-digital/mxm-base/events';
import { format } from 'date-fns';
import {
  Badge,
  BodyDark,
  BodyLight,
  Icon,
  InputSearch,
  Pagination,
  Pressable,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableSection,
  Toggle,
  Loading,
  TagsInput
} from '@/components/elements';
import { useDrag, useDrop } from 'react-dnd';


export interface ArticleSearchProps {
  onEdit?: (article: ArticleSchema) => void;
  onView?: (article: ArticleSchema) => void;
  onAi?: (article: ArticleSchema) => void;
  onNotify?: (article: ArticleSchema) => void;
  hideActions?: boolean;
  draggable?: boolean;
}

const defaultParams = { isAdmin: true, page: 1, count: 10000 };

export function ArticleSearch(props: ArticleSearchProps) {
  const [tags, setTags] = useState<TagSchema[]>([]);
  const articles = useMethod(listArticles);
  const [search, setSearch] = React.useState<string>('');
  const [trendingOnly, setTrendingOnly] = React.useState<boolean>(false);
  const [publishedOnly, setPublishedOnly] = React.useState<boolean>(true);
  const [scheduledOnly, setShowScheduled] = React.useState<boolean>(false);
  const paginateEvent = usePaginateEvent(
    ({ data }: Event<PaginationData>) => {
      articles.run(data?.page, 10, { 
        isAdmin: true,
        page: data?.page,
        count: 10,
        query: search,
        scope: tags?.map(t => t.scope).join(',') 
      });
    },
    { namespace: 'mxm.http.content' },
    {},
    [search, tags]
  );

  useEffect(() => {
    articles
    .debounce([1, 10, {
        isAdmin: true,
        page: 1,
        count: 10,
        query: search,
        status: publishedOnly ? '' : 'drafted',
        scope: tags?.map(t => t.scope).join(','),
        scheduled: scheduledOnly ? true : false
      }], 500)
    .catch(e => console.error(e))
  }, [trendingOnly, publishedOnly,  search, tags, scheduledOnly]);

  const changeSearchEvent = useChangeEvent(({ data }: Event<InputData>) => {
    setSearch(data?.value as string);
  });

  const hasActions = (props.onAi || props.onEdit || props.onView || props.onNotify) && props.hideActions !== true;

  return (
      <div className='flex flex-col gap-4 h-full'>
        <div className="flex flex-row justify-between">
          <div className="w-1/2 pr-8">
            <InputSearch
              showLoading={false}
              showLabel={false}
              placeholder={'Search Articles'}
              changeValueEvent={changeSearchEvent}
              hideResults
            />
          </div>
          {/**
           * ADD A METHOD TO SHOW ONLY CONTENT THAT WAS MANUALLY CURATED
           * FILTER BY TAGS
           * <Toggle
            isLabelLeft
            label={'Show Trending Only'}
            onToggle={() => {
              setTrendingOnly(!trendingOnly);
            }}
          />**/}
          <div className='flex flex-col gap-2'>
            <Toggle
              isLabelLeft
              initialState={publishedOnly}
              label={'Show Published Only'}
              onToggle={() => {
                setPublishedOnly(!publishedOnly);
              }}
            />
            <Toggle
              isLabelLeft
              initialState={scheduledOnly}
              label={'Show Scheduled Upcoming Only'}
              onToggle={() => {
                setShowScheduled(!scheduledOnly);
              }}
            />
          </div>
          
        </div>
        <div className='flex flex-row'>
          <TagsInput
            placeholder='Find tags'
            className="flex flex-row gap-2 items-center"
            value={tags}
            onChange={setTags}
          />
        </div>
        <Table>
          <TableSection type="head">
            <TableRow>
              {hasActions ? <>
                <TableHeader className={'w-1/12'}> </TableHeader>
              </> : <></>}
              <TableHeader className={'w-5/12'}>Title</TableHeader>
              <TableHeader className={'w-1/12'}>Publication</TableHeader>
              <TableHeader className={'w-3/12'}>Tags</TableHeader>
              {hasActions ? <>
                <TableHeader className={'w-1/12'}>Views</TableHeader>
                <TableHeader className={'w-1/12 text-right'}>Actions</TableHeader>
              </> : <></>}
            </TableRow>
          </TableSection>
          <TableSection>
            {!articles.loading && articles.result?.map((article: Article, key: number | undefined) => (
                  <DraggableRow key={key} item={article.toParams()} draggable={props.draggable}>
                    {hasActions ? <>
                      <TableCell className={`p-1`}>
                        <Icon
                          clickEvent={() => props.onView?.(article)}
                          src={
                            article.content_type === 'article'
                              ? articlesIcon
                              : article.content_type === 'tweet'
                              ? twitterIcon
                              : article.content_type === 'mxm_exclusive'
                              ? mxmIcon
                              : videoIcon
                          }
                          size={'medium'}
                        />
                      </TableCell>
                    </> : <></>}
                    <Pressable id={article.id} clickEvent={() => props.onView?.(article)}>
                      <TableCell className={'flex flex-col'}>
                        <BodyDark><a href={`/articles/${article.id}/preview`} target="_blank">{article.title}</a></BodyDark>
                        
                        <BodyLight className='gap-2 flex'>
                          {format(new Date((article.date || 0) * 1000), 'dd/MM/yyyy h:mm a')}

                          {article.scheduled_date ? (
                            <Badge
                              className='text-white'
                            >Scheduled: {article.scheduled_date ? `${format(new Date((article.scheduled_date || 0) * 1000), 'dd/MM/yyyy h:mm a')}` : ''}</Badge>
                          ) : null}
                        </BodyLight>

                      </TableCell>
                    </Pressable>
                    <TableCell className={`align-top`}>
                      <BodyDark className={`${hasActions ? '' : 'text-xs'}`}>{article.tags?.find((tag: TagSchema) => tag.type === 'publication')?.name}</BodyDark>
                    </TableCell>
                    <TableCell className={`align-top`}>
                      {article.tags
                        ?.filter((tag: TagSchema) => tag.type !== 'publication')
                        .slice(0, 3)
                        .map((tag: TagSchema, tagkey) => (
                          <Badge className={'mr-2'} key={tagkey} type={'subdued'}>
                            <BodyDark className={'text-xs'}>{tag.name}</BodyDark>
                          </Badge>
                        ))}
                    </TableCell>
                    {hasActions ? <>
                      <TableCell className={'align-top'}>
                        <BodyDark className={'text-xs'}>{article.views?.toLocaleString()}</BodyDark>
                        {
                          // make out of 1000 to save space
                        }
                      </TableCell>
                      <TableCell className={'text-right'}>
                        {article.tags?.some((tag) => tag.type === 'flag' && tag.tag === 'trending') && (
                          <Icon src={trendingBrandIcon} size={'small'} fill={'#24AAE1'} stroke={'#24AAE1'} />
                        )}
                        {
                          //If not notified, then have button to send a notification
                        }
                        <div className={'text-right'}>
                          {props.onEdit ? <>
                            <Pressable id={article.id} clickEvent={() => props.onEdit?.(article)} className={'inline'}>
                              <img src={'/icons/edit_zone.svg'} title={'Edit article'} className={'h-4 inline mb-2'} />
                            </Pressable>
                          </> : <></>}
                          {props.onAi ? <>
                            <Pressable id={article.id} clickEvent={() => props.onAi?.(article)} className={'inline'}>
                              <img src={'/icons/ai_rewrite.svg'} title={'AI rewrite'} className={'h-5 inline mb-2'} />
                            </Pressable>
                          </> : <></>}
                          {props.onNotify ? <>
                            <Pressable id={article.id} clickEvent={() => props.onNotify?.(article)} className={'inline'}>
                              <img
                                src={'/icons/notification_white.svg'}
                                title={'Send notification'}
                                className={'h-5 inline'}
                              />
                            </Pressable>
                          </> : <></>}
                        </div>
                      </TableCell>
                    </> : <></>}
                  </DraggableRow>
                ))}
            {articles.loading ? (
              <TableRow>
                <TableCell className={'text-center'} colSpan={6}>
                  <Loading size={'medium'} />
                </TableCell>
              </TableRow>
            ) : null}
            {!articles.loading && !articles?.result?.toArray?.()?.length && articles.count > 0 ? (
              <TableRow>
                <TableCell className={'text-center'} colSpan={6}>
                  This query return no results.
                </TableCell>
              </TableRow>
            ) : null}
          </TableSection>
        </Table>
        {articles.result && articles.result.pagination && (
          <Pagination className={'mt-5'} paginateEvent={paginateEvent} paginator={articles.result?.pagination} infinite/>
        )}
      </div>
  );
}

interface DraggableRowProps {
  item: any;
  children: any;
  draggable?: boolean;
}

function DraggableRow(props: DraggableRowProps) {
  const propsRef = React.useRef(props);
  propsRef.current = props;

  if(props.draggable) {
    const [{isDragging}, drag] = useDrag(() => ({
      item: () => ({
        type: 'article',
        article: propsRef.current.item
      }),
      type: 'article',
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      })
    }));
  
    return <tr
      ref={drag}
      className={'border-b border-stone-200 text-left text-font text-xs h-2 hover:bg-slate-50'}>{props.children}</tr>;
  } else {
    return <tr
      className={'border-b border-stone-200 text-left text-font text-xs h-2 hover:bg-slate-50'}>{props.children}</tr>;
  }
}