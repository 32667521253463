import { useMethod } from '@roc-digital/ui-lib';
import {
  BodyDark,
  Button,
  Chip,
  InputSearch,
  Loading,
  Pressable,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableSection,
} from '@/components/elements';
import { listPromotionals, makeDefaultPromotional, deletePromotional } from '@/logic/promotionals';
import { Promotional } from '@roc-digital/types';
import React from 'react';
import { Badge, BadgeSizes } from '@roc-digital/ui-web';
import { notifySuccess, notifyError } from '@roc-digital/mxm-base/logic';

export interface PromotionalsListProps {
  onEdit?: (Promotional: Promotional) => void;
  onPreview?: (Promotional: Promotional) => void;
  onDuplicate?: (Promotional: Promotional) => void;
  onSelect?: (Promotional: Promotional) => void;
  enableMakeDefault?: boolean;
  enableDelete?: boolean;
  onMakeDefault?: (Promotional: Promotional) => void;
}

export function PromotionalsList(props: PromotionalsListProps) {
  const [search, setSearch] = React.useState('');
  const listQuery = useMethod(listPromotionals, { autoRun: [], defaultValue: [] });
  const makeDefaultQuery = useMethod(makeDefaultPromotional, {
    onSuccess: () => {
      listQuery.reload();
      notifySuccess('Default changed');
    },
    onError: (err) => {
      notifyError('There was an error changing the default promotional');
    }
  });
  const deleteQuery = useMethod(deletePromotional, {
    onSuccess: listQuery.reload,
    onError: () => {
      notifyError('Failed to delete promotional');
    }
  });


  const promotionals = listQuery.result || [];

  React.useEffect(() => {
    const lastSearch = listQuery.lastArgs?.[0]?.query || '';
    if(lastSearch !== search) {
      listQuery.debounce([{query: search}], 500);
    }
  }, [search]);

  const changeDefault = (promotional: Promotional) => {

    const answer = prompt(`Are you sure you want to change the default promotional to '${promotional.name}(${promotional.code})'?\n Type 'yes' to confirm.`)?.trim().toLowerCase();
    if(answer !== 'yes'){
      return;
    }

    makeDefaultQuery.run(promotional.id);
  }

  const doDelete = (promotional: Promotional) => {
    
    if(promotional.is_default) {
      return notifyError('Cannot delete the default promotional.');
    }

    if(promotional.enabled) {
      return notifyError('Cannot delete an enabled promotional.');
    }

    const yes = prompt(`Are you sure you want to delete '${promotional.name}'?\n\nType yes to confirm.`) || '';
    if(yes.trim().toLowerCase() !== 'yes') return;
    deleteQuery.run(promotional.id);
  }

  const loading = listQuery.loading || deleteQuery.loading;


  return (
    <>
      <div className="flex flex-row mb-4 justify-between">
        <InputSearch
          showLoading={false}
          showLabel={false}
          value={search}
          placeholder={'Search Promotionals'}
          changeValueEvent={setSearch}
          hideResults
        />
      </div>
      <Table>
        <TableSection type="head">
          <TableRow>
            <TableHeader className={'w-3/5'}>Name</TableHeader>
            <TableHeader className={'w-2/5 text-right pr-6'}>Actions</TableHeader>
          </TableRow>
        </TableSection>
        <TableSection>
          {promotionals.map((promotional, index) => (
            <TableRow key={promotional.id}>
              <TableCell className={'flex flex-row gap-2'}>
                <BodyDark>{promotional.name ? promotional.name : '--'}</BodyDark>
                {promotional.enabled ? <Badge selected size={BadgeSizes.xsmall} title='Enabled'/> : null}
                {promotional.is_default ? <Badge selected size={BadgeSizes.xsmall} title='Default'/> : null}
              </TableCell>
              <TableCell className={'text-right'}>
                {props.enableMakeDefault && promotional.is_default !== true && promotional.enabled === true ? <>
                  <Button customClassName='opacity-[.1] hover:opacity-[1]' size='sm' clickEvent={() => changeDefault(promotional)}>
                    MAKE DEFAULT
                  </Button>
                </> : null}
                {props.onEdit ? <>
                  <Pressable className={'inline mx-2'} clickEvent={() => props.onEdit?.(promotional)}>
                    <img src={'/icons/edit_zone.svg'} title={'Edit'} className={'inline h-5'} />
                  </Pressable>
                </> : null}
                {props.onPreview ? <>
                  <Pressable className={'inline mx-2'} clickEvent={() => props.onPreview?.(promotional)}>
                    <img src={'/icons/preview.svg'} title={'Preview'} className={'inline h-5'} />
                  </Pressable>
                </> : null}
                {props.onDuplicate ? <>
                  <Pressable className={'inline mx-2'} clickEvent={() => props.onDuplicate?.(promotional)}>
                    <img src={'/icons/duplicate.svg'} title={'Duplicate'} className={'inline h-5'} />
                  </Pressable>
                </> : null}
                {props.onSelect ? <>
                  <Button size='sm' clickEvent={() => props.onSelect?.(promotional)}>
                    SELECT
                  </Button>
                </> : null}
                {props.enableDelete ? <>
                  <Pressable className={'inline mx-2'} clickEvent={() => doDelete(promotional)}>
                    <img src={'/icons/delete_zone.svg'} title={'Delete'} className={'inline h-5'} />
                  </Pressable>
                </> : null}
              </TableCell>
            </TableRow>
          ))}
          {!loading && promotionals.length === 0 ? (
            <TableRow>
              <TableCell className={'text-center'} colSpan={6}>
                <div className={'p-2'}>No records found.</div>
              </TableCell>
            </TableRow>
          ) : null}
          {loading ? (
            <TableRow>
              <TableCell className={'text-center'} colSpan={2}>
                <Loading size={'medium'} />
              </TableCell>
            </TableRow>
          ) : null}
        </TableSection>
      </Table>
    </>
  );
};

