import { RuiNode } from "@roc-digital/rich-ui";
import { BlockElementDef, RuiApi } from "./elements";
import { ReactElement, JSXElementConstructor } from "react";
import { createPortal } from "react-dom";
import { document2Dom } from './utils/document2Dom';
import { dom2Document } from './utils/dom2Document';

export class RuiEditorApi implements RuiApi {

  idCounter = 0;

  nodes: {[id: string]: RuiNode} = {};
  parentNodes: {[childId: string]: RuiNode} = {};
  domNodes: {[id: string]: HTMLElement} = {};
  elementDefs: {[id: string]: BlockElementDef} = {};
  observer = new MutationObserver(() => {
    if(this.onChange) this.onChange();
  });

  constructor
  (
    elements: BlockElementDef[],
    private onChange: () => void,
    private onJsxRender: () => void,
  ) {
    elements.forEach(e => this.elementDefs[e.id] = e);
  }

  register(node: RuiNode, domNode: HTMLElement, parentId?: string) {
    this.idCounter++;
    node.id = this.idCounter.toString();

    if(parentId) {
      this.parentNodes[node.id] = this.nodes[parentId];
    }

    this.nodes[node.id] = node;
    this.domNodes[node.id] = domNode;
    domNode.dataset.ruiId = node.id;
    domNode.dataset.elementId = node.element;
    this.observer.observe(domNode, {childList: true});
  }

  getIdFromDomNode(node: HTMLElement): string | null {
    return node.dataset.ruiId || null;
  }

  getRuiNodeFromDomNode(node: HTMLElement | undefined) {
    if(!node) return undefined;

    const id = this.getIdFromDomNode(node);

    if (!id) return undefined;

    return this.nodes[id];
  }

  getDomNode(node: RuiNode) {
    if(!node.id) {
      console.warn('Node does not have an id', node);
      return null;
    }

    return this.domNodes[node.id]; 
  }

  getParent(node: RuiNode) {

    if(!node.id) {
      console.warn('Dom node does not have an id', node);
      return null;
    }

    return this.parentNodes[node.id];
    
  }

  getElementDef(element: string) {
    return this.elementDefs[element];
  }

  createDomNode(node: RuiNode, parentId?: string) {
    const element = this.getElementDef(node.element);
    if(!element) return null;
    const domNode = element.createDomNode(node, this);
    this.register(node, domNode, parentId);

    return domNode;
  }

  renderNode(node: RuiNode) {
    const element = this.getElementDef(node.element);
    if(!element) return null;
    element.renderNode(node, this);
  }

  readNodeDataKey<T>(node: RuiNode, key: string): T | undefined {
    return node?.data?.[key]; 
  }
  
  setNodeDataKey<T>(node: RuiNode, key: string, value: T) {
    if(!node) return;
    if (!node.data) node.data = {};
    node.data[key] = value;    
  }

  markChange() {
    this.onChange()
  }

  renderJsx(root: HTMLElement, jsx: ReactElement<any, string | JSXElementConstructor<any>>) {
    if(!root.dataset.jsxId) root.dataset.jsxId = Math.random().toString();
    root.classList.add('rui-jsx-root');
    root['_ruiJsx'] = createPortal(jsx, root, root.dataset.jsxId);
    this.onJsxRender();
  }

  getJsxPortals() {
    const elements = document.getElementsByClassName('rui-jsx-root');
    const map: any = {};
    const ids: string[] = [];
    for(let item of elements) {
      const element: HTMLElement = item as any;
      if(!element.dataset.jsxId || !element['_ruiJsx']) continue;
      map[element.dataset.jsxId] = element['_ruiJsx'];
      ids.push(element.dataset.jsxId);
    }

    return ids.sort().map(id => map[id]);
  } 

  dom2Node(domNode: HTMLElement) {
    const doc = dom2Document(domNode, this);
    return doc.children[0];
  }

  importNodeIntoDom(node: RuiNode, targetElement: HTMLElement, index?: number) {
    const parentId = this.getIdFromDomNode(targetElement);
    const parentNode = this.nodes[parentId || ''];

    if(!parentId || !parentNode) {
      throw new Error('Element is not a known container.');
    }

    const element = document2Dom({children: [node]}, this);

    if(!element) return;

    targetElement.insertBefore(element, targetElement.children[index || -1]);
    
    return element;
  }
}