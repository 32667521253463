import { RuiDocument } from '@roc-digital/rich-ui';
import { UiBlockTemplate } from './types';

const DOCUMENT: RuiDocument = {
  "children": [
    {
      "children": [
        {
          "children": [
            {
              "children": [
                {
                  "children": [],
                  "data": {
                    "html": "<h1 class=\"font-[Chivo-ExtraBold] text-3xl tracking-tighter leading-tight text-white !font-chivo !font-extrabold text-[64px] leading-[64px] sm:text-[48px]\" style=\"text-align: center; line-height: 1;\"><span style=\"font-size: 36pt;\">Subscribe to MxM News+ and access our<br>Exclusive content.</span></h1>\n<p>&nbsp;</p>\n<p style=\"line-height: 2; text-align: center;\"><span class=\"text-admin-default font-normal leading-6 font-[Chivo-Bold] text-xl text-white !font-chivo !font-bold text-md\" style=\"font-size: 24pt;\">Cut through the censorship, mainstream bias, and institutional dominance that has left society divided. Access our exclusive content. Subscribe to MxM News+ now!</span></p>"
                  },
                  "element": "paragraph"
                }
              ],
              "data": {
                "alignment": "top-left",
                "backgroundColor": "none",
                "direction": "column",
                "height": "fill",
                "maxWidth": 553,
                "width": "fill"
              },
              "element": "container"
            },
            {
              "children": [
                {
                  "children": [],
                  "data": {
                    "action": "visit-billing",
                    "label": "Subscribe",
                    "size": "sm",
                    "url": "",
                    "variant": "outline"
                  },
                  "element": "button"
                }
              ],
              "data": {
                "alignment": "middle-center",
                "backgroundColor": "none",
                "direction": "row",
                "height": "fill",
                "width": "fill"
              },
              "element": "container"
            }
          ],
          "data": {
            "alignment": "top-center",
            "backgroundColor": "none",
            "borderRadius": 0,
            "direction": "column",
            "gap": 72,
            "height": "fill",
            "marginLeft": 0,
            "marginTop": 0,
            "maxWidth": 1024,
            "paddingLeft": 0,
            "paddingTop": 20,
            "width": "hug"
          },
          "element": "container"
        }
      ],
      "data": {
        "alignment": "middle-center",
        "backgroundColor": "#24aae1",
        "direction": "column",
        "gap": 8,
        "height": "hug",
        "width": "fill"
      },
      "element": "container"
    }
  ],
  "textTokens": []
}

export const TrendingSubscribeBannerLarge: UiBlockTemplate = {
  class: 'trending-subscribe-banner-large',
  label: 'Trending Subscribe Banner Large',
  description: 'Large banner displayed in trending with a subscribe CTA.',
  document: DOCUMENT,
  validate: () => null,
  webPreviewUrl: '/trending',
}


