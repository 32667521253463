import React, { FC } from 'react';
import { BodyDark, BodyLight } from '@/components/elements';

interface BarSection {
  value: number;
  label: string;
}

interface HorizontalPercentageBarProps {
  sections: BarSection[];
}

const getColor = (index: number) => {
  switch (index) {
    case 0:
      return 'bg-teal-500';
    case 1:
      return 'bg-orange-500';
    case 2:
      return 'bg-lime-400';
    case 3:
      return 'bg-sky-400';
    case 4:
      return 'bg-red-500';
    case 5:
      return 'bg-violet-500';
    default:
      return 'bg-gray-400';
  }
};

const HorizontalPercentageBar: FC<HorizontalPercentageBarProps> = ({ sections }) => {
  const total: number = sections.reduce((sum, { value }) => sum + value, 0);

  return (
    <>
      <div className="flex flex-col mb-2">
        <div className="w-full flex justify-start gap-3">
          {sections.map(({ label }, i) => (
            <div key={i} className="flex items-center mb-2">
              <div className={`${getColor(i)} w-3 h-3 rounded-full`}></div>
              <BodyDark className="text-left text-black ml-1">{label}</BodyDark>
            </div>
          ))}
        </div>
        {total === 0 ? <BodyLight>No new content past 24 hrs</BodyLight> : null}
        <div className="h-5 gap-2 w-full flex">
          {sections.map(({ value, label }, i) =>
            value > 0 ? (
              <div
                key={i}
                className={`${getColor(i)} rounded`}
                style={{ width: `${(value / total) * 100}%` }}
                title={value ? value.toString() + ' ' + label : ''}
              ></div>
            ) : null
          )}
        </div>
      </div>
    </>
  );
};

export default HorizontalPercentageBar;
