import React from "react";
import panzoom, {PanZoom} from 'panzoom';

export interface PreviewContainerProps {
  children: any;
}

export function PreviewContainer(props: PreviewContainerProps) {

  const api = React.useMemo(() => {
    // const observer = new ResizeObserver((events) => {
    //   const event = events[0];
    //   if(!event) return;
      
    //   // const width = 
    // });
    let target: HTMLDivElement = null as any;
    let instance: PanZoom | null = null;

    const setElement = (div: HTMLDivElement) => {

      if(div == target) return;
      if(instance) instance.dispose();
      if(!div) return;
      instance = panzoom(div, {
        // transformOrigin: {x: 0, y: .5},
        // autocenter: true,
        initialZoom: .7,
        initialY:0
        
      });
      target = div;
      // instance.

      // if(target) observer.unobserve(target);
      // if(div) observer.observe(div);
      // target = div;
    };

    return {
      setElement
    }
  }, [])

  return <div style={{
    overflow: 'hidden',
    minWidth: '0px',
    minHeight: '0px',
    flexGrow: 1,
    flex: '1',
    // width: '1440px',
    // height: '830px',
    position: 'relative',
  }}>
    <div ref={api.setElement} style={{
      backgroundImage: 'url("/photos/mxm_trending_preview.png")',
      width: '1440px',
      height: '830px',
      // transform: 'scale(0.5) translateX(-47%) translateY(-44%)',
      position: 'relative',
    }}>
      {props.children}
    </div>
  </div>;
}