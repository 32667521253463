import React, { memo, useEffect } from 'react';
import { useClickEvent, useStateEvent, PublisherData, useCustomEvent } from '@roc-digital/ui-lib';
import { Event } from '@roc-digital/mxm-base/types';
import { useParams } from 'react-router-dom';
import { View, Zone } from '@roc-digital/mxm-base/data';
import { readView } from '@roc-digital/mxm-base/logic';
import { useNavigate } from 'react-router';
import { Heading1, ButtonSizes, Button, ButtonVariants, BodyLight } from '@/components/elements';
import ZonePreview from '@/components/views/Views/ZonePreview';

const PreviewView = memo(() => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [view, setView] = useStateEvent<View>(
    'mxm.http.views',
    'readView.success',
    new View({
      id: '',
      title: '',
    })
  );

  useEffect(() => {
    if (id && id !== 'new') {
      void readView(id, { isAdmin: false });
      // isAdmin: false implies that we have all tags and zones for the view
      // the following line is not needed but is here for comparing that also the zones are coming with empty tags
      //void listZones(id, { isAdmin: true });
    }
  }, [id]);

  useCustomEvent(
    'readView.success',
    ({ data }: { data: View }) => {
      setView(new View({ ...data }));
    },
    { namespace: 'mxm.http.views' },
    {},
    [id, view]
  );

  useCustomEvent(
    'listZones.success',
    ({ data }: any) => {
      console.log('zones from listZones', data.items);

      setTimeout(() => {
        setView(new View({ ...view, zones: data.items }));
      }, 300);
    },
    { namespace: 'mxm.http.views' },
    {},
    [id, view]
  );

  const handleBackClick = useClickEvent(() => {
    navigate('/views/');
  });

  const openViewsEvent = useClickEvent(({ data }: Event<PublisherData>) => {
    navigate(('/views/' + data?.id) as string);
  });

  return (
    <div className="flex flex-col w-4/5">
      <div className="flex justify-between h-20 mt-3 mr-10">
        <Heading1 className={'text-2xl text-black'}>Preview: {view?.title}</Heading1>
        <div className={'flex flex-row justify-end w-1/6 gap-3'}>
          <Button
            size={ButtonSizes.medium}
            clickEvent={handleBackClick}
            variant={ButtonVariants.secondary}
            // disabled={loading as boolean}
          >
            <BodyLight className={'font-light'}>Back</BodyLight>
          </Button>
          <Button size={ButtonSizes.medium} clickEvent={openViewsEvent} id={view?.id}>
            <BodyLight className={`${'text-white'} font-light`}>Edit</BodyLight>
          </Button>
        </div>
      </div>
      <div className="flex-grow bg-white p-10 mr-6 rounded">
        {view?.zones
          ?.sort((a, b) => (a.order || 0) - (b.order || 0))
          ?.map((zone, index) => (
            <div key={zone.id}>
              <ZonePreview preview={true} key={index} view={view as View} zone={new Zone(zone)} />
            </div>
          ))}
      </div>
    </div>
  );
});

PreviewView.displayName = 'PreviewView';

export default PreviewView;
