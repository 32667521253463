import React, { memo } from 'react';
import {
  Button,
  ButtonSizes,
  ButtonVariants,
  Heading2,
  Modal,
  SpinnerBackdrop,
} from '@/components/elements';
import { exportUserData } from '@roc-digital/mxm-base/logic';
import { useMethod } from '@roc-digital/ui-lib';

export interface ExportUsersModalProps {
  articleId: string;
}

export function openExportUsersModal(props: ExportUsersModalProps) {
  Modal.open('mxm.modal', <ExportUsersModal {...props}/>, 'medium');
}

export const ExportUsersModal = memo((props: ExportUsersModalProps) => {

  const query = useMethod(downloadCSV);

  const closeModal = () => {
    Modal.close('mxm.modal');
  };

  return (
    <div className="p-8">
      <Heading2 className={'mb-8 text-admin-dark !font-extrabold !text-[26px]'}>Export User Data</Heading2>

      <div>
        This action will download all user accounts as a CSV file. The file columns are as follows:
        <br/><br/>
        <pre>
           - <b>id</b>: User id <br/>
           - <b>email</b>: User email address, if known.<br/>
           - <b>created</b>: The date when the account was created.<br/>
           - <b>last_active</b>: The date when the user was last online.<br/>
           - <b>web</b>: The date when the user last visited mxmnews.com, if at all.<br/>
           - <b>ios</b>: The date when the user last opened the ios app, if at all.<br/>
           - <b>android</b>: The date when the user last opened the android app, if at all.<br/>
           - <b>subscription_status</b>: The current status of the subscription.<br/>
           - <b>subscription_status_hint</b>: A reason for the subscription status.<br/>
           - <b>subscription_end</b>: The date when the user's subscription will expire if not renewed.<br/>
           - <b>utm_url</b>: The utm url visited by the user before signing up.<br/>
           - <b>utm_referrer_url</b>: The referrer url of the utm_url.<br/>
           - <b>phone</b>: The phone number the user provided.<br/>
           - <b>signup_article_id</b>: The last article visited before signing up.<br/>
        </pre>        
      </div>

      <div>
        <div className="mt-4 flex inline-block gap-2">
          <Button clickEvent={closeModal} size={ButtonSizes.medium} variant={ButtonVariants.secondary}>Cancel</Button>
          <Button clickEvent={query.run} disabled={query.loading} size={ButtonSizes.medium}>Download</Button>
        </div>
      </div>

      <SpinnerBackdrop active={query.loading}/>
    </div>
  );
});

async function downloadCSV() {
  const data = await exportUserData();
  const filename = `mxm-users-export-${new Date().toLocaleString().replace(/\/|\\|\-|\s|\,/g, '_')}.csv`;

  const headers = 'id,email,created,last_active,web,ios,android,subscription_end,subscription_status,subscription_status_hint,utm_url,utm_referrer_url,phone,signup_article_id';
  let content = '';

  for(let i = 0; i < data.length; i++) {
    const row = data[i];

    content+= '\n'
      + `${row.id || ''},`
      + `${row.email || ''},`
      + `${unixToLocalDate(row.created)},`
      + `${unixToLocalDate(row.last_active)},`
      + `${unixToLocalDate(row.web)},`
      + `${unixToLocalDate(row.ios)},`
      + `${unixToLocalDate(row.android)},`
      + `${unixToLocalDate(row.subscription_end)},`
      + `${row.subscription_status || ''},`
      + `${row.subscription_status_hint || ''},`
      + `${row.utm_url || ''},`
      + `${row.utm_referrer_url || ''},`
      + `${row.phone || ''},`
      + `${row.signup_article_id || ''}`
      
  }

  const blob = new Blob([headers+content], {type: 'text/csv'});
  const a = document.createElement('a');
  a.download = filename;
  a.href = window.URL.createObjectURL(blob);
  a.click();
}

function unixToLocalDate(unix: number) {
  if(!unix) return '';
  return new Date(unix * 1000).toLocaleString().replace(',', ' ');
}

