import { publish } from '@roc-digital/mxm-base/events';
import { ReactNode } from 'react';

export function openModal(
  content: ReactNode,
  size?: 'small' | 'medium' | 'large',
  showCloseButton?: boolean,
  blurBg?: boolean
) {
  publish('mxm.modal', 'open', { content, size, showCloseButton, blurBg });
}

export function closeModal() {
  publish('mxm.modal', 'close');
}
