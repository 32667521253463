import { RuiDocument } from '@roc-digital/rich-ui';
import { UiBlockTemplate } from './types';

const DOCUMENT: RuiDocument = {
  "children": [
    {
      "children": [
        {
          "children": [
            {
              "children": [
                {
                  "children": [
                    {
                      "children": [],
                      "data": {
                        "html": "<p style=\"line-height: 1;\"><span style=\"font-size: 36pt;\"><strong><span style=\"color: rgb(236, 240, 241);\">Subscribe to MxM <br>News+ </span></strong></span><span style=\"font-size: 36pt;\"><strong><span style=\"color: rgb(236, 240, 241);\">and access more content.</span></strong></span></p>"
                      },
                      "element": "paragraph"
                    }
                  ],
                  "data": {
                    "alignment": "top-left",
                    "backgroundColor": "none",
                    "direction": "row",
                    "height": "fill",
                    "maxWidth": 600,
                    "width": "fill"
                  },
                  "element": "container"
                }
              ],
              "data": {
                "alignment": "top-left",
                "backgroundColor": "none",
                "direction": "column",
                "height": "fill",
                "minWidth": 0,
                "width": "fill"
              },
              "element": "container"
            },
            {
              "children": [
                {
                  "children": [],
                  "data": {
                    "action": "visit-billing",
                    "label": "Subscribe",
                    "size": "sm",
                    "url": "",
                    "variant": "outline"
                  },
                  "element": "button"
                }
              ],
              "data": {
                "alignment": "middle-right",
                "backgroundColor": "none",
                "direction": "row",
                "height": "fill",
                "width": "hug"
              },
              "element": "container"
            }
          ],
          "data": {
            "alignment": "middle-center",
            "backgroundColor": "none",
            "borderRadius": 0,
            "direction": "row",
            "height": "fill",
            "marginLeft": 0,
            "marginTop": 0,
            "maxWidth": 1024,
            "paddingLeft": 0,
            "paddingTop": 0,
            "width": "fill"
          },
          "element": "container"
        }
      ],
      "data": {
        "alignment": "middle-center",
        "backgroundColor": "#24aae1",
        "direction": "column",
        "gap": 8,
        "height": "hug",
        "paddingLeft": 20,
        "paddingTop": 20,
        "width": "fill"
      },
      "element": "container"
    }
  ],
  "textTokens": []
}

export const TrendingSubscribeBannerSmall: UiBlockTemplate = {
  class: 'trending-subscribe-banner-small',
  label: 'Trending Subscribe Banner Small',
  description: 'Small banner displayed in trending with a subscribe CTA.',
  document: DOCUMENT,
  validate: () => null,
  webPreviewUrl: '/trending',
}


