import { RuiDocument, RuiNode } from "@roc-digital/rich-ui";

interface Api {
  createDomNode(node: RuiNode, parentId?: string): HTMLElement | null;
  renderNode(node: RuiNode): any;
}

export function document2Dom(doc: RuiDocument, api: Api): HTMLElement | null {

  if(doc.children[0]) {
    return node2Dom(doc.children[0], null, api)
  }

  return null;
}

function node2Dom(node: RuiNode, parent: RuiNode | null, api: Api) {
  node.id = undefined;
  const domNode = api.createDomNode(node, parent?.id);
  if(!domNode) return null;

  api.renderNode(node);

  // @ts-ignore
  domNode['_ruiNode'] = node;
  
  if(node.children?.length) {
    for(let child of node.children) {
      const childDomNode = node2Dom(child, node, api);

      if(childDomNode) {
        domNode.appendChild(childDomNode);
      }
      
    }
  }

  return domNode;
}

